import { eventTypeOptions } from "../../../Components/Customers/constants/SessionFunctions"
import { EVENT_TYPE } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchEventTypeFailure, fetchEventTypeSuccess } from "./reducer"

export const fetchEventTypes = (subscriptionId, setLoading, setEventTypeData) => async (dispatch) => {
    await determineBaseUrlAndPerformApiCall(`${EVENT_TYPE}/${subscriptionId}`, 'GET').then((response) => {
        setLoading(true)
        if(response == null) {
            dispatch(fetchEventTypeFailure("response is null"))
            dispatch(fetchEventTypeSuccess([]))
            setEventTypeData({
                name: "usageEventType",
                value: "",
                options: []
            });
            setLoading(false)
        }

       else if (response?.error) {
            dispatch(fetchEventTypeFailure(response.error.message))
            setLoading(false)
        }
        else {
            let eventOptions = eventTypeOptions(response)
            const extraObject = {
                name: "usageEventType",
                value: "TelcoSimulate",
                options: eventOptions
            };
            setEventTypeData(extraObject)
            dispatch(fetchEventTypeSuccess(response.reverse()))
            setLoading(false)
        }
    })
}