import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    logoHeader: [],
    loading: false,
    error: null,
};

const fetchlogoHeaderSlice = createSlice({
    name: "logoHeader",
    initialState,
    reducers: {
        fetchlogoHeaderSuccess: (state, action) => {
            state.loading = false;
            state.logoHeader = action.payload
        },
        fetchlogoHeaderFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchlogoHeaderFailure,
    fetchlogoHeaderSuccess
} = fetchlogoHeaderSlice.actions

export default fetchlogoHeaderSlice.reducer