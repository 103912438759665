import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Container, FormFeedback, Input, Label } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Row, Col, Button } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { handleExportData } from "../Utils/ExportToCsv";
import { useNavigate } from "react-router-dom";
import { formatDate, convertNumberOfDaysToMonthYear, getDayDiff, duration, } from "./constants/DateFormat";
import { setDefault, storeRow, tabChange } from "../Utils/SetDefaultRowData";
import Simulate from "./Simulate";
import { currencyDataFormat, noCurrencyArrayDataFormat, reservationDataFormat, subscriptionDataFormat, toggleAccordion, fetchCustomerDetails, } from "./constants/constantFunctions";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerList } from "../../slices/customer/customerlist/thunk";
import { ToastContainer, toast } from "react-toastify";
import { CustomerPreview } from "./CustomerPreview";
import { customerColumns, customerSearchColumns } from "./constants/CustomerTableColumns";
import { Loader } from "../Utils/Loader";
import { setWelcomeToast } from "../../slices/welcomeToaster/action";
import { setSuccessMessage, setEditSuccessMessage, setDocumentSuccessMessage, setCustomerNotFoundMessage, } from "../../slices/toastMessage/action";
import { lists } from "./constants/constantValues"
import { getCustomerData } from "./constants/ListingCustomerFunctions";
import { exportDataForCustomer } from "./constants/exportDataForCustomer";
import { PAGE_TITLES } from "../Common/constants";
import { CustomerTableCustomTableButtons } from "./CustomerTableCustomTableButtons";
import { TableForTelco } from "./TableForTelco"
import { TelcoHeaderForCustomer } from "./TelcoHeaderForCustomer"
import { searchCustomerJsonData } from "./constants/CustomerJsonObj";
import { TableForAI } from "./TableForAI";
import { getAttributeByType } from "../../slices/attribute/getattributebytype/thunk";
import CommonModal from "../Utils/CommonModal";
import { deletecustomer } from "../../slices/customer/deletecustomer/thunk";
import { bulkDelete } from "../../slices/BulkDelete/thunk";
import { MenuItem } from '@mui/material';
import { navigateToSubscribe } from './constants/DocumentTableFunctions';
import ReactApexChart from "react-apexcharts";
import SelectField from "../Utils/SelectField";
import { useColumnSettings } from '../Utils/useColumnSettings';

export default function ListingCustomers({ AIData, AISetTable, TableShow }) {
    const [loading, setLoading] = useState(false);
    const [renderSimulate, setRenderSimulate] = useState(false);
    const [index, setIndex] = useState(null);
    const [open, setOpen] = useState("1");
    const [activeTab, setActiveTab] = useState("0.1");
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(100);
    const [isError, setIsError] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [columnFilters, setColumnFilters] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [msisdn, setMsisdn] = useState('');
    const [imsi, setImsi] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [getTable, setGetTable] = useState(false)
    const [customerArray, setCustomerArray] = useState([]);
    const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
    const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
    const [errors, setErrors] = useState({ msisdn: '', imsi: '', accountNumber: '' });
    const [errorMessage, setErrorMessage] = useState('Search for customer using the parameters above.')
    const [errorIcon, setErrorIcon] = useState('ri-user-fill')
    const rolename = localStorage.getItem('roleName');
    const isAIPath = window.location.pathname.includes('/chatbot');
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [customerId, setCustomerId] = useState();
    const [modal, setModal] = useState(false);
    const [searchExpanding, setSearchExpanding] = useState(false);
    const [custId, setCustId] = useState()
    const [selectAllCustomers, setSelectAllCustomers] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const checkboxRef = useRef(null);
    const isMobile = window.innerWidth <= 768;
    const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)

    const isEIPEnabled = operatorConfiguration?.configurations
        ?.find(config => config.name === "EIP")
        ?.value?.find(item => item.key === "Enabled")
        ?.value?.[0] === "True";

    const successMessage = isEIPEnabled
        ? "Device Created Successfully"
        : "Customer Created Successfully";

    const failureMessage = isEIPEnabled
        ? "Device Updated Successfully"
        : "Customer Updated Successfully";

    const toggleModal = (id, singleOrMulti) => {
        if (singleOrMulti === "single") {
            setCustomerId(id);
            setModal(!modal);
        }
        else {
            setSelectedRowsForBulkDelete(id)
            setModalForBulkDelete(!modalForBulkDelete)
        }
        document.body.style.overflow = 'auto'
    };
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const current = new Date();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let simulateOrPreview = "";
    let subscriptionsArray = [];
    let noCurrencyArray = [];
    let currencyArray = [];
    let reservationDataArray = [];
    let exportData
    const toggle = toggleAccordion(open, setOpen);
    const permissions = useSelector((state) => state.permissions.permissions);
    const showWelcomeToast = useSelector(
        (state) => state.welcomeToast.showWelcomeToast
    );
    const showSuccessMessage = useSelector(
        (state) => state.SuccessToast.showSuccessMessage
    );
    const showDocumentSuccessMessage = useSelector(
        (state) => state.SuccessToast.showDocumentSuccessMessage
    );
    const showCustomerNotFoundMessage = useSelector(
        (state) => state.SuccessToast.showCustomerNotFoundMessage
    );
    const showEditSuccessMessage = useSelector(
        (state) => state.SuccessToast.showEditSuccessMessage
    ); const reservationPermission = permissions.find((resource) => resource.resourceName === 'reservation');
    const stationPermission = permissions.find((resource) => resource.resourceName === 'station');
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    const orderPermission = permissions.find((resource) => resource.resourceName === 'order');
    const subscriptionPermission = permissions.find((resource) => resource.resourceName === 'subscription');
    const allCustomerData = useSelector((state) => state.CustomerAllData.customerListForReservation)
    let list = lists
    const stationData = useSelector((state) => state.Stations.station);
    const reservationData = useSelector(
        (state) => state.Reservations.reservations
    );
    const customerData = useSelector(
        (state) => state.FetchCustomer.fetchCustomer
    );
    const fetchCustomerSummaryData = useSelector(
        (state) => state.Simulate.simulate
    );
    noCurrencyArray = noCurrencyArrayDataFormat(
        fetchCustomerSummaryData,
        noCurrencyArray
    );
    let attributeDataArray = customerData?.customerInfo?.attributeList || [];

    currencyArray = currencyDataFormat(fetchCustomerSummaryData, currencyArray);
    subscriptionsArray = subscriptionDataFormat(customerData, subscriptionsArray);
    reservationDataArray = reservationDataFormat(
        reservationData,
        customerData,
        stationData,
        reservationDataArray
    );
    const customers = useSelector((state) => state?.CustomerList?.customerList?.Customers);
    const TotalCustomers = useSelector((state) => state?.CustomerList?.customerList);
    const columns = customerColumns(selectAllCustomers, setSelectAllCustomers, customers, setSelectedCustomer, selectedCustomer, checkboxRef, isMobile, operatorConfiguration);
    const customerListingVar = operatorConfiguration?.configurations?.find(item => item.name === "CustomerRetrieval")?.value[1]?.value[0] === "True" ? true : false
    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = selectedCustomer?.length > 0 && selectedCustomer?.length < customers?.length;
        }
        setSelectAllCustomers(selectedCustomer?.length > 0 && customers?.length > 0 && selectedCustomer?.length === customers?.length);
    }, [selectedCustomer, customers?.length, setSelectAllCustomers]);

    useEffect(() => {
        if (customerListingVar) {
            const [lastFilter] = [...columnFilters].slice(-1);
            if (lastFilter) {
                const selectedId = lastFilter.id === "FullName" ? 'Name' : lastFilter.id;

                const value = lastFilter.id === "Tags" ? [lastFilter.value] : lastFilter.value;

                setSelectedValue(selectedId);
                setSearchValue(value);

                dispatch(fetchCustomerList(setLoading, 1, TotalCustomers?.TotalCount, selectedId, value));
            } else {
                if (!customerListingVar) {
                    dispatch(fetchCustomerList(setLoading, 1, isEIPEnabled ? 10 : 5));

                } else {
                    setSearchValue('');
                    dispatch(fetchCustomerList(setLoading, 1, pagination.pageSize));
                }
            }
        }
    }, [columnFilters]);

    useEffect(() => {
        dispatch(getAttributeByType("customer", setLoading))
    }, [])

    const searchableAttributes = useSelector((state) => state.GetAttributeByType.getSearchableAttribute)
    const integerAndTextAttributes = searchableAttributes.filter(item => {
        return item.valueType === "integer" || item.valueType === "string";
    });
    const [searchFields, setSearchFields] = useState([])
    useEffect(() => {
        if (!customerListingVar) {
            dispatch(fetchCustomerList(setLoading, 1, isEIPEnabled ? 10 : 5));
        }
    }, [rolename, dispatch,isEIPEnabled]);

    useEffect(() => {
        if (integerAndTextAttributes.length > 0) {
            setSearchFields(integerAndTextAttributes.map(attr => ({ name: attr.name, value: '', touched: false })));
        }
    }, [searchableAttributes]);

    useEffect(() => {
        if (showWelcomeToast) {
            toast.success(`Welcome ${localStorage.getItem("userName")}`);
            dispatch(setWelcomeToast(false));
        }
        if (showSuccessMessage) {
            toast.success(successMessage);
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success(failureMessage);
            dispatch(setEditSuccessMessage(false));
        }
        if (showDocumentSuccessMessage) {
            toast.success(`Subscription Created Successfully`);
            dispatch(setDocumentSuccessMessage(false));
        }
        if (showCustomerNotFoundMessage) {
            toast.error('Customer Not Found');
            dispatch(setCustomerNotFoundMessage(false));
        }
    }, [showSuccessMessage, showDocumentSuccessMessage, showWelcomeToast, showCustomerNotFoundMessage, dispatch]);

    useEffect(() => {
        if (customerListingVar) {
            setIsLoading(true);
            setIsRefetching(true);
            const fetchData = async () => {
                setIsLoading(true);
                setIsRefetching(true);

                try {
                    await dispatch(
                        fetchCustomerList(
                            setLoading,
                            pagination.pageIndex + 1,
                            pagination.pageSize,
                        )
                    );
                    setRowCount(customers.length);

                } catch (error) {
                    setIsError(true);
                } finally {
                    setIsLoading(false);
                    setIsRefetching(false);
                }
            };
            fetchData();
        }
    }, [pagination.pageIndex, pagination.pageSize]);
    if (customers) {
        exportData = exportDataForCustomer(allCustomerData)
    }

    const searchCustomerData = useSelector((state) => state.SearchedCustomer.searchCustomer)
    let searchedCustomerDataJsonObj
    if (searchCustomerData) {
        searchedCustomerDataJsonObj = searchCustomerJsonData(searchCustomerData, rolename)
    }
    const searchColumns = customerSearchColumns()

    const {
        settingsModalOpen,
        viewColumns,
        toggleSettingsModal,
        SettingsSidebar,
    } = useColumnSettings('customer', searchColumns);

    // const pageTitle = rolename === 'telcoAdmin' ? PAGE_TITLES.TELCO_CUSTOMER : PAGE_TITLES.CUSTOMER_LIST;
    const pageTitle = isEIPEnabled
        ? (rolename === 'telcoAdmin' ? PAGE_TITLES.TELCO_CUSTOMER_EIP : PAGE_TITLES.CUSTOMER_LIST_EIP)
        : (rolename === 'telcoAdmin' ? PAGE_TITLES.TELCO_CUSTOMER : PAGE_TITLES.CUSTOMER_LIST);

    const customerListTitle = isEIPEnabled
        ? PAGE_TITLES.CUSTOMER_LIST_EIP
        : PAGE_TITLES.CUSTOMER_LIST;

    const activeInactiveOptions = [{ value: "RAN Devices", label: "RAN Devices" }, { value: "IOT", label: "IOT" }, { value: "Data Center", label: "Data Center" }]


    const [selectedOption, setSelectedOption] = useState(activeInactiveOptions[0]?.value);
    const [series, setSeries] = useState([45, 55]);

    const handleSelectChange = (e) => {
        const value = e.value;


        // Update the series based on the selected value
        switch (value) {
            case 'RAN Devices':
                setSeries([60, 40]);
                break;
            case 'IOT':
                setSeries([30, 70]);
                break;
            case 'Data Center':
                setSeries([50, 50]);
                break;
            default:
                setSeries([45, 55]);
        }

        setSelectedOption(value);
    };
    const options = {
        chart: {
            height: 200,
            width: 200,
            type: 'donut',
        }, labels: ['Active', 'In Active'],
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false,
            dropShadow: {
                enabled: false,
            },
        },
        colors: ["#66A8E3", "#F6A351"], // Active in green, Inactive in red
    };
    const expectedValue = 7000;

    const barSeries = [
        {
            name: "Power Consumed (kWh)",
            type: "bar",
            data: [
                { x: ["Nagoya", "Site"], y: 12000 },
                { x: ["Tokyo Base", "Station"], y: 9800 },
                { x: ["Fukuoka", "Site"], y: 8500 },
                { x: ["Totsuka Data", "Center"], y: 7700 },
            ],
        },
        {
            name: "Energy Consumption Threshold",
            type: "line",
            data: [
                { x: ["Nagoya", "Site"], y: expectedValue },
                { x: ["Tokyo Base", "Station"], y: expectedValue },
                { x: ["Fukuoka", "Site"], y: expectedValue },
                { x: ["Totsuka Data", "Center"], y: expectedValue },
            ],
        },
    ];

    const barOptions = {
        chart: {
            type: "line",
            toolbar: { show: false },
        },
        plotOptions: {
            bar: { columnWidth: "30%" },
        },
        dataLabels: { enabled: false },
        legend: {
            show: true, // Ensure legend is visible
            showForSingleSeries: false,
            customLegendItems: ["Power Consumed (kWh)", "Energy Consumption Threshold"],
            markers: {
                fillColors: ["#51d28c", "#FF0000"],
            },
            offsetX: 20,
        },
        stroke: {
            width: [5, 2, 6], // Thinner dashed line (2px)
            curve: 'straight',
            dashArray: [0, 8, 5] // Second series is dashed
        },
        xaxis: {
            type: "category",
        },
        yaxis: {
            title: {
                text: "Power Consumption (kWh)",
            },
        },
        colors: ["#51d28c", "#FF0000"],
    };

    const pieseries = [5000, 3000, 2000]; // Cost breakdown in dollars

    const pieoptions = {
        chart: {
            height: 300,
            type: 'pie',
        },
        labels: ['Data Centers', 'RAN Devices', 'IoT'],
        legend: {
            show: false, // Show legend for better understanding
            position: "bottom",
        },
        dataLabels: {
            enabled: false, // Enable data labels
            formatter: function (val, opts) {
                return `$${opts.w.config.series[opts.seriesIndex].toLocaleString()}`;
            },
            style: {
                fontSize: "14px",
                fontWeight: "bold",
                colors: ["#fff"],
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return `$${value.toLocaleString()}`; // Format as currency
                },
            },
        },
        colors: ['#84BFFF', '#4BD495', '#FFC854'], // Custom colors
    };


    return (
        <React.Fragment>
            {!isAIPath ? (
                <div id="csms" className="page-content">
                    <Container fluid>
                        <BreadCrumb title={pageTitle} pageTitle={customerListTitle} />
                        <Row style={{ marginTop: '10px' }}>
                            <ToastContainer position="top-center" />
                            <Col lg={12}>
                                <div className="listingjs-table " id="customerList">
                                {isEIPEnabled &&
                                        <Row className="mb-3">
                                            <Col lg={12}>
                                                <Row className="gy-4">
                                                    <Col xxl={4} md={6}>
                                                        <Card className="h-100">
                                                            <CardHeader>Active vs In Active Devices</CardHeader>
                                                            <CardBody className="d-flex flex-column">
                                                                <SelectField
                                                                    className={"mb-4"}
                                                                    defaultValue={activeInactiveOptions[0]}
                                                                    value={selectedOption?.label}
                                                                    options={activeInactiveOptions}
                                                                    handleChange={handleSelectChange}
                                                                />
                                                                <div className="d-flex justify-content-center align-items-center mt-2">
                                                                    <ReactApexChart
                                                                        dir="ltr"
                                                                        className="apex-charts flex-grow-1"
                                                                        series={series}
                                                                        options={options}
                                                                        type="donut"
                                                                        height={230}
                                                                        width={230}
                                                                    />
                                                                    <div>
                                                                        <h5 className="text-black-50 mb-4">Monthly Trend</h5>
                                                                        <div className=" p-2 text-center mb-2">
                                                                            <div className="d-flex align-items-center justify-content-center mb-2">
                                                                                <span
                                                                                    className="rounded-circle d-inline-block me-2"
                                                                                    style={{ width: "12px", height: "12px", backgroundColor: "#66A8E3" }}
                                                                                ></span>
                                                                                <span className="text-muted">Active</span>
                                                                            </div>
                                                                            <div className="bg-light p-2 rounded d-inline-block">
                                                                                <span className="text-success fw-bold">
                                                                                    <i className="ri-arrow-up-line align-middle"></i> 16.24%
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className=" p-2 text-center ">
                                                                            <div className="d-flex align-items-center justify-content-center mb-2">
                                                                                <span
                                                                                    className="rounded-circle d-inline-block me-2"
                                                                                    style={{ width: "12px", height: "12px", backgroundColor: "#F6A351" }}
                                                                                ></span>
                                                                                <span className="text-muted">In Active</span>
                                                                            </div>
                                                                            <div className="bg-light p-2 rounded d-inline-block" style={{ width: "85px" }}>
                                                                                <span className="text-danger fw-bold">
                                                                                    <i className="ri-arrow-down-line align-middle"></i> 1.5%
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>

                                                    <Col xxl={4} md={6}>
                                                        <Card className="h-100">
                                                            <CardHeader>High Power Consuming Sites (Top 4) </CardHeader>
                                                            <CardBody>
                                                                <ReactApexChart
                                                                    dir="ltr"
                                                                    className="apex-charts"
                                                                    options={barOptions}
                                                                    series={barSeries}
                                                                    type="line"
                                                                    height={350}
                                                                    // width={403}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>

                                                    <Col xxl={4} md={6}>
                                                        <Card className="h-100">
                                                            <CardHeader>Energy Cost Breakdown</CardHeader>
                                                            <CardBody className="d-flex flex-column">
                                                                <div className="d-flex justify-content-center align-items-center mt-2">
                                                                    <ReactApexChart
                                                                        dir="ltr"
                                                                        className="apex-charts flex-grow-1"
                                                                        series={pieseries}
                                                                        options={pieoptions}
                                                                        type="pie"
                                                                        height={230}
                                                                    />
                                                                    <div>
                                                                        <h5 className="text-black-50 mb-4">Monthly Trend</h5>
                                                                        <div className=" rounded p-2 text-center  mb-2">
                                                                            <div className="d-flex align-items-center justify-content-center mb-2">
                                                                                <span
                                                                                    className="rounded-circle d-inline-block me-2"
                                                                                    style={{ width: "12px", height: "12px", backgroundColor: '#4BD495' }}
                                                                                ></span>
                                                                                <span className="text-muted">RAN Device</span>
                                                                            </div>
                                                                            <div className="bg-light p-2 rounded d-inline-block">
                                                                                <span className="text-success fw-bold">
                                                                                    <i className="ri-arrow-up-line align-middle"></i> 16.24 %
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className=" rounded p-2 text-center  mb-2">
                                                                            <div className="d-flex align-items-center justify-content-center mb-2">
                                                                                <span
                                                                                    className="rounded-circle d-inline-block me-2"
                                                                                    style={{ width: "12px", height: "12px", backgroundColor: '#84BFFF' }}
                                                                                ></span>
                                                                                <span className="text-muted">Data Center</span>
                                                                            </div>
                                                                            <div className="bg-light p-2 rounded d-inline-block">
                                                                                <span className="text-danger fw-bold">
                                                                                    <i className="ri-arrow-down-line align-middle"></i> 10.24 %
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className=" rounded p-2 text-center ">
                                                                            <div className="d-flex align-items-center justify-content-center mb-2">
                                                                                <span
                                                                                    className="rounded-circle d-inline-block me-2"
                                                                                    style={{ width: "12px", height: "12px", backgroundColor: '#FFC854' }}
                                                                                ></span>
                                                                                <span className="text-muted">IoT</span>
                                                                            </div>
                                                                            <div className="bg-light p-2 rounded d-inline-block">
                                                                                <span className="text-danger fw-bold">
                                                                                    <i className="ri-arrow-down-line align-middle"></i> 9.24 %
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }
                                    <TelcoHeaderForCustomer setCustId={setCustId} custId={custId} searchCustomerData={searchCustomerData} setRenderSimulate={setRenderSimulate} stationPermission={stationPermission} reservationPermission={reservationPermission} customerPermission={customerPermission} searchFields={searchFields} setSearchFields={setSearchFields} integerAndTextAttributes={integerAndTextAttributes} setErrorMessage={setErrorMessage} setErrorIcon={setErrorIcon} msisdn={msisdn} imsi={imsi} accountNumber={accountNumber} errors={errors} dispatch={dispatch} setLoading={setLoading} setGetTable={setGetTable} setMsisdn={setMsisdn} setImsi={setImsi} setAccountNumber={setAccountNumber} navigate={navigate} setErrors={setErrors} searchExpanding={searchExpanding} setSearchExpanding={setSearchExpanding} />
                                    <Row className="d-flex align-items-sm-start"> 
                                    <Col lg={settingsModalOpen ? 10 : 12}>
                                    <Card>
                                        <CardBody>
                                            {customerListingVar ?
                                                <Row className="mb-3 mt-1 table-responsive">
                                                    {customers ? (
                                                        <MaterialReactTable
                                                            icons={{
                                                                DragHandleIcon: () => (
                                                                    <i className="ri-drag-move-fill" />
                                                                ),
                                                            }}
                                                            renderTopToolbarCustomActions={({ table }) => (
                                                                <CustomerTableCustomTableButtons selectedCustomer={selectedCustomer} toggleModal={toggleModal} setMessageForPopUp={setMessageForPopUp} setCustomerArray={setCustomerArray} table={table} navigate={navigate} customerPermission={customerPermission} exportData={exportData} />
                                                            )}
                                                            displayColumnDefOptions={{
                                                                'mrt-row-actions': {
                                                                    header: '',
                                                                    enableHiding: true,
                                                                    size: isMobile ? 60 : 36,
                                                                    grow: true
                                                                },
                                                            }}
                                                            defaultColumn={{
                                                                minSize: 1,
                                                                maxSize: 9001,
                                                                size: 260,
                                                                muiTableBodyCellProps: {
                                                                    sx: {
                                                                        fontFamily: "Inter,sans-serif"
                                                                    }
                                                                },
                                                                muiTableHeadCellProps: {
                                                                    sx: {
                                                                        fontFamily: "Inter,sans-serif"
                                                                    }
                                                                }
                                                            }}
                                                            layoutMode='grid'
                                                            muiTableDetailPanelProps={{
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif",
                                                                },
                                                            }}
                                                            enableGlobalFilter={false}
                                                            enableColumnOrdering={true}
                                                            enableColumnDragging={true}
                                                            enableDensityToggle={false}
                                                            positionToolbarAlertBanner='none'
                                                            columns={columns}
                                                            data={!getTable ? customers : searchedCustomerDataJsonObj}
                                                            manualPagination={true}
                                                            onPaginationChange={setPagination}
                                                            {...(searchValue === '' ? { rowCount: TotalCustomers?.TotalCount } : { rowCount: TotalCustomers?.Count })}
                                                            positionGlobalFilter="right"
                                                            muiSearchTextFieldProps={{
                                                                placeholder: `Search ${customers.length} rows`,
                                                                sx: { minWidth: "300px" },
                                                                variant: "outlined",
                                                            }}
                                                            manualFiltering
                                                            onColumnFiltersChange={setColumnFilters}
                                                            enableGlobalFilterModes
                                                            state={{
                                                                isLoading,
                                                                ...(searchValue === '' ? { pagination } : {}),
                                                                showProgressBars: isRefetching,
                                                                columnFilters,
                                                                showColumnFilters: true
                                                            }}
                                                            muiSelectCheckboxProps={{
                                                                color: 'warning'
                                                            }}
                                                            muiSelectAllCheckboxProps={{
                                                                color: 'warning'
                                                            }}
                                                            enableRowActions={true}
                                                            initialState={{
                                                                columnVisibility: {
                                                                    "mrt-row-expand": false,
                                                                    // "Tags":false,
                                                                    "Email": false,
                                                                    "Mobile": false
                                                                },
                                                                columnOrder: [
                                                                    'checkbox',
                                                                    'mrt-row-actions',
                                                                    'CustomerId',
                                                                    'FullName',
                                                                    'Email',
                                                                    'Mobile',
                                                                    'Address',
                                                                    'Tags'
                                                                ]
                                                            }}
                                                            renderRowActionMenuItems={({ closeMenu, row }) => (
                                                                [
                                                                    customerPermission && customerPermission.permissionList.includes('view') ? <MenuItem className='row-options' key="view" onClick={() => { simulateOrPreview = "View"; navigate(`/view-customer/${row.original.CustomerId}`) }}>
                                                                        <i className='ri  ri-account-circle-line me-2' /> View
                                                                    </MenuItem> : null,
                                                                    customerPermission && customerPermission.permissionList.includes('update') ? <MenuItem className='row-options' key="edit" onClick={() => { navigate(`/update-customer/${row.original.CustomerId}`) }}>
                                                                        <i className='bx bxs-edit me-2' /> Edit
                                                                    </MenuItem> : null,
                                                                    <MenuItem className='row-options' key="delete" onClick={(e) => {
                                                                        simulateOrPreview = "Simulate"
                                                                        fetchCustomerDetails(dispatch, setLoading, setRenderSimulate, row, simulateOrPreview)
                                                                        storeRow(row, setIndex, index), setDefault(row.index, setActiveTab)
                                                                        closeMenu()
                                                                    }}>
                                                                        <i className=' bx bx-info-circle me-2' /> Simulate
                                                                    </MenuItem>,
                                                                    <MenuItem className='row-options' key="preview" onClick={(e) => {
                                                                        simulateOrPreview = "Preview"
                                                                        e.stopPropagation();
                                                                        getCustomerData(row, simulateOrPreview, setLoading, stationPermission, reservationPermission, customerPermission, setRenderSimulate, dispatch)
                                                                        row.toggleExpanded(); storeRow(row, setIndex, index), setDefault(row.index, setActiveTab), closeMenu()
                                                                    }}>
                                                                        <i className="ri-survey-line me-2" /> Preview
                                                                    </MenuItem>,
                                                                    orderPermission && orderPermission.permissionList.includes('create') ? <MenuItem className='row-options' key="subscribe" onClick={() => {
                                                                        navigateToSubscribe(row, dispatch, navigate)
                                                                    }}>
                                                                        <i className="ri-add-circle-line me-2 " ></i> Subscribe
                                                                    </MenuItem> : null,
                                                                    customerPermission && customerPermission.permissionList.includes('delete') ? <MenuItem className='row-options'
                                                                        key="close"
                                                                        onClick={() => {
                                                                            setMessageForPopUp(`Are you sure you want to Delete this Customer "${row.original.CustomerId}"?`)
                                                                            toggleModal(row, "single");
                                                                            setCustomerId(row.original.CustomerId)
                                                                            closeMenu();
                                                                        }}
                                                                    >
                                                                        <i className="ri ri-delete-bin-5-line me-2" />{" "}
                                                                        Close
                                                                    </MenuItem> : null,
                                                                ]
                                                            )}
                                                            renderDetailPanel={({ row }) => (
                                                                <React.Fragment>
                                                                    {useEffect(() => {
                                                                        row.toggleExpanded(false);
                                                                    }, [pagination])}
                                                                    {renderSimulate ? (
                                                                        <Simulate row={row} />
                                                                    ) : (
                                                                        <div className="preview-customer-1">
                                                                            <CustomerPreview
                                                                                customerData={customerData}
                                                                                row={row}
                                                                                activeTab={activeTab}
                                                                                setActiveTab={setActiveTab}
                                                                                tabChange={tabChange}
                                                                                open={open}
                                                                                setOpen={setOpen}
                                                                                toggle={toggle}
                                                                                noCurrencyArray={noCurrencyArray}
                                                                                currencyArray={currencyArray}
                                                                                fetchCustomerSummaryData={fetchCustomerSummaryData}
                                                                                reservationDataArray={reservationDataArray}
                                                                                duration={duration}
                                                                                customerAttributeData={customerData?.customerInfo?.attributeList}
                                                                                subscriptionsArray={subscriptionsArray}
                                                                                convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear}
                                                                                formatDate={formatDate}
                                                                                getDayDiff={getDayDiff}
                                                                                current={current} />
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            )} />
                                                    ) : (
                                                        <MaterialReactTable
                                                            renderTopToolbarCustomActions={({ table }) => (
                                                                <CustomerTableCustomTableButtons table={table} navigate={navigate} customerPermission={customerPermission} exportData={exportData} />
                                                            )}
                                                            icons={{
                                                                DragHandleIcon: () => (
                                                                    <i className="ri-drag-move-fill" />
                                                                ),
                                                            }}
                                                            positionToolbarAlertBanner='none'
                                                            muiTableHeadCellProps={{
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif",
                                                                },
                                                            }}
                                                            muiTableBodyCellProps={{
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif",
                                                                },
                                                            }}
                                                            muiTableDetailPanelProps={{
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif",
                                                                },
                                                            }}
                                                            initialState={{
                                                                columnVisibility: {
                                                                    "mrt-row-expand": false,
                                                                    // "Tags":false,
                                                                    "Email": false,
                                                                    "Mobile": false
                                                                },
                                                                columnOrder: [
                                                                    'checkbox',
                                                                    'mrt-row-actions',
                                                                    'CustomerId',
                                                                    'FullName',
                                                                    'Email',
                                                                    'Mobile',
                                                                    'Address',
                                                                    'Tags'
                                                                ]
                                                            }}
                                                            muiSelectCheckboxProps={{
                                                                color: 'warning'
                                                            }}
                                                            muiSelectAllCheckboxProps={{
                                                                color: 'warning'
                                                            }}
                                                            columns={columns}
                                                            data={!getTable ? [] : searchedCustomerDataJsonObj}
                                                            enableRowActions={true}
                                                            enableColumnOrdering={true}
                                                            enableColumnDragging={true}
                                                            enableDensityToggle={false}
                                                            displayColumnDefOptions={{
                                                                "mrt-row-actions": {
                                                                    header: "",
                                                                    enableHiding: true,
                                                                },
                                                            }}
                                                            enablePagination={false}
                                                            state={{
                                                                showColumnFilters: true
                                                            }}
                                                        />
                                                    )}
                                                    <CommonModal messageForPopUp={messageForPopUp} toggle={() => { if (modal) { toggleModal(customerId, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }}
                                                        open={modalForBulkDelete ? modalForBulkDelete : modal} buttonText={"Yes, Delete It"} modalAction={() => {
                                                            if (modal) {
                                                                dispatch(deletecustomer(customerId, setLoading, 1, pagination))
                                                                toggleModal(customerId, "single");
                                                            }
                                                            else {
                                                                let jsonObj = { ids: customerArray, type: "Customer" }
                                                                dispatch(bulkDelete(jsonObj, setLoading, pagination?.pageIndex + 1, pagination?.pageSize))
                                                                setSelectAllCustomers(false)
                                                                setSelectedCustomer([])
                                                                toggleModal(selectedRowsForBulkDelete, "multi")
                                                            }
                                                        }} />
                                                </Row>
                                                : <Row className="mb-3 mt-1 table-responsive">
                                                    <TableForTelco messageForPopUp={messageForPopUp} modal={modal} customerId={customerId} errorMessage={errorMessage} errorIcon={errorIcon} getTable={getTable} searchColumns={searchColumns} searchData={searchedCustomerDataJsonObj} orderPermission={orderPermission} customerPermission={customerPermission} navigate={navigate} dispatch={dispatch} setRenderSimulate={setRenderSimulate} setLoading={setLoading} simulateOrPreview={simulateOrPreview} index={index} setIndex={setIndex} setActiveTab={setActiveTab} fetchCustomerDetails={fetchCustomerDetails} stationPermission={stationPermission} reservationPermission={reservationPermission} getCustomerData={getCustomerData} storeRow={storeRow} setDefault={setDefault} renderSimulate={renderSimulate} pagination={pagination} customerData={customerData} activeTab={activeTab} tabChange={tabChange} setOpen={setOpen} toggle={toggle} noCurrencyArray={noCurrencyArray} currencyArray={currencyArray} fetchCustomerSummaryData={fetchCustomerSummaryData} reservationDataArray={reservationDataArray} duration={duration} subscriptionsArray={subscriptionsArray} convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear} formatDate={formatDate} getDayDiff={getDayDiff} current={current} setGetTable={setGetTable} searchExpanding={searchExpanding} setSearchExpanding={setSearchExpanding} toggleModal={toggleModal} setMessageForPopUp={setMessageForPopUp} setCustomerId={setCustomerId} TotalCustomers={TotalCustomers} viewColumns={viewColumns} toggleSettingsModal={toggleSettingsModal} />
                                                </Row>}
                                        </CardBody>

                                    </Card>
                                    </Col>
                                        {SettingsSidebar()}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <div className="p-4">
                    {TableShow ? (
                        <CardBody>
                            <TableForAI errorMessage={errorMessage} errorIcon={errorIcon} getTable={AISetTable} searchColumns={searchColumns} searchData={AIData} orderPermission={orderPermission} customerPermission={customerPermission} navigate={navigate} dispatch={dispatch} setRenderSimulate={setRenderSimulate} setLoading={setLoading} simulateOrPreview={simulateOrPreview} index={index} setIndex={setIndex} setActiveTab={setActiveTab} fetchCustomerDetails={fetchCustomerDetails} stationPermission={stationPermission} reservationPermission={reservationPermission} getCustomerData={getCustomerData} storeRow={storeRow} setDefault={setDefault} renderSimulate={renderSimulate} pagination={pagination} customerData={customerData} activeTab={activeTab} tabChange={tabChange} setOpen={setOpen} toggle={toggle} noCurrencyArray={noCurrencyArray} currencyArray={currencyArray} fetchCustomerSummaryData={fetchCustomerSummaryData} reservationDataArray={reservationDataArray} duration={duration} subscriptionsArray={subscriptionsArray} convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear} formatDate={formatDate} getDayDiff={getDayDiff} current={current} TableShow={TableShow} toggleModal={toggleModal} setMessageForPopUp={setMessageForPopUp} setCustomerId={setCustomerId} />
                        </CardBody>) : null}

                </div>
            )}
            {loading && <Loader />}
        </React.Fragment>
    );
}
