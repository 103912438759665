import { toast } from "react-toastify";
import { QUOTA_MANAGEMENT_API } from "../../constants/constants";
import {  getAllQuota } from "../thunk";
import {  fetchdeleteQuotaFailure, fetchdeleteQuotaSuccess } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const deleteQuota = (id, setLoading, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${QUOTA_MANAGEMENT_API}/${id}`, 'DELETE').then((response) => {
        if (!response.error) {
            dispatch(fetchdeleteQuotaSuccess(response))
            setLoading(false)
            dispatch(getAllQuota(setLoading))
            toast.success(`Quota Deleted Successfully`)
        }
        if (response.error) {
            dispatch(fetchdeleteQuotaFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}