import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useState } from "react";

export default function CommonDateTimePicker({
    className,
    value, format,
    name,style,
    handleChange,minDate,
    disabled, minDateTime,views
}) {
    const [open, setOpen] = useState(false)
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                name={name}
                className={className}
                disabled={disabled}
                views={views}
                sx={style}
                onChange={handleChange}
                value={value}
                format={format}
                minDateTime={minDateTime}
                minDate={minDate}
                closeOnSelect={false}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                slotProps={{
                  textField: {
                   onClick: () => setOpen(true),             
                  },
                }}
            />
        </LocalizationProvider>
    )
}