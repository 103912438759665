import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { countryOptionss, OperatorOptionsFields, OperatorStatusOptions } from "./Constant/Options";
import { fetchOperatorbyId } from "../../slices/OperatorManagement/GetOperatorbyID/thunk";
import ConfigurationForOperator from "./configurationOperator";
import { fetchOperatorSpecificConfigurations } from "../../slices/OperatorManagement/GetConfigurationByOperatorId/thunk";
import { fetchCountries } from "../../slices/customer/countries/thunk";
import { Button, Col, Row } from "reactstrap";
import { createConfigOperator } from "../../slices/OperatorManagement/ConfigurationCreateOperator/thunk";
import { useNavigate } from "react-router-dom";
import { createConfigArray } from "./Constant/ConfigFunction";
import { updateConfigOperator } from "../../slices/OperatorManagement/UpdateConfiguration/thunk";
import { fetchAllOperatorDefaultConfigurations } from "../../slices/OperatorManagement/GetAllDefaultConfiguraions/thunk";
import { filter } from "lodash";
import { Loader } from "../Utils/Loader";

export default function CreateConfiguration() {
    const [finalValues, setFinalValues] = useState({});
    const [loading, setLoading] = useState();
    const [editData,setEditData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [callState, setCallState] = useState(false)
    const [values, setValues] = useState({})
    const [defaultConfigurations, setDefaultConfigurations] = useState([])
    // Store all templates locally to manage updates
    const [finalTemplates, setFinalTemplates] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const operatorId = localStorage.getItem('operatorId');

    useEffect(() => {
        dispatch(fetchCountries(setLoading, setCallState))
    }, [dispatch]);

    const countries = useSelector(state => state.Countries.countries)
    const countryOptions = countryOptionss(countries?.countries)
    const operatorOptions = [
        {
            name: "Domain",
            value: "Operator",
            options: OperatorOptionsFields
        },
        {
            name: "country",
            value: "Operator",
            options: countryOptions
        },
        {
            name: "Status",
            value: "Operator",
            options: OperatorStatusOptions
        }
    ]
    const initialValues= {}
    useEffect(() => {
        dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions, OperatorStatusOptions, setEditData))
            .then(() => {
                setValues({ ...initialValues });
            });
        dispatch(fetchOperatorSpecificConfigurations(setLoading,operatorId))
    }, [dispatch, setLoading]);

    useEffect(() => {
        if (values?.Domain) {
            dispatch(fetchAllOperatorDefaultConfigurations(setLoading, values.Domain.label));
        }
    }, [dispatch, values?.Domain]);
    const OperatorDefaultConfigurationList = useSelector((state) => state.GetAllDefaultConfiguration.OperatorDefaultConfiguration);

    const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId);
    const configData = useSelector((state) => state.OperatorSpecificConfig.OperatorSpecificConfiguration)

    const currentRole = localStorage.getItem('roleName'); 
    const editConfig = useMemo(() => {
        return {
            configurations: configData?.configurations?.filter(config => {
                const matchingDefaultConfig = OperatorDefaultConfigurationList?.find(
                    defaultConfig => defaultConfig.name.toLowerCase() === config.name.toLowerCase()
                );
                return matchingDefaultConfig?.scope?.includes(currentRole);
            }) || [],
            operatorId: operatorId
        };
    }, [configData, OperatorDefaultConfigurationList, currentRole]);


    useEffect(()=> {
        if(editConfig){
            setIsEdit(true);
        }
    },[editConfig])

    const handleConfirm = () => {
        const configArray = createConfigArray(finalValues, operatorData);
        const filteredConfigurations = configArray?.filter(config => {
            const matchingDefaultConfig = OperatorDefaultConfigurationList?.find(
                defaultConfig => defaultConfig?.name?.toLowerCase() === config?.name?.toLowerCase()
            );
            return matchingDefaultConfig?.scope?.includes(currentRole);
        });
    
        // Dynamically format template configurations
        const formattedTemplateConfigurations = finalTemplates.map(template => ({
            name: template.name,
            value: [
                {
                    key: template.name,
                    value: template.value
                }
            ],
            level: "Operator"
        }));
    
        const combinedConfigurations = [
            ...filteredConfigurations,
            ...formattedTemplateConfigurations
        ];
    
        const jsonObj = {
            "configurations": combinedConfigurations,
            "operatorId": operatorId
        };
    
        if(editConfig){
            dispatch(updateConfigOperator(jsonObj, setLoading, navigate,true))
        }else{
            dispatch(createConfigOperator(jsonObj, setLoading, navigate,true))
        }
    }

    return(
        <div id='csms' className='page-content'>
            {loading && <Loader />}
            <ConfigurationForOperator
                setFinalValues={setFinalValues}
                finalValues={finalValues}
                values={values}
                configData={editConfig}
                isEdit={isEdit}
                setDefaultConfigurations={setDefaultConfigurations}
                setFinalTemplates={setFinalTemplates}
                finalTemplates={finalTemplates}
            />
            <Row className="mb-5">
                <Col >
                    <Button color={"main-color"} className="position-absolute end-0 px-4 me-3" onClick={()=>handleConfirm()}>Confirm</Button>
                </Col>
            </Row>
        </div>
    )
}