import { CURRENCY_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchAllCurrencySuccess, fetchAllCurrencyFailure } from "./reducer";

export const fetchAllCurrencyCodes = (setLoading) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${CURRENCY_API}`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchAllCurrencySuccess(response))
        }
        if (response.error) {
            dispatch(fetchAllCurrencyFailure(response.error.message))
            setLoading(false)
        }
    })
}