import moment from "moment"
export const offerJsonObj = (values, selectedService, resourceData, state, globalState, serviceCode, serviceData, addOnState, isEdit) => {
    let validityArray = {}
    let attributesArray = []
    let grantsArray = []
    let chargesArray = []
    let resourceIdArray = []
    let recurringFrequencyArray = []
    let resourceIdArrayForCharges = []
    let recurringFrequencyArrayForCharges = []
    let usageRatePlanArray = []
    let recurringFrequencyArrayForUsageRatePlanArray = []
    let rateTier = []
    let serviceCodeForOffer = ""
    let selectService = []
    let aggregateVolume = []
    const roleName = localStorage.getItem('roleName')
    //telcoAdmin
    serviceData?.forEach((value, index) => {
        if (values?.serviceName?.value === value?.serviceCode) {
            serviceCodeForOffer = values?.serviceName?.value
            if ("provisioningAttributes" in value) {
                selectService.push(value?.provisioningAttributes)
            }
        }
    })
    let subscriptionValidityArray = {}
    const convertedStartDateTime = moment(values.offerStartsOn).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
    validityArray.from = convertedStartDateTime
    if (!state) {
        const convertedEndDateTime = moment(values.offerEndsOn).endOf('day').format();
        const appendedZ = moment(convertedEndDateTime).utc().set({ hour: 23, minute: 59, second: 59 }).format();
        validityArray.to = appendedZ;
    }

    subscriptionValidityArray.startTimeType = values?.subscriptionStartsOn?.value
    subscriptionValidityArray.endTimeType = values?.subscriptionEndsOn?.value
    subscriptionValidityArray.relativeStartTimeUnit = parseInt(values?.timePeriodOptionStart?.value);
    subscriptionValidityArray.relativeStartTimeValue = parseInt(values?.timePeriodStart)
    subscriptionValidityArray.relativeEndTimeUnit = parseInt(values?.timePeriodOption?.value);
    subscriptionValidityArray.relativeEndTimeValue = parseInt(values?.timePeriod)

    let grantsMergedArray = [...values.grants, ...values.recurringGrants]
    let chargesMergedArray = [...values.charges, ...values.recurringCharges]
    let usageRatePlanMergedArray = [...values.usageRatePlan, ...values.recurringUsageRatePlan]

    const innerArray = selectService[0]
    innerArray?.forEach((value) => {
        attributesArray.push({
            "name": value.name,
            "value": value.default,
            "valueType": value.valueType
        })
    })

    resourceData.forEach((value, index) => {
        grantsMergedArray.forEach((val, idx) => {
            if (val.resourceType?.label === value.name) {
                resourceIdArray.push(resourceData[index].resourceId)
            }
        })
        chargesMergedArray.forEach((val, idx) => {
            if (val.resourceType?.label === value.name) {
                resourceIdArrayForCharges.push(resourceData[index].resourceId)
            }
        })
    })

    grantsMergedArray?.forEach((val, index) => {
        recurringFrequencyArray?.push(parseInt(val?.recurringFrequency?.value))
    })
    chargesMergedArray?.forEach((val, index) => {
        recurringFrequencyArrayForCharges?.push(parseInt(val?.recurringFrequency?.value))
    })

    grantsMergedArray.forEach((value, index) => {
        grantsArray.push({
            "amount": value.resourceValue === "0" ? 0 : value.resourceValue,
            "infinite": value?.infinite?.label === undefined ? "" : value?.infinite?.value,
            "prorate": value.prorate,
            "overridable": value?.override === true?true:false,
            "resourceName": value.resourceType.value,
            "resourceId": resourceIdArray[index],
            "type": parseInt(value.type),
            "recurringFrequency": recurringFrequencyArray[index],
            "cycleAlignment": value?.cycleAlignment?.value,
            "customValidity": {
                "startTimeType": value?.validityStartsOn?.value,
                "endTimeType": value?.validityEndsOn?.value,
                "relativeStartTimeUnit": 0,
                "relativeStartTimeValue": 0,
                "relativeEndTimeUnit": value?.grantValidityEndsOn?.value,
                "relativeEndTimeValue": value?.validityStartsOn?.value === 0 ? 0 : (parseInt(value?.grantValidityEndsOnTimeperiod) || 1)
            },
            ...(value?.validityStartsOn?.value === 0 && {
                "valid": {
                    "from": moment(value.startDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                    "to": moment(value.endDateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                }
            }),
            ...(value?.rollover && {
                "rollover":{
                    "maxRolloverAmount": value?.rolloverModel?.rolloverAmount,
                    "maxCycleRollover": value?.rolloverModel?.rolloverCycle,
                    "rolloverPriotity": value?.rolloverModel?.rolloverPriority,
                }
            }),
        })

    })
    chargesMergedArray.forEach((value, index) => {
        chargesArray.push({
            "amount": value.resourceValue === "0" ? 0 : value.resourceValue,
            "infinite": value?.infinite?.label === undefined ? "" : value?.infinite?.value,
            "prorate": value.prorate,
            "overridable": value?.override === true?true:false,
            "resourceName": value.resourceType.value,
            "resourceId": resourceIdArrayForCharges[index],
            "type": parseInt(value.type),
            "recurringFrequency": recurringFrequencyArrayForCharges[index]
        })
    })

    usageRatePlanMergedArray.forEach((value) => {
        const rateTier = [];
        const pricingModels = value.pricingModel.value === 'volume' && Array.isArray(value.pricingModelNew)
            ? value.pricingModelNew
            : [{}];
        pricingModels.forEach((pricingModel) => {
            rateTier.push({
                "flatRate": parseFloat(value.flatRate),
                "id": 1,
                "maxQuantity": value.pricingModel.value === 'volume' ? parseFloat(pricingModel.endingUnit) : 0,
                "minQuantity": value.pricingModel.value === 'volume' ? parseFloat(pricingModel.startingUnit) : 0,
                "perUnitRate": value.pricingModel.value === 'volume' ? parseFloat(pricingModel.pricePerUnit) : parseFloat(value.perUnitRate),
                "tierType": value.pricingModel.value
            });
        });

        let validity = {
            "startTimeType": value?.validityStart?.value,
            "endTimeType": value?.validityEnd?.value,
            "relativeEndTimeUnit": value?.timeUnit?.value,
            ...(value?.validityStart?.value === 3 && { "from": moment(value?.startDateTime) }),
            ...(value?.validityStart?.value === 3 && { "to": moment(value?.endDateTime) }),
            "relativeEndTimeValue": value?.timeValue ? parseInt(value?.timeValue) : value?.timeValue,
        };

        // Check for cycleAlignment and recurringFrequency
        let usageRatePlanObject = {
            "eventType": value.eventType,
            "id": parseInt(value.usageId),
            "priority": parseInt(value.priority),
            "resourceName": value.resourceName.value,
            "uomName": value.unitOfMeasurement.value,
            "rateTier": rateTier,
            "validity": validity,
        };

        if (value.cycleAlignment && value.recurringFrequency) {
            usageRatePlanObject.type = 2;
            usageRatePlanObject.cycleAlignment = value.cycleAlignment.value;
            usageRatePlanObject.recurringFrequency = parseInt(value.recurringFrequency.value);
        }
        else {
            usageRatePlanObject.type = 1;
        }

        usageRatePlanArray.push(usageRatePlanObject);
    });
    const jsonObj = JSON.stringify({
        offerName: values.offerName,
        offerCode: values.offerCode,
        offerPriority: parseInt(values.offerPriority),
        description: values.description,
        validity: validityArray,
        globalScope: globalState,
        addOnOffer: addOnState,
        serviceCode: serviceCodeForOffer,
        aggregateVolume: Array.isArray(values.aggregateVolume)
            ? values.aggregateVolume.map(item => item.value)
            : values.aggregateVolume && values.aggregateVolume.value
                ? [values.aggregateVolume.value]
                : [],
        categories: Array.isArray(values.categories)
            ? values.categories.map(item => item.value)
            : values.categories && values.categories.value
                ? [values.categories.value]
                : [],
        ...(values?.policyName && values?.policyName.value && { policySpecification: [values?.policyName?.value] }),
        ...(attributesArray.length !== 0 && {
            attributes: attributesArray,
        }),
        ...(grantsArray.length !== 0 && {
            Grants: grantsArray,
        }),
        ...(chargesArray.length !== 0 && {
            Charges: chargesArray,
        }),
        ...(usageRatePlanArray.length !== 0 && {
            usageRatePlan: usageRatePlanArray,
        }),
        subscriptionValidity: subscriptionValidityArray,
        ...(isEdit && {
            effectiveAt: moment(values?.effectiveAt).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        }),
    });
    return jsonObj;

}