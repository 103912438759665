import { FieldArray } from "formik";
import { CardHeader, Button, CardBody, Row, Col, Label, FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import SelectField from "../../Utils/SelectField";
import InputField from "../../Utils/InputField";
import { Link } from "react-router-dom";
import { removeRowsFromGrantsAndCharges } from "./constants/checkEmptyFields";
import { grantsAndChargesHandle, handleGrantsAndChargesResourceValueAndUsageFields, handleInfiniteForGrantsAndCharges } from "./constants/GrantsAndRecurringFunctions";
import { GrantsValidityEnd, SubscriptionEndsOnOptions, SubscriptionStartsOnOptions, TimePeriodGrantOptions, TimePeriodOptions, ValidityGrantsOneTimeStartsOnOptions, ValidityStartsOnOptions } from "./constants/initialValues";
import { useEffect, useState } from "react";
import CommonDateTimePicker from "../../Utils/CommonDateTimePicker";
import moment from "moment";
import { handleGrantsOverrideState } from "./constants/displayInfinite";

export default function GrantsOneTime({
    touched, isEdit,
    errors, loading,
    values, resourceData,
    handleChange,disableValidityInGrantOne, setDisableValidityInGrantOneTime,
    overrideState,
    infiniteOptions, grantResourceOptions,
    setTouched, setFieldTouched, setFieldValue,
    isEmptyorInvalidFieldForGrantsAndCharges, isSubscribed, isCreate
}) {

    return (
        <FieldArray name="grants">
            {({ push, remove }) => (
                <>
                    <CardHeader className="d-flex justify-content-center align-items-center">
                        <h4 className="card-title mb-0 flex-grow-1 me-2"> Grants</h4>
                        {!isSubscribed && <Button color="secondary" className="btn-label" onClick={() => {
                            const lastIndex = values.grants.length - 1;
                            if (lastIndex === -1 || !isEmptyorInvalidFieldForGrantsAndCharges(lastIndex, values.grants)) {
                                push({ resourceType: '', resourceValue: "0", ceiling: '', floor: '', validityStartsOn: '', validityEndsOn: '',override: overrideState, prorate: false, type: "1", recurringFrequency: { value: '0', label: "false" } });
                                setTouched({
                                    ...touched,
                                    grants: {
                                        ...touched.grants,
                                        [lastIndex]: {
                                            resourceType: false,
                                        },
                                    },
                                });
                            }
                        }} > <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Grant </Button>}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            {values.grants.length === 0 ? (
                                <div className="text-center">No Grants</div>
                            ) : (
                                values.grants.map((val, index) => (
                                    <div key={index} className="border border-opacity-50 rounded mb-3">
                                        <Row className="mb-3 px-3 pt-3">
                                            <Col xs={12} className="d-flex justify-content-between align-items-center">
                                                <h3 className="card-title mb-0">Grant {index + 1}</h3>
                                                {!isEdit && (
                                                    <Link to="#" className="link-danger" onClick={() => {
                                                        remove(index);
                                                    }}>
                                                        <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                                                    </Link>
                                                )}
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3 p-3">
                                            <Col md={4} lg={4}>
                                                <Label for={`grants[${index}].resourceType`}>Resource Type<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    isLoading={loading}
                                                    className={(
                                                        touched.grants &&
                                                        touched.grants[index] &&
                                                        !values.grants[index].resourceType &&
                                                        !disableValidityInGrantOne[index]
                                                    ) || false ? 'error-input' : ''}
                                                    name={`grants[${index}].resourceType`}
                                                    isDisabled={isSubscribed}
                                                    value={values.grants[index].resourceType}
                                                    handleChange={grantsAndChargesHandle(`values.grants[${index}].resourceType`, values.grants[index], setFieldValue, setFieldTouched, resourceData, setDisableValidityInGrantOneTime, index)}
                                                    options={grantResourceOptions}
                                                />
                                                {errors.grants &&
                                                    errors.grants[index] &&
                                                    touched.grants &&
                                                    touched.grants[index] &&
                                                    touched.grants[index].resourceType ? (
                                                    <p className="text-danger">
                                                        {errors.grants[index].resourceType}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col md={4} lg={4}>
                                                <Label for={`grants[${index}].resourceValue`}>Resource Value<span className="text-danger"> *</span></Label>
                                                <InputField
                                                    name={`grants[${index}].resourceValue`}
                                                    className={'form-control'}
                                                    placeholder="Enter Amount"
                                                    type="number"
                                                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                    value={values.grants[index].resourceValue}
                                                    invalid={
                                                        errors.grants &&
                                                            errors.grants[index] &&
                                                            touched.grants &&
                                                            touched.grants[index] &&
                                                            touched.grants[index].resourceValue
                                                            && errors.grants[index].resourceValue ? true : false
                                                    }
                                                />
                                            </Col>

                                            <Col md={4} lg={4} >
                                                <Label>Type of Resource</Label>
                                                <InputField
                                                    className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                    value={"Non-Currency"}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].ceiling`}>Ceiling</Label>
                                                {values.grants[index].ceiling ? (
                                                    <InputField
                                                        className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                        value={values.grants[index].ceiling}
                                                        disabled
                                                    />
                                                ) : null}
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].floor`}>Floor</Label>
                                                {values.grants[index].floor ? (
                                                    <InputField
                                                        className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                        value={values.grants[index].floor}
                                                        disabled
                                                    />
                                                ) : null}
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].infinite`}>Infinite</Label>
                                                {values.grants[index].infinite && (
                                                    <SelectField
                                                        name={`grants[${index}].infinite`}
                                                        value={values.grants[index].infinite}
                                                        isDisabled={isSubscribed}
                                                        handleChange={handleInfiniteForGrantsAndCharges(`grants[${index}].infinite`, values.grants[index], setFieldValue)}
                                                        options={infiniteOptions}
                                                    />
                                                )}
                                            </Col>
                                            <Col lg={4} className="mt-3">
                                                <Label>Override</Label>
                                                <FormGroup switch className="d-flex align-items-start switch-state">
                                                    <div className="me-2">
                                                        <Input
                                                            type="switch"
                                                            disabled={isEdit}
                                                            checked={values.grants[index].override}
                                                            onChange={() => handleGrantsOverrideState(index, values, setFieldValue)}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].validityStartsOn`}>Validity Starts<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    isLoading={loading}
                                                    className={(
                                                        touched.grants &&
                                                        touched.grants[index] &&
                                                        !values.grants[index].validityStartsOn &&
                                                        !disableValidityInGrantOne[index]
                                                    ) || false ? 'error-input' : ''}
                                                    name={`grants[${index}].validityStartsOn`}
                                                    isDisabled={(isEdit && isSubscribed) || (disableValidityInGrantOne[index] && isCreate)}
                                                    value={values.grants[index].validityStartsOn}
                                                    handleChange={(value) => {
                                                        setFieldValue(`grants[${index}].validityStartsOn`, value)
                                                    }}
                                                    options={ValidityGrantsOneTimeStartsOnOptions}
                                                />
                                                {errors.grants &&
                                                    errors.grants[index] &&
                                                    touched.grants &&
                                                    touched.grants[index] &&
                                                    touched.grants[index].validityStartsOn &&
                                                    !disableValidityInGrantOne[index] ? (
                                                    <p className="text-danger">
                                                        {errors.grants[index].validityStartsOn}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            {values.grants[index]?.validityStartsOn?.label === "Specific Time" &&
                                                <Col md={4} lg={4} className="mt-3">
                                                    <Label>Start Date and Time<span className="text-danger"> *</span></Label>
                                                    <CommonDateTimePicker
                                                        name={`grants[${index}].startDateTime`}
                                                        className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                                                        type="datetime-local"
                                                        disabled={isSubscribed}
                                                        minDate={moment()}
                                                        handleChange={(newValue) => {
                                                            setFieldValue(`grants[${index}].startDateTime`, newValue);
                                                        }}
                                                        value={values.grants[index].startDateTime}
                                                    />
                                                </Col>}
                                            {values.grants[index]?.validityStartsOn?.label === "Specific Time" &&
                                                <Col md={4} lg={4} className="mt-3">
                                                </Col>}
                                            <Col md={4} lg={4} className="mt-3">
                                                <Label for={`grants[${index}].validityEndsOn`}>Validity Ends<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    isLoading={loading}
                                                    className={(
                                                        touched.grants &&
                                                        touched.grants[index] &&
                                                        !values.grants[index].validityEndsOn &&
                                                        !disableValidityInGrantOne[index]
                                                    ) || false ? 'error-input' : ''}
                                                    name={`grants[${index}].validityEndsOn`}
                                                    isDisabled={(isEdit && isSubscribed) || (disableValidityInGrantOne[index] && isCreate)}
                                                    value={values.grants[index].validityEndsOn}
                                                    handleChange={(value) => {
                                                        setFieldValue(`grants[${index}].validityEndsOn`, value)
                                                    }}
                                                    options={values.grants[index]?.validityStartsOn?.label === "Specific Time" ? GrantsValidityEnd.filter((_, idx) => idx !== 0 && idx !== 2) : GrantsValidityEnd}
                                                />
                                                {errors.grants &&
                                                    errors.grants[index] &&
                                                    touched.grants &&
                                                    touched.grants[index] &&
                                                    touched.grants[index].validityEndsOn &&
                                                    !disableValidityInGrantOne[index] ? (
                                                    <p className="text-danger">
                                                        {errors.grants[index].validityEndsOn}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            {values.grants[index]?.validityEndsOn?.label === "Specific Time" &&
                                                <Col md={4} lg={4} className="mt-3">
                                                    <Label>End Date and Time<span className="text-danger"> *</span></Label>
                                                    <CommonDateTimePicker
                                                        name={`grants[${index}].endDateTime`}
                                                        className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                                                        type="datetime-local"
                                                        disabled={isSubscribed}
                                                        minDateTime={values.grants[index].endDateTime}
                                                        handleChange={(newValue) => {
                                                            setFieldValue(`grants[${index}].endDateTime`, newValue);
                                                        }}
                                                        value={values.grants[index].endDateTime}
                                                    />
                                                </Col>}
                                            {values.grants[index]?.validityEndsOn?.label === "Specific Time" &&
                                                <Col md={4} lg={4} className="mt-3">
                                                </Col>}
                                            <Col md={4} lg={4}>
                                                {values.grants[index]?.validityEndsOn?.label === "Relative" && (
                                                    <Row className="mt-3">
                                                        <Col xxl={6} md={6} lg={6}>
                                                            <Label>Time Period</Label>
                                                            <InputField
                                                                name={`grants[${index}].grantValidityEndsOnTimeperiod`}
                                                                disabled={isSubscribed}
                                                                type={'number'}
                                                                className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                                                                id={"grantValidityEndsOnTimeperiod"}
                                                                handleChange={handleChange}
                                                                value={values.grants[index].grantValidityEndsOnTimeperiod}
                                                            />
                                                        </Col>
                                                        <Col xxl={6} md={6} data-testid="select-component" className="mt-4">
                                                            <SelectField
                                                                name={`grants[${index}].grantValidityEndsOn`}
                                                                isDisabled={isSubscribed}
                                                                value={values.grants[index].grantValidityEndsOn || ""}
                                                                handleChange={(val) => {
                                                                    values.grants[index].grantValidityEndsOn = val
                                                                    setFieldValue(`grants[${index}].grantValidityEndsOn`, val);
                                                                }}
                                                                options={TimePeriodGrantOptions}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                            )}
                        </div>
                    </CardBody>
                </>
            )}
        </FieldArray>
    );
}