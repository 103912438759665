import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    recharge : [],
    loading : false,
    error : null,
};

const rechargeSlice = createSlice({
    name:"recharge",
    initialState,
    reducers: {
        fetchRechargeSuccess: (state,action) => {
            state.loading = false;
            state.recharge = action.payload;
        },
        fetchRechargeFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchRechargeSuccess,
    fetchRechargeFailure
} = rechargeSlice.actions

export default rechargeSlice.reducer