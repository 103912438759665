import { Button } from "reactstrap";
import { navigateToCreateCustomer } from "./constants/ListingCustomerFunctions";
import { handleExportData } from "../Utils/ExportToCsv";
import SelectField from "../Utils/SelectField";
import { useSelector } from "react-redux";

export const CustomerTableCustomTableButtons = ({ toggleModal, setMessageForPopUp, setCustomerArray, table, exportData, customerPermission, navigate, selectedCustomer }) => {
    const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)

    const isEIPEnabled = operatorConfiguration?.configurations
        ?.find(config => config.name === "EIP")
        ?.value?.find(item => item.key === "Enabled")
        ?.value?.[0] === "True";
    const buttonLabel = isEIPEnabled ? "New Device" : "New Customer";
    return (
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
            <div className="d-flex flex-column flex-md-row">
                <Button
                    color="secondary"
                    className="btn-label me-2 mb-2 mb-md-0"
                    onClick={() => {
                        handleExportData(exportData);
                    }}
                >
                    <i className="ri-upload-line label-icon align-middle fs-16 me-2 "></i>
                    Export Data
                </Button>
                {customerPermission && customerPermission.permissionList.includes('create') &&
                    <Button
                        color="secondary"
                        className="btn-label me-2 mb-2 mb-md-0"
                        onClick={() => navigateToCreateCustomer(navigate)}
                    >
                        <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>
                        {buttonLabel}
                    </Button>
                }
                {selectedCustomer?.length ? <Button className="btn me-2" color='main-color' onClick={() => {
                    const names = selectedCustomer.map(row => row.CustomerId);
                    let uniqueCustomers = Array.from(new Set(names))
                    setCustomerArray(uniqueCustomers);
                    setMessageForPopUp(`Are you sure you want to delete the selected items?`)
                    toggleModal(selectedCustomer, "multi")
                }}>Close</Button> : null}
            </div>
        </div>
    )
}