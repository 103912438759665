import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    QuotaByEvent: [],
    loading: false,
    error: null,
}

const QuotaByEventSlice = createSlice({
    name: "QuotaByEvent",
    initialState,
    reducers: {
        fetchQuotaByEventStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchQuotaByEventSuccess: (state, action) => {
            state.loading = false,
                state.QuotaByEvent = action.payload;
        },
        fetchQuotaByEventFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})
export const {
    fetchQuotaByEventStart,
    fetchQuotaByEventSuccess,
    fetchQuotaByEventFailure,
} = QuotaByEventSlice.actions;

export default QuotaByEventSlice.reducer;