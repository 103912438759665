import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Col, Row, Card, CardHeader, CardBody, FormGroup, Form, FormFeedback, Table } from "reactstrap";
import { useEffect, useState} from "react";
import moment from "moment";
import { LabelText } from "../Utils/Input";
import { handleSelectOffer } from "./constants/OrderFilterFunctions";
import CommonDateTimePicker from "../Utils/CommonDateTimePicker";
import InputField from "../Utils/InputField";
import { Formik } from "formik";
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux";
import { subscribeOrder } from "../../slices/orderManagement/Subscribe/thunk";
import SelectField from "../Utils/SelectField";
import { Link } from "react-router-dom";

export const OrderFilterAccordion = ({ open, toggle, loading, filteredResults, navigate, setLoading, subscriptionId }) => {
    const [selectedOffers, setSelectedOffers] = useState([]);
    const [startsOn, setStartsOn] = useState(null);
    const [endsOn, setEndsOn] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const roleName = localStorage.getItem('roleName');
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);
    const [attributes, setAttributes] = useState(false)
    const [viewDetails, setViewDetails] = useState({})
    const [resourceOptions, setResourceOptions] = useState([])
    const [overrideResource, setOverrideResource] = useState({
        0: [{ resourceName: null, value: '' }]
    });
    const [formValues, setFormValues] = useState({
        subscriptionId: "",
        startsOn: null
    });

    const operatorConfiguration = useSelector((state)=>state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)
    const prefix = operatorConfiguration?.configurations?.find(config => config?.name === "Prefixes")?.value[1]?.value[0]
    const sequence = operatorConfiguration?.configurations?.find(config => config?.name === "SequenceHandling")?.value[1]?.value[0] 
    const id = prefix && prefix + 'X'.repeat(sequence?.length) 

    const addOn = location.pathname.includes('add-on')
    const dispatch = useDispatch()
    const handleSelect = (offer, offerIndex, index) => {
        if (roleName === 'telcoAdmin' || roleName === 'telcoOperationUser' || roleName === 'telcoBusinessUser') {
            setSelectedOffers([offer]);
            setSelectedItemIndex(offerIndex)
            setSelectedServiceIndex(index)
            setAttributes(offer.provisioningAttributes.length > 0)
            const grants = offer?.Grants?.filter(grant => grant.overridable === true)?.map(grant => grant.resourceName);
            const charges = offer?.Charges?.filter(charge => charge.overridable === true)?.map(charge => charge.resourceName);
            setResourceOptions([...grants, ...charges]);
            setOverrideResource(prev => ({
                ...prev,
                [offerIndex]: prev[offerIndex] || [{ resourceName: null, value: '' }]
            }));
        } else {
            handleSelectOffer(offer, navigate, subscriptionId);
        }
    };
    const handleAddResource = (offerIndex) => {
        setOverrideResource(prev => ({
            ...prev,
            [offerIndex]: [
                ...(prev[offerIndex] || []), 
                { resourceName: null, value: '' } 
            ]
        }));
    };
    const handleRemoveResource = (offerIndex, resourceIndex) => {
        setOverrideResource(prev => {
            const updatedResources = [...(prev[offerIndex] || [])];
            updatedResources.splice(resourceIndex, 1); 
    
            return {
                ...prev,
                [offerIndex]: updatedResources
            };
        });
    };
    const toggleViewDetails = (serviceIndex, offerIndex) => {
        const key = `${serviceIndex}-${offerIndex}`;
        setViewDetails((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const filteredResources = Object.values(overrideResource)
    .flat()
    .filter(resource => resource.resourceName?.value?.trim())
    .map(resource => ({
        resourceName: resource.resourceName.value,
        amount: parseFloat(resource.value) || 0
    }));
    const initialValues = {
        subscriptionId: "",
        startsOn: null
    }
    const handleConfirm = (values) => {
        const selectedData = selectedOffers.map((offer) => ({
            offer,
            startsOn,
            endsOn,
            subscriptionID: values.subscriptionId
        }));

        moment(values.offerStartsOn).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]')
        if (selectedData.length > 0) {
            const jsonData = {
                ...(values.subscriptionId!=="" && {subscriptionID: addOn ? subscriptionId : selectedData[0]?.subscriptionID}),
                offerCode: selectedData[0]?.offer.offerCode,
                customerId: selectedData[0]?.offer.CustomerId,
                quantity: quantity,
                validFrom: values.startsOn ? moment(values.startsOn?._d).format('YYYY-MM-DDTHH:mm:ss[Z]') : moment().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                // ...(values.startsOn && {validFrom: moment(values.startsOn?._d).format('YYYY-MM-DDTHH:mm:ss[Z]'),}),
                ...(endsOn && { validTo: moment(endsOn?._d).format('YYYY-MM-DDTHH:mm:ss[Z]'), }),
                ...(filteredResources.length > 0 ? { resources: filteredResources } : {})
            };
            if (jsonData.subscriptionID !== "" && jsonData.validFrom) {
                dispatch(subscribeOrder(jsonData, setLoading, navigate));
            }
        }
    };

    const validationSchema = Yup.object({
        subscriptionId: id ? Yup.string() : Yup.string().required('Kindly enter Subscription ID'),
    })
    

    return (
        <Accordion open={open} toggle={toggle}>
            {loading ? (
                <p></p>
            ) : (
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleConfirm}>
                    {({ values, touched, errors, handleChange, handleSubmit, handleBlur, setFieldValue }) => {
                        useEffect(() => {
                            setFormValues(values);
                        }, [values]) 
                        return(
                        <Form onSubmit={handleSubmit} >
                            {filteredResults.map(([serviceName, offers], index) => (
                                serviceName && (
                                    <AccordionItem key={index}>
                                        <AccordionHeader targetId={`${index}`}>
                                            {serviceName}
                                        </AccordionHeader>
                                        <AccordionBody accordionId={`${index}`}>
                                            {offers.map((offer, offerIndex) => {
                                                const key = `${index}-${offerIndex}`;
                                                return (
                                                    <>
                                                        <Row className="gy-4 ms-2">
                                                            <Col className="pt-2 ps-3" xxl={4} md={6}>
                                                                <h5 className="offer-name me-2 text-black fst-italic">{offer.offerName}</h5>
                                                            </Col>
                                                        </Row>
                                                        <Row className="gy-4 mb-2 ms-2" key={offerIndex}>
                                                            <Col className="p-3" xxl={4} md={6}>
                                                                <div>
                                                                    <div className="d-flex mb-3">
                                                                        <h5 className="offer-name offer-code-color">Offer Code : <span className="text-black">{offer.offerCode}</span></h5>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        {offer.Grants?.length > 0 && (
                                                                            <div className="badge-for-order me-2 p-1">
                                                                                <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Grants?.length} />
                                                                                <LabelText classLabelName="mb-0" displayText="Grant" />
                                                                            </div>
                                                                        )}
                                                                        {offer.Charges?.length > 0 && (
                                                                            <div className="badge-for-order me-2 p-1">
                                                                                <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Charges?.length} />
                                                                                <LabelText classLabelName="mb-0" displayText="Charge" />
                                                                            </div>
                                                                        )}
                                                                        {offer.Usage?.length > 0 && (
                                                                            <div className="badge-for-order me-2 p-1">
                                                                                <LabelText classLabelName="mb-0 me-1 ps-1 badge-for-offer-count" displayText={offer.Usage?.length} />
                                                                                <LabelText classLabelName="mb-0" displayText="Usage" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={1} md={6}></Col>
                                                            <Col xxl={3} md={6}>
                                                                <div className="d-flex justify-content-start align-items-center mb-3">
                                                                    <h5 className="fs-6 pe-2 mt-3 fw-semibold offer-code-color">
                                                                        Term Validity:
                                                                    </h5>
                                                                    <h5 className="fs-6 mt-3 fw-lighter text-black">{offer.termValidity}</h5>
                                                                </div>
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    {offer?.categories?.map((category) => (
                                                                        <div className="me-2 text-black border px-2 py-1 offer-bg-color ">{category}</div>
                                                                    ))}
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} md={6}>
                                                                <div className="d-flex justify-content-end align-items-center mb-3">
                                                                    <Button className="px-2 py-1 mt-3" color="secondary" onClick={() => handleSelect(offer, offerIndex, index)}>
                                                                        Select
                                                                    </Button>
                                                                </div>
                                                                <div className="d-flex justify-content-end align-items-center mt-4 fst-italic view-more-color" onClick={() => { toggleViewDetails(index, offerIndex) }}>View {viewDetails[key] ? "Less" : "More"}</div>
                                                            </Col>
                                                            {viewDetails[key] &&
                                                                <Row className="ms-1 mt-3">
                                                                    <Col className="px-2" xxl={4} md={6}>
                                                                        <div className="d-flex mb-3">
                                                                            <h5 className="offer-name offer-code-color">Grant</h5>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start align-items-center">
                                                                            {offer?.GrantCard?.length ? offer?.GrantCard?.map((grant) => (
                                                                                <div className="me-2 text-black border px-2 py-1 offer-bg-color ">
                                                                                    <div className="fs-3 fw-bold d-flex justify-content-center align-items-center">
                                                                                        {grant?.grantAmount} {grant?.uom}
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        {grant?.name}
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        {"("}{grant?.oneTimeorRecurring}{")"}
                                                                                    </div>
                                                                                </div>
                                                                            )) : <div className="text-black">No grants available</div>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col xxl={1} md={6}></Col>
                                                                    <Col xxl={3} md={6}>
                                                                        <div className="d-flex mb-3">
                                                                            <h5 className="offer-name offer-code-color">Charge</h5>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start align-items-center">
                                                                            {offer?.ChargeCard?.length ? offer?.ChargeCard?.map((charge) => (
                                                                                <div className="me-2 text-black border py-3 px-2 offer-bg-color ">
                                                                                    <div className="fs-3 fw-bold d-flex justify-content-center align-items-center">
                                                                                        {charge?.currencySymbol} {charge?.chargeAmount}
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        {"("}{charge?.oneTimeorRecurring}{")"}
                                                                                    </div>
                                                                                </div>
                                                                            )):<div className="text-black">No charges available</div>}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {((roleName === "telcoAdmin" || roleName === 'telcoOperationUser' || roleName === 'telcoBusinessUser') && selectedItemIndex === offerIndex && selectedServiceIndex === index) && selectedOffers.map((offer, index) => (
                                                                <>
                                                                    <Card className=" border border-numeric-code" key={index}>
                                                                        <CardHeader>
                                                                            <h4 className="card-title mb-0 flex-grow-1">Subscription</h4>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col xxl={3} md={3}>
                                                                                    <FormGroup>
                                                                                        <LabelText htmlFor={'subscriptionId'} className={'text-danger'} displayText={'Subscription ID'} important={!id && '*'}/>
                                                                                        <InputField
                                                                                            name={"subscriptionId"}
                                                                                            placeholder={"Enter the Subscription ID "}
                                                                                            type={"text"}
                                                                                            className={"form-control"}
                                                                                            id={"code"}
                                                                                            value={addOn ? subscriptionId : values.subscriptionId}
                                                                                            handleChange={handleChange}
                                                                                            handleBlur={handleBlur}
                                                                                            disabled={addOn}
                                                                                            invalid={(touched.subscriptionId && errors.subscriptionId && !addOn) ? true : false}
                                                                                        />
                                                                                        {touched.subscriptionId && errors.subscriptionId ? (
                                                                                            <FormFeedback>{errors.subscriptionId}</FormFeedback>
                                                                                        ) : null}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xxl={3} md={3}>
                                                                                    <LabelText htmlFor={"startsOn"} displayText={"Starts On"} />
                                                                                    <CommonDateTimePicker
                                                                                        className={`form-control ${touched.startsOn &&
                                                                                            !values.startsOn ? (
                                                                                            "error-input"
                                                                                        ) : ""}`}
                                                                                        value={values.startsOn}
                                                                                        name={`startsOn`}
                                                                                        handleChange={(val) => { setFieldValue('startsOn', val) }}
                                                                                        format="DD/MM/YYYY"
                                                                                        views={['year', 'month', 'day']}
                                                                                    />
                                                                                </Col>
                                                                                <Col xxl={3} md={3}>
                                                                                    <LabelText htmlFor={"endsOn"} displayText={"Ends On"} />
                                                                                    <CommonDateTimePicker
                                                                                        className={`form-control`}
                                                                                        value={endsOn}
                                                                                        name={`endsOn`}
                                                                                        handleChange={(values) => { setEndsOn(values) }}
                                                                                        format="DD/MM/YYYY"
                                                                                        minDate={values.startsOn}
                                                                                        views={['year', 'month', 'day']}
                                                                                    />
                                                                                </Col>
                                                                                <Col xxl={3} md={3} className="d-flex flex-column align-items-center justify-content-start">
                                                                                    <LabelText htmlFor={"Quantity"} displayText={"Quantity"} className="mb-2" /> {/* Label is now above */}
                                                                                    <div className="quantity-control d-flex align-items-center">
                                                                                        <button
                                                                                            className="btn-circle"
                                                                                            type="button"
                                                                                            onClick={() => setQuantity(prev => Math.max(1, prev - 1))}>
                                                                                            <span>-</span>
                                                                                        </button>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="quantity-input mx-2"
                                                                                            id="quantity"
                                                                                            value={quantity}
                                                                                            onChange={(e) => {
                                                                                                const value = e.target.value;
                                                                                                if (value === '') {
                                                                                                    setQuantity('');
                                                                                                } else {
                                                                                                    setQuantity(Math.max(1, Number(value)));
                                                                                                }
                                                                                            }}
                                                                                            min={1}
                                                                                        />

                                                                                        <button
                                                                                            className="btn-circle"
                                                                                            type="button"
                                                                                            onClick={() => setQuantity(prev => prev + 1)}>
                                                                                            <span>+</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </CardBody>
                                                                    </Card>
                                                                    <Card className="border">
                                                                        <CardHeader className="d-flex justify-content-between align-items-center pb-2 pt-2">
                                                                                <h4 className="card-title mb-0">Resource Override</h4>
                                                                                {(overrideResource[offerIndex].length < resourceOptions.length ) ?
                                                                                  (
                                                                                    <button type="button" className="btn btn-primary" onClick={() => handleAddResource(offerIndex)}>
                                                                                        + Add
                                                                                    </button>
                                                                            ): null}
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Row>
                                                                                {(Array.isArray(overrideResource[offerIndex]) ? overrideResource[offerIndex] : []).map((resource, resourceIndex) => (
                    
                                                                                    <Row key={resourceIndex}>
                                                                                        <Col xxl={3} md={3}>
                                                                                            <FormGroup>
                                                                                                <LabelText htmlFor={'resourceName'} className={'text-danger'} displayText={'Resource Name'} />
                                                                                                <SelectField
                                                                                                    name={`resourceName-${resourceIndex}`}
                                                                                                    options={resourceOptions
                                                                                                        .filter(option => 
                                                                                                            !overrideResource[offerIndex]?.some(r => r.resourceName?.value === option) 
                                                                                                        )
                                                                                                        .map(option => ({ value: option, label: option }))
                                                                                                    }
                                                                                                    value={resource.resourceName}
                                                                                                    handleChange={(selectedOption) => {
                                                                                                        setOverrideResource(prev => ({
                                                                                                            ...prev,
                                                                                                            [offerIndex]: prev[offerIndex].map((r, i) => 
                                                                                                                i === resourceIndex ? { ...r, resourceName: selectedOption } : r
                                                                                                            )
                                                                                                        }));
                                                                                                    }}
                                                                                                    placeholder="Select Resource Name"
                                                                                                    isClearable={true}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col xxl={3} md={3}>
                                                                                            <FormGroup>
                                                                                                <LabelText htmlFor={'value'} className={'text-danger'} displayText={'Value'} />
                                                                                                <InputField
                                                                                                    name={`value-${resourceIndex}`}
                                                                                                    placeholder={"Enter the Value"}
                                                                                                    type={"text"}
                                                                                                    className={"form-control"}
                                                                                                    id={`value-${resourceIndex}`}
                                                                                                    value={resource.value}
                                                                                                    handleChange={(e) => {
                                                                                                        const newValue = e.target.value;
                                                                                                        setOverrideResource(prev => ({
                                                                                                            ...prev,
                                                                                                            [offerIndex]: prev[offerIndex].map((r, i) =>
                                                                                                                i === resourceIndex ? { ...r, value: newValue } : r
                                                                                                            )
                                                                                                        }));
                                                                                                    }}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col xxl={1} md={1} sm={2} className="d-flex align-self-center mt-3">
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="link-danger fs-4"
                                                                                                onClick={() => handleRemoveResource(offerIndex, resourceIndex)}
                                                                                            >
                                                                                                <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                                                                                            </Link>
                                                                                        </Col>
                                                                                    </Row>
                                                                                ))}
                                                                            </Row>
                                                                        </CardBody>
                                                                    </Card>
                                                                    {attributes && <Card>
                                                                        <CardHeader className="d-flex justify-content-between align-items-center ">
                                                                            <h4 className="card-title mb-0 flex-grow-1">Attributes</h4>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Table className=' table-border-none'>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Name</th>
                                                                                        <th>Value</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {offer?.provisioningAttributes.map((attribute, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{attribute.name}</td>
                                                                                            <td>{attribute.value}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </Table>
                                                                        </CardBody>
                                                                    </Card>}
                                                                </>
                                                            ))}
                                                            <hr />
                                                        </Row>
                                                    </>
                                                )
                                            })}
                                        </AccordionBody>
                                    </AccordionItem>
                                )
                            ))}
                            {(roleName === "telcoAdmin" || roleName === 'telcoOperationUser' || roleName === 'telcoBusinessUser') && selectedOffers.length > 0 && (
                                <div className="d-flex justify-content-end mt-3">
                                    <Button color="main-color" type="submit" >
                                        Confirm
                                    </Button>
                                </div>
                            )}
                        </Form>
                        )
                    }}
                </Formik>
            )}
        </Accordion>
    );
};
