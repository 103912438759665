import { fetchGetAttributeFailure, fetchGetAttributeSucess } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { CREATE_EDIT_GET_ATTRIBUTE_API } from "../../constants/constants";
import { attributeOptions } from "../../../Components/Pricings/Attributes/constants/InitialValues";

export const getAttributeByName = (attributeName, setLoading, initialValues) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${CREATE_EDIT_GET_ATTRIBUTE_API}/${attributeName}`, 'GET').then((response) => {
        setLoading(false)
        dispatch(fetchGetAttributeSucess(response))
        const mappedArray = response?.values?.map(value => ({
            label: value,
            value: value
        }));
        initialValues.name = response.name
        initialValues.type = attributeOptions.find(attr => attr.name === 'type')?.options?.find(option => option.value === response.type);
        initialValues.valueType = attributeOptions.find(attr => attr.name === 'valueType')?.options?.find(option => option.value === response.valueType);
        initialValues.description = response.description
        initialValues.defaultValue =  response.default
        initialValues.minimumValue = response.minValue
        initialValues.maximumValue = response.maxValue
        initialValues.attributeValue = mappedArray
        initialValues.SelfcareAttribute = mappedArray
        initialValues.sequenceValue = response.sequenceValue
        initialValues.valueFormat = (response.sequenceValue !== "" )? {value: 'sequence', label: 'Sequence'} :{value: 'default', label: 'Default'}
    }).catch((errorResp) => { dispatch(fetchGetAttributeFailure(errorResp.message)) })
}