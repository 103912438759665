import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    quota : [],
    loading : false,
    error : null,
};

const quotaSlice = createSlice({
    name:"quota",
    initialState,
    reducers: {
        fetchQuotaSuccess: (state,action) => {
            state.loading = false;
            state.quota = action.payload;
        },
        fetchQuotaFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchQuotaSuccess,
    fetchQuotaFailure
} = quotaSlice.actions

export default quotaSlice.reducer