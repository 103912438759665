import { Formik } from "formik"
import { Button, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { initialPasswordValues } from "./constants/constantValues"
import { validationSchemaForPassword } from "./constants/validationSchema"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { UpdatePassword } from "../../slices/customer/passwordUpdate/thunk"
import { toast } from "react-toastify"

export const PasswordModal = ({passwordModal, setPasswordModal, setLoading, navigate, passwordCustomer, setPasswordCustomer}) => {
    const [newPassword, setNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const togglenewPassword = () => {
        setNewPassword(!newPassword);
    };
    const toggleconfirmPassword = () => {
        setConfirmPassword(!confirmPassword);
    };

    const status = useSelector(state=>state.SetUpdatePassword.SetUpdatePassword)
    const details = useSelector(state => state.SetUpdatePassword.userDetail)

    const maskText = (str) => {
        if(str!=="")
            return str[0] + "X".repeat(str?.length - 2) + str[str?.length - 1];
    };
    
    return(
        <Formik
            initialValues={initialPasswordValues}
            validationSchema={validationSchemaForPassword}
            onSubmit={(values) => {
                const jsonObj = {
                "newPassword": values.newPassword,
                "confirmNewPassword": values.confirmNewPassword,
                };
                dispatch(UpdatePassword(jsonObj,passwordCustomer.CustomerId, setLoading, navigate,passwordCustomer, setPasswordCustomer));
            }}
        >
            {({ handleSubmit, errors, touched, handleChange, handleBlur, values,resetForm }) => {
                const toggle =() => {
                    if(passwordModal)
                        resetForm();
                    setPasswordModal(!passwordModal)
                }
                const copyCredentials = (username, password) => {
                    const credentials = `${username}\n${password}`;
                    navigator.clipboard.writeText(credentials).then(() => {
                        toast.success(`Username and Password has been copied to clipboard`)
                    }).catch();
                    setPasswordModal(!passwordModal)
                    resetForm()
                };
                return(
                <Modal isOpen={passwordModal} centered toggle={toggle} backdrop="static" className="password-modal rounded-3" backdropClassName="custom-backdrop">
                    {status !== "Success"?
                    (
                    <>
                    <ModalHeader className="d-flex justify-content-center align-items-center position-relative">
                    <h5 className="fw-normal text-main-color m-0 mx-auto">
                        Configure Password
                    </h5>
                    <button 
                        type="button" 
                        className="btn-close fs-6 position-absolute top-0 end-0 m-3"
                        onClick={toggle}
                    />
                    </ModalHeader>
                    <ModalBody>
                            <Form id="csms" className="needs-validation" onSubmit={handleSubmit}>
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <div className='mb-3'>
                                        <Label className='form-label' htmlFor='newPassword'>New Password</Label>
                                        <div className='position-relative auth-pass-inputgroup mb-3'>
                                            <Input
                                                id='newPassword'
                                                name='newPassword'
                                                type={newPassword ? 'text' : 'password'}
                                                value={values.newPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={touched.newPassword && !!errors.newPassword}
                                                className='form-control pe-5'
                                            />
                                            {touched.newPassword && errors.newPassword && (
                                                <FormFeedback>{errors.newPassword}</FormFeedback>
                                            )}
                                            <button
                                                className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none'
                                                onClick={togglenewPassword}
                                                type='button'
                                                id='newPassword-addon'
                                            >
                                                <i className={newPassword ? 'ri-eye-off-fill align-middle' : 'ri-eye-fill align-middle'}></i>
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                                <Row className="gy-4">
                                    <div className='mb-0 pb-0'>
                                        <Label className='form-label' htmlFor='confirmNewPassword'>Confirm New Password</Label>
                                        <div className='position-relative auth-pass-inputgroup mb-3'>
                                            <Input
                                                id='confirmNewPassword'
                                                name='confirmNewPassword'
                                                type={confirmPassword ? 'text' : 'password'}
                                                value={values.confirmNewPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={touched.confirmNewPassword && !!errors.confirmNewPassword}
                                                className='form-control pe-5'
                                            />
                                            {touched.confirmNewPassword && errors.confirmNewPassword && (
                                                <FormFeedback>{errors.confirmNewPassword}</FormFeedback>
                                            )}
                                            <button
                                                className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none'
                                                onClick={toggleconfirmPassword}
                                                type='button'
                                                id='confirmNewPassword-addon'
                                            >
                                                <i className={confirmPassword ? 'ri-eye-off-fill align-middle' : 'ri-eye-fill align-middle'}></i>
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleSubmit} className='btn bg-secondary w-100'>
                            Save
                        </Button>
                    </ModalFooter>
                    </>):
                    (<>
                        <ModalHeader toggle={toggle} className="mb-0"/>
                        <ModalBody className="d-flex flex-column justify-content-center align-items-center text-center mt-0 pt-0" toggle={toggle}>
                            <i className="modal-icon ri-checkbox-circle-fill mb-2" />
                            <div>
                                <h4>Password Changed</h4>
                                <p>{passwordCustomer.Name}'s password has been changed successfully</p>
                                <div className="d-flex flex-column whitespace">
                                <p>User Name : {details?.userName}</p>
                                <p>Password  : {maskText(values.newPassword)}</p>
                                <i className="ri-file-copy-line text-primary fs-1" onClick={()=>copyCredentials(details?.userName, values.newPassword)}/>
                                </div>
                            </div>
                        </ModalBody>
                    </>)}
                </Modal>
                )
        }}
        </Formik>
    )
}