import { getCellStyle } from "../../Utils/DecideColor";
import { Box } from "@mui/material";

export const operatorColoumns = () => {
  const environment = window.env.REACT_APP_LOGIN_ENVIRONMENT
    const columns = [
      {
        accessorKey: 'operatorId',
        header: 'Operator ID',
        size: 150,
      },
      {
        accessorKey: 'tradingName',
        header: 'Business Name',
        size: 150,
      },
      {
        accessorKey: 'domain',
        header: 'Domain',
        size: 150,
      },
      {
        accessorKey: 'startDateTime',
        header: 'Created On',
        size: 150,
      },
      {
        accessorKey: 'endDateTime',
        header: 'End Date',
        size: 150,
      },
     
      environment === 'Native' ? {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          let displayValue;
            switch (value) {
              case 'Active':
                displayValue = 'Active';
                break;
              case 'Inactive':
                displayValue = 'Inactive';
                break;
              case 'Closed':
                displayValue = 'Closed';
                break;
              default:
                displayValue = 'Unknown';
                break;
            }
          return (
            <Box
              sx={(theme) => value ? { ...getCellStyle(value, "Operator") } : {}}
            >
              {displayValue || 'Unknown'}
            </Box>
          );
        }
      } : {
        accessorKey: '',
        header: 'Status',
      }    
    ];
  
    return columns;
  };