import { setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { fetchCreateOperatorSucess, fetchCreateOperatorFailure } from "./reducer";
import { OPERATOR_CREATE_AND_EDIT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { uploadDocument } from "../../customer/documentupload/thunk";
import { createConfigOperator } from "../ConfigurationCreateOperator/thunk";
import { createConfigArray } from "../../../Components/OperatorManagement/Constant/ConfigFunction";

export const createOperator = (jsonObj, setLoading, navigate, file, finalValues, createNow) => (dispatch) => {
    setLoading(true)
    const roleName = localStorage.getItem("roleName")

    determineBaseUrlAndPerformApiCall(`${OPERATOR_CREATE_AND_EDIT_API}`, 'POST', jsonObj).then((response) => {

        if (!response.error) {
            setLoading(false)
            const formData = new FormData()
            formData.append("ID", response?.data?.operatorId)
            formData.append("file", file);
            if (roleName === "systemUser") {
                formData.append("userType", "operator")
            }
            formData.append("documentName", "logo");
            formData.append("documentType", "logo");
            formData.append('uploadedBy', response?.data?.tradingName)

            const configArray = createConfigArray(finalValues,response);
            const jsonObj = {
                "configurations": configArray,
                "operatorId": response?.data?.operatorId
            }

            const tasks = [];

            if (file) {
                tasks.push(dispatch(uploadDocument(formData, setLoading, response?.data?.operatorId, "Operator", navigate)));
            }

            if (createNow) {
                tasks.push(dispatch(createConfigOperator(jsonObj, setLoading, navigate,false)));
            }

            if (tasks.length > 0) {
                Promise.all(tasks).then(() => {
                    dispatch(setSuccessMessage(true));
                });
            } else {
                navigate('/operator-listing');
                dispatch(setSuccessMessage(true));
            }

            dispatch(fetchCreateOperatorSucess(response));

        } else if (response.error) {
            dispatch(fetchCreateOperatorFailure(response.error.message));
            setLoading(false);
            toast.error(`${response.error.message}`);
        }
    }).catch((errorResp) => {
        console.error('Error occurred:', errorResp);
        setLoading(false);
    });
}
