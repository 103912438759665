import { toast } from "react-toastify";
import {fetchAddQuotaSuccess, fetchAddQuotaFailure } from "./reducer";
import {QUOTA_MANAGEMENT_API } from "../../constants/constants";
import {setSuccessMessage } from "../../toastMessage/action";
import {determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { getAllQuota } from "../thunk";

export const addQuota = (jsonObj, setLoading,setModalOpen) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${QUOTA_MANAGEMENT_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            setModalOpen(false)
            dispatch(fetchAddQuotaSuccess(response))
            dispatch(getAllQuota(setLoading))
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchAddQuotaFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}