import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    GetAllCurrency : [],
    loading : false,
    error : null,
};

const getAllCurrencySlice = createSlice({
    name:"GetAllCurrency",
    initialState,
    reducers: {
        fetchAllCurrencySuccess: (state,action) => {
            state.loading = false;
            state.GetAllCurrency = action.payload;
        },
        fetchAllCurrencyFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchAllCurrencyFailure,
    fetchAllCurrencySuccess
} = getAllCurrencySlice.actions

export default getAllCurrencySlice.reducer