import React, { useState, useMemo, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, Label, FormGroup, Nav, NavItem, NavLink, Table, Button, Modal, ModalBody, Input, ModalFooter, FormFeedback } from "reactstrap";
import { LabelText } from "../Utils/Input";
import PieChart from "./constants/PieChart";
import { CustomerAddress, CustomerViewDetails } from "./CustomerViewDetail";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../Utils/SelectField";
import classnames from 'classnames';
import AggregateResourceCarousel from "./AggregateResource";
import { goToIndex, next, previous } from "./constants/corouselNavigation";
import { viewDoc } from "../../slices/customer/documentview/thunk";
import logoLarge from '../../assets/images/users/user-dummy-img.jpg'
import noData from '../../assets/images/aggregate/noData.png'
import CommonButton from "../Utils/CommonButton";
import { useNavigate } from "react-router-dom";
import { adjustJsonObject } from "./constants/CustomerJsonObj";
import { recharge } from "../../slices/Recharge/thunk";
import { Loader } from "../Utils/Loader";
import { setSuccessMessage } from "../../slices/toastMessage/action";
import { ToastContainer, toast } from "react-toastify";
import CommonDateTimePicker from "../Utils/CommonDateTimePicker";
import Select from "react-select";
import { Form, Formik } from "formik";
import { validationSchemaForAdjustment } from "./constants/validationSchema";

export const CustomerProfile = ({ customerPermission, customerData, fetchCustomerSummaryData, formatDate }) => {
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [activeTabForAddOn, setActiveTabForAddOn] = useState("1");
    const [addOnLength, setAddOnLength] = useState(0);
    const [baseOfferLength, setBaseOfferLength] = useState(0);
    const [profileData, setProfileData] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState("");
    const [description, setDescription] = useState("");
    const [modalStep, setModalStep] = useState(1);
    const toggleModal = (type = "") => {
        setModalType(type);
        setModalOpen(!modalOpen);
        setAdjustmentAmount();
        setDescription("");
    };
    const [adjustmentAmount, setAdjustmentAmount] = useState("");
    const [formData, setFormData] = useState({
        resourceName: "",
        offerCode: "",
        subscriptionId: "",
        balanceItemSpecId: "",
        validFrom: null,
        validTo: null
    });
    const [profileImage, setProfileImage] = useState(logoLarge);
    const [loading, setLoading] = useState(false);
    const [chargedResource, setChargedResource] = useState([]);
    const roleName = localStorage.getItem('roleName')
    const isSelfcareChangePlan = location.pathname.includes("selfcare-changeplan")
    const navigateToView = useNavigate()
    const aggregateResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAggregate);
    const dispatch = useDispatch()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData, // Keep previous state
            [name]: value // Update only the changed field
        }));
    };
    const tabChange = (tab) => {
        if (activeTabForAddOn !== tab) setActiveTabForAddOn(tab);
    };
    const groupedResources = useMemo(() => {
        return fetchCustomerSummaryData?.resources?.reduce((acc, resource) => {
            const key = `${resource.subscriptionId}-${resource.offerCode}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(resource);
            return acc;
        }, {});
    }, [fetchCustomerSummaryData]);

    const initialAdjustValues = {
        description: "",
        adjustmentAmount: "",
        resourceName: "",
        balanceItemSpecId: "",
        subscriptionId: "",
        offerCode: "",
        validFrom: null,
        validTo: null,
    }
    const handleSubscriptionChange = (selectedOption) => {
        setSelectedSubscriptionId(selectedOption ? selectedOption.value : "");
        setActiveIndex(0);
    };
    const uniqueSubscriptionIds = useMemo(() => {
        return [...new Set(
            fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAggregate && resource.subscriptionId)?.map(resource => resource.subscriptionId)
        )];
    }, [fetchCustomerSummaryData]);

    const showSuccessMessage = useSelector(
        (state) => state.SuccessToast.showSuccessMessage
    );
    useEffect(() => {
        if (showSuccessMessage && modalType === "Recharge") {
            toast.success(`Recharge Successful!`);
            dispatch(setSuccessMessage(false));
        }
        if (showSuccessMessage && modalType !== "Recharge") {
            toast.success(`Adjustment Successful!`);
            dispatch(setSuccessMessage(false));
        }
    }, [showSuccessMessage, dispatch]);

    useEffect(() => {
        const addOns = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAddOn) || [];
        const baseOffers = fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAddOn) || [];
        const chargeResource = fetchCustomerSummaryData?.resources?.filter(resource => resource.currencyCode) || [];
        setAddOnLength(addOns.length || 0);
        setBaseOfferLength(baseOffers.length || 0);
        setChargedResource(chargeResource);
    }, [fetchCustomerSummaryData?.resources]);
    useEffect(() => {
        if (uniqueSubscriptionIds.length > 0 && !selectedSubscriptionId) {
            setSelectedSubscriptionId(uniqueSubscriptionIds[0]);
        } else {
            setSelectedSubscriptionId("");
        }
    }, [uniqueSubscriptionIds, customerData]);
    useEffect(() => {
        if (customerData && customerData?.customerInfo?.userName) {
            let partnerOrCustomer = "Customer"
            dispatch(viewDoc(setLoading, customerData?.customerInfo?.customerId, "logo", partnerOrCustomer, setProfileData))
        }
    }, [dispatch, customerData]);
    let ViewDocument = useSelector(state => state.ViewDocument.documentView)
    let base64ImageData = ViewDocument?.filePath
    useEffect(() => {
        if (base64ImageData) {
            setProfileImage(base64ImageData)
        } else {
            setProfileImage(logoLarge)
        }
    }, [base64ImageData])

    const handleBalanceConfirm = () => {
        let jsonObject
        jsonObject = adjustJsonObject(chargedResource[0], fetchCustomerSummaryData.customerId, adjustmentAmount, description, modalType, formData)
        dispatch(recharge(jsonObject, setLoading))
        setAdjustmentAmount();
        setDescription("");
        setModalOpen(false);
        setFormData("");
    };
    const handleConfirm = (values) => {
        let jsonObject = adjustJsonObject(
            chargedResource[0],
            fetchCustomerSummaryData.customerId,
            values.adjustmentAmount,
            values.description,
            modalType,
            values,
            formData
        );
        dispatch(recharge(jsonObject, setLoading));
        setAdjustmentAmount("");
        setDescription("");
        setModalOpen(false);
    };

    const renderGrantsCarousel = (resources) => {
        const slides = resources.map((resource) => (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={resource.resourceName}
            >
                <Card className="border border-2 customer-view-screen">
                    <CardHeader className="d-flex align-items-center customer-view-screen">
                        <Label className="fs-5 mt-2">Grant Bucket</Label>
                    </CardHeader>
                    <CardBody className="px-5">
                        <div className="d-flex flex-column align-items-center flex-md-row">
                            <PieChart
                                remainingBalance={resource?.remainingBalance || resource?.originalBalance}
                                originalBalance={resource?.originalBalance}
                                width={"100%"}
                                height={200}
                            />
                            <div>
                                <p className="fw-bolder fs-5">{resource.resourceName}</p>
                                <p>
                                    <b>{resource?.remainingBalance || resource?.originalBalance}</b> left of <b>{resource?.originalBalance}</b>
                                </p>
                                <p className="d-flex justify-content-start">
                                    <LabelText displayText={`${formatDate(resource.validFrom)} - ${formatDate(resource.validTo)}`} />
                                </p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </CarouselItem>
        ));
        return (
            <Carousel
                activeIndex={activeIndex}
                next={() => next(resources, activeIndex, setActiveIndex, animating)}
                previous={() => previous(resources, activeIndex, setActiveIndex, animating)}
                interval={false}
            >
                <CarouselIndicators items={resources} activeIndex={activeIndex} onClickHandler={(newIndex) => goToIndex(newIndex, animating, setActiveIndex)} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous(resources, activeIndex, setActiveIndex, animating)} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={() => next(resources, activeIndex, setActiveIndex, animating)} />
            </Carousel>
        );
    };

    function extractOptionsFromResources(resources, key) {
        if (!Array.isArray(resources)) return [];
        const seenValues = new Set();
        return resources
            .map((resource) => {
                const value = resource[key];
                if (value !== undefined && value !== null && value !== "" && !seenValues.has(value)) {
                    seenValues.add(value);
                    return {
                        label: String(value),
                        value: value,
                    };
                }
                return null;
            })
            .filter(Boolean);
    }


    const resourceNameArray = extractOptionsFromResources(fetchCustomerSummaryData?.resources, "resourceName");
    const idArray = extractOptionsFromResources(fetchCustomerSummaryData?.resources, "id");
    const subscriptionIdArray = extractOptionsFromResources(fetchCustomerSummaryData?.resources, "subscriptionId");
    const offerCodeArray = extractOptionsFromResources(fetchCustomerSummaryData?.resources, "offerCode");

    return (
        <Row lg={12}>
            {roleName !== 'Customer' && (<Col lg={4}>
                <div className='listingjs-table' id='customerList'>
                    {loading && <Loader />}
                    <ToastContainer position="top-center" />
                    <Card className="border aggregate-resource-view mb-1">
                        <CardBody>
                            <div className='live-preview'>
                                <div className="text-center position-relative p-3">
                                    <div className="position-relative p-3 mx-auto mb-1">
                                        <img
                                            src={profileImage}
                                            className="rounded-circle outline-dark avatar-xl img-thumbnail user-profile-image mb-4"
                                            alt="user-profile"
                                        />
                                        <div className="fs-5">
                                            {customerData?.customerInfo?.userName || ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <Card className={`border pt-0 ${(!aggregateResources?.length && roleName !== "Customer") && "mt-4"}`}>
                    <>
                        <header className="p-1 ms-3 mt-0 pb-0 mb-0">
                            <h2 className="fs-5 mt-4 fw-medium balance-heading-color">Aggregate View</h2>
                        </header>
                        {aggregateResources?.length > 0 && roleName !== 'Customer' ?
                            <div className=" mb-2">
                                <AggregateResourceCarousel resources={aggregateResources} />
                            </div>
                            :
                            <div className="d-flex justify-content-center align-items-center mt-0 pt-0 mb-4">
                                <img
                                    src={noData}
                                    alt="No Data available"
                                    className='img-fluid w-50 user-profile-image mt-0 pt-0 mb-1'
                                    style={{ height: "175px" }}
                                />
                            </div>
                        }
                    </>
                </Card>
            </Col>)}
            {roleName !== 'Customer' && (
                <Col lg={8}>
                    <CustomerViewDetails customerData={customerData} customerPermission={customerPermission} aggregateResources={aggregateResources} />
                    <CustomerAddress customerData={customerData} aggregateResources={aggregateResources} />
                </Col>
            )}
            <Col lg={12}>
                <div className="border-bottom mt-n4" />
                <Card className="border border-2">
                    <CardHeader className="d-flex align-items-center">
                        <Label className="fs-5 mt-2 balance-heading-color">Subscriptions</Label>
                    </CardHeader>
                    {groupedResources ? (
                        <>
                            <Row>
                                <Col xxl={4} lg={4} md={4} xs={11} className="ms-3 mt-2 ">
                                    <FormGroup>
                                        <Label for="subscriptionSelect" className="fw-bold mt-1">Subscription ID</Label>
                                        <SelectField
                                            id="subscriptionSelect"
                                            value={selectedSubscriptionId ? { value: selectedSubscriptionId, label: selectedSubscriptionId } : null}
                                            placeholder="Select Subscription ID"
                                            handleChange={handleSubscriptionChange}
                                            options={uniqueSubscriptionIds.map(id => ({ value: id, label: id }))}
                                            isMulti={false}
                                        />
                                    </FormGroup>
                                </Col>
                                {chargedResource[0] ?
                                    <Col xxl={5} lg={5} md={5} xs={11} className="me-2 mt-2 ms-n4 d-flex justify-content-center">
                                        <div className=" me-5 border-md-0 border-sm-0 sample-file">
                                            <div className=" border-lg-start">
                                                <h5 className="fw-semibold ms-2">{chargedResource[0]?.type == 'Prepaid' ? 'Balance' : 'Balance Due'}</h5>
                                                <p className="fw-bold text-dark fs-1 ms-2">
                                                    {chargedResource[0]?.currencySymbol}{chargedResource[0]?.remainingBalance}
                                                    <span className="ms-2 fs-5 title-disable-color-edit-offer fw-light">{chargedResource[0]?.type == 'Prepaid' ? 'Left to spend' : ''}</span>
                                                </p>
                                                <p className="mt-n3 text-black mb-0 ms-2">{chargedResource[0]?.type == 'Prepaid' ? 'Remaining' : ''}</p>
                                            </div>
                                        </div>
                                    </Col> : null}
                                {chargedResource[0] ?
                                    <Col xxl={2} md={2} lg={2} xs={11} className=" mt-2 ms-n4 d-flex align-items-center justify-content-center">
                                        <div className="d-flex justify-content-end">
                                            <CommonButton
                                                color="secondary"
                                                className="px-4 mx-1"
                                                buttonAction={() => {
                                                    setModalType("Recharge");
                                                    setModalOpen(true);
                                                }}
                                                buttonText={"Recharge"}
                                            />
                                            <CommonButton
                                                color="secondary"
                                                className="px-3 mx-1 py-0 my-0"
                                                buttonAction={() => {
                                                    setModalType("Adjust");
                                                    setModalOpen(true);

                                                }}
                                                buttonText={
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <i className="ri-money-dollar-circle-line me-2 fs-2"></i>
                                                        Adjust
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </Col> : null}
                            </Row>
                            <Modal isOpen={modalOpen} toggle={toggleModal} centered={true} size="md" className="custom-modal">
                                <button type="button" className="close-button-currency mt-n1" onClick={toggleModal}>
                                    &times;
                                </button>
                                <h5 className="text-center w-100 mt-0 fw-semibold py-3 bg-pop-header">
                                    {modalType === "Recharge" ? "Recharge" : modalType === "Adjust" ? "Currency Adjustment" : "Non-Currency Adjustment"}
                                </h5>

                                <ModalBody>
                                    {modalType === "Recharge" ? (
                                        <>
                                        <FormGroup>
                                            <LabelText displayText="Recharge Amount"/>
                                            <div className="input-group">
                                                <span className="input-group-text">{chargedResource[0]?.currencySymbol}</span>
                                                <Input
                                                    type="number"
                                                    value={adjustmentAmount}
                                                    onChange={(e) => setAdjustmentAmount(e.target.value)}
                                                    placeholder="Enter the Recharge Amount"
                                                />
                                            </div>
                                        </FormGroup>
                                        <div className="d-flex justify-content-center">
                                        <CommonButton
                                            color={"secondary"}
                                            type={"close"}
                                            buttonText={"Close"}
                                            className="btn btn-outline-secondary px-4 py-2 me-2 rounded-lg"
                                            buttonAction={() => {
                                                setModalOpen(false);
                                            }}
                                        />
                                        <CommonButton
                                            color={"secondary"}
                                            type="submit"
                                            buttonText={"Submit"}
                                            className="btn btn-secondary px-4 py-2 ms-2 rounded-lg"
                                            buttonAction={handleBalanceConfirm}
                                        />
                                    </div>
                                    </>
                                    ) : modalType === "Adjust" ? (
                                        <>
                                            <FormGroup>
                                                <LabelText displayText="Description"/>
                                                <Input
                                                    type="text"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    placeholder="Enter description"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <LabelText displayText="Adjustment Amount"/>
                                                <div className="input-group">
                                                    <span className="input-group-text">{chargedResource[0]?.currencySymbol}</span>
                                                    <Input
                                                        type="number"
                                                        value={adjustmentAmount}
                                                        onChange={(e) => setAdjustmentAmount(e.target.value)}
                                                        placeholder="Enter adjustment amount"
                                                    />
                                                </div>
                                            </FormGroup>
                                            <CommonButton
                                            color={"secondary"}
                                            type={"close"}
                                            buttonText={"Close"}
                                            className="btn btn-outline-secondary px-4 py-2 me-2 rounded-lg"
                                            buttonAction={() => {
                                                setModalOpen(false);
                                            }}
                                        />
                                        <CommonButton
                                            color={"secondary"}
                                            type="submit"
                                            buttonText={"Submit"}
                                            className="btn btn-secondary px-4 py-2 ms-2 rounded-lg"
                                            buttonAction={handleBalanceConfirm}
                                        />
                                        </>
                                    ) : (
                                        <Formik
                                            initialValues={initialAdjustValues}
                                            validationSchema={validationSchemaForAdjustment}
                                            onSubmit={handleConfirm}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                handleBlur
                                            }) => (
                                                <Form>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <LabelText displayText="Description"/>
                                                                <Input
                                                                    type="text"
                                                                    name="description"
                                                                    value={values.description}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    placeholder="Enter description"
                                                                    invalid={touched.description && !!errors.description}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <LabelText displayText="Adjustment Amount" important={"*"} className={"text-danger"}/>
                                                                <Input
                                                                    type="number"
                                                                    name="adjustmentAmount"
                                                                    value={values.adjustmentAmount}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    placeholder="Enter adjustment amount"
                                                                    invalid={touched.adjustmentAmount && !!errors.adjustmentAmount}
                                                                />
                                                                {(touched.adjustmentAmount &&
                                                                    errors.adjustmentAmount) && (
                                                                        <FormFeedback type="invalid">
                                                                            {errors.adjustmentAmount}
                                                                        </FormFeedback>
                                                                    )}
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <LabelText displayText="Resource Name" important={"*"} className={"text-danger"}/>
                                                                <SelectField
                                                                    name="resourceName"
                                                                    className={
                                                                        touched.resourceName && errors.resourceName ? 'error-input' : ''
                                                                    }
                                                                    value={
                                                                        values.resourceName
                                                                            ? resourceNameArray.find(option => option.value === values.resourceName)
                                                                            : null
                                                                    }
                                                                    handleChange={(selectedOption) => {
                                                                        setFieldValue("resourceName", selectedOption?.value || "");
                                                                    }}
                                                                    options={resourceNameArray}
                                                                />
                                                                {(touched.resourceName &&
                                                                    errors.resourceName) && (
                                                                    
                                                                        <p className="text-danger validation-font-size">
                                                                        {errors.resourceName}
                                                                    </p>
                                                                    )}
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Balance Item Spec ID</Label>
                                                                <SelectField
                                                                    name="balanceItemSpecId"
                                                                    className={
                                                                        touched.balanceItemSpecId && errors.balanceItemSpecId ? 'error-input' : ''
                                                                    }
                                                                    value={
                                                                        values.balanceItemSpecId
                                                                            ? idArray.find(option => option.value === values.balanceItemSpecId)
                                                                            : null
                                                                    }
                                                                    handleChange={(selectedOption) => {
                                                                        setFieldValue("balanceItemSpecId", selectedOption?.value || "");
                                                                    }}
                                                                    options={idArray}
                                                                />
                                                                {touched.balanceItemSpecId && errors.balanceItemSpecId && (
                                                                    
                                                                    <p className="text-danger validation-font-size">
                                                                    {errors.balanceItemSpecId}
                                                                </p>
                                                                )}
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Subscription Id</Label>
                                                                <SelectField
                                                                    name="subscriptionId"
                                                                    className={
                                                                        touched.subscriptionId && errors.subscriptionId ? 'error-input' : ''
                                                                    }
                                                                    value={
                                                                        values.subscriptionId
                                                                            ? subscriptionIdArray.find(option => option.value === values.subscriptionId)
                                                                            : null
                                                                    }
                                                                    handleChange={(selectedOption) => {
                                                                        setFieldValue("subscriptionId", selectedOption?.value || "");
                                                                    }}
                                                                    options={subscriptionIdArray}
                                                                />
                                                                {(touched.subscriptionId &&
                                                                    errors.subscriptionId) && (
                                                                        
                                                                        <p className="text-danger validation-font-size">
                                                                        {errors.subscriptionId}
                                                                    </p>
                                                                    )}
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Offer Code</Label>
                                                                <SelectField
                                                                    name="offerCode"
                                                                    className={
                                                                        touched.offerCode && errors.offerCode ? 'error-input' : ''
                                                                    }
                                                                    value={
                                                                        values.offerCode
                                                                            ? offerCodeArray.find(option => option.value === values.offerCode)
                                                                            : null
                                                                    }
                                                                    handleChange={(selectedOption) => {
                                                                        setFieldValue("offerCode", selectedOption?.value || "");
                                                                    }}
                                                                    options={offerCodeArray}
                                                                />
                                                                {(touched.offerCode &&
                                                                    errors.offerCode) && (
                                                                        <p className="text-danger validation-font-size">
                                                                        {errors.offerCode}
                                                                    </p>
                                                                    )}
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6}></Col>

                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <LabelText displayText="Valid From"/>
                                                                <CommonDateTimePicker
                                                                    views={["year", "month", "day", "hours", "minutes", "seconds"]}
                                                                    className={`form-control`}
                                                                    value={values.validFrom}
                                                                    name="validFrom"
                                                                    format="DD/MM/YYYY hh:mm:ss A"
                                                                    handleChange={(value) => setFieldValue("validFrom", value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <LabelText displayText="Valid To"/>
                                                                <CommonDateTimePicker
                                                                    views={["year", "month", "day", "hours", "minutes", "seconds"]}
                                                                    className={`form-control`}
                                                                    value={values.validTo}
                                                                    name="validTo"
                                                                    format="DD/MM/YYYY hh:mm:ss A"
                                                                    handleChange={(value) => setFieldValue("validTo", value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <div className="d-flex justify-content-center">
                                                        <CommonButton
                                                            color={"secondary"}
                                                            type={"close"}
                                                            buttonText={"Close"}
                                                            className="btn btn-outline-secondary px-4 py-2 me-2 rounded-lg"
                                                            buttonAction={() => {
                                                                setModalOpen(false);
                                                            }}
                                                        />
                                                        <CommonButton
                                                            color={"secondary"}
                                                            type="submit"
                                                            buttonText={"Submit"}
                                                            className="btn btn-secondary px-4 py-2 ms-2 rounded-lg"
                                                        />
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </ModalBody>
                            </Modal>

                            <Nav className="nav-tabs-custom rounded card-header-tabs border-top border-bottom mt-2  mb-3" role="tablist">
                                <div className="ms-3 d-flex justify-content-between">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTabForAddOn === "1" })}
                                            onClick={() => tabChange("1")}
                                        >
                                            Base Offer
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTabForAddOn === "2" })}
                                            onClick={() => tabChange("2")}
                                        >
                                            Add-On Offer
                                        </NavLink>
                                    </NavItem></div>
                            </Nav>
                            {activeTabForAddOn === "2" && addOnLength <= 0 && (
                                <CardBody>
                                    <p className="d-flex justify-content-center fw-bold fs-5 balance-heading-color">
                                        No Add-Ons purchased for this subscription
                                    </p>
                                </CardBody>
                            )}
                            {selectedSubscriptionId &&
                                Object.keys(groupedResources)?.filter(key => key.startsWith(selectedSubscriptionId)).map((key, index) => {
                                    const resources = groupedResources[key];
                                    const filteredResources = resources?.filter(resource =>
                                        (activeTabForAddOn === "1" && !resource.isAddOn) ||
                                        (activeTabForAddOn === "2" && resource.isAddOn)
                                    );
                                    return (
                                        <CardBody key={index} className="card-body">
                                            {filteredResources.length > 0 ? (
                                                <Row className="gy-4">
                                                    {!filteredResources.some(resource => resource.isCounter) ? (
                                                        // Show both columns if no resource has isCounter === true
                                                        <>
                                                            <Row>
                                                                <Col lg={12} className="d-flex justify-content-end">
                                                                    <CommonButton
                                                                        color="secondary"
                                                                        className="px-3 mx-1 py-0 my-0"
                                                                        buttonAction={() => {
                                                                            setModalType("Adjustment");
                                                                            setModalOpen(true);
                                                                        }}
                                                                        buttonText={
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <i className="ri-creative-commons-nc-line me-2 fs-2"></i>
                                                                                Adjust
                                                                            </div>
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Col lg={6}>
                                                                {filteredResources.length > 1 ? renderGrantsCarousel(filteredResources) : (
                                                                    <Card className="border border-2 customer-view-screen">
                                                                        <CardHeader className="d-flex align-items-center customer-view-screen">
                                                                            <Label className="fs-5 mt-2">Grant</Label>
                                                                        </CardHeader>
                                                                        <CardBody className="px-5">
                                                                            <div className="d-flex flex-column align-items-center flex-md-row">
                                                                                <PieChart
                                                                                    remainingBalance={filteredResources[0]?.remainingBalance || filteredResources[0]?.originalBalance}
                                                                                    originalBalance={filteredResources[0].originalBalance}
                                                                                    width={"100%"}
                                                                                    height={200}
                                                                                />
                                                                                <div>
                                                                                    <p className="fw-bolder fs-5">{filteredResources[0].resourceName}</p>
                                                                                    <p>
                                                                                        <b>{filteredResources[0].remainingBalance || filteredResources[0].originalBalance}</b> left of <b>{filteredResources[0].originalBalance}</b>
                                                                                    </p>
                                                                                    <p className="d-flex justify-content-start">
                                                                                        <LabelText displayText={`${formatDate(filteredResources[0].validFrom)} - ${formatDate(filteredResources[0].validTo)}`} />
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                )}
                                                            </Col>
                                                            <Col lg={6}>

                                                                {filteredResources && (
                                                                    <Card className="border border-2" style={{ height: "262px" }}>
                                                                        <CardHeader className="d-flex align-items-center">
                                                                            <h5 className="fs-5 mb-0">Usage</h5>
                                                                        </CardHeader>
                                                                        <div className="table-responsive">
                                                                            <Table className="table table-borderless table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="p-3" scope="col">Name</th>
                                                                                        <th className="p-3" scope="col">Value</th>
                                                                                        <th className="p-3" scope="col">Units</th>
                                                                                        <th className="p-3" scope="col">Time Period</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {resources?.map((resource, idx) => (
                                                                                        <tr key={idx}>
                                                                                            <td className="p-3">{resource?.resourceName}</td>
                                                                                            <td className="p-3">{(resource?.consumedBalance)}</td>
                                                                                            <td className="p-3">{resource?.uom}</td>
                                                                                            <td>{`${formatDate(resource?.validFrom)} - ${formatDate(resource?.validTo)}`}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </Card>
                                                                )}
                                                                {roleName === "Customer" && activeTabForAddOn === "1" ? (
                                                                    <div className="d-flex justify-content-end ms-5 me-3">
                                                                        <CommonButton color="secondary" className="px-4 mx-1" buttonAction={() => { navigateToView(`/selfcare-changeplan/${selectedSubscriptionId}`) }} buttonText={"Change Plan"} />
                                                                        <CommonButton color="secondary" className="px-4 mx-1" buttonAction={() => { navigateToView(`/selfcare-addon/${selectedSubscriptionId}`) }} buttonText={"Purchase Add-On"} />
                                                                    </div>
                                                                ) : null}
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        // Show only the second column if any resource has isCounter === true
                                                        <Col lg={6}>
                                                            {filteredResources && (
                                                                <Card className="border border-2" style={{ height: "262px" }}>

                                                                    <CardHeader className="d-flex align-items-center">
                                                                        <h5 className="fs-5 mb-0">Usage</h5>
                                                                    </CardHeader>
                                                                    <div className="table-responsive">
                                                                        <Table className="table table-borderless table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="p-3" scope="col">Name</th>
                                                                                    <th className="p-3" scope="col">Value</th>
                                                                                    <th className="p-3" scope="col">Units</th>
                                                                                    <th className="p-3" scope="col">Time Period</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {resources?.map((resource, idx) => (
                                                                                    <tr key={idx}>
                                                                                        <td className="p-3">{resource?.resourceName}</td>
                                                                                        <td className="p-3">{(resource?.consumedBalance)}</td>
                                                                                        <td className="p-3">{resource?.uom}</td>
                                                                                        <td>{`${formatDate(resource?.validFrom)} - ${formatDate(resource?.validTo)}`}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Card>
                                                            )}
                                                            {roleName === "Customer" && activeTabForAddOn === "1" ? (
                                                                <div className="d-flex justify-content-end ms-5 me-3">
                                                                    <CommonButton color="secondary" className="px-4 mx-1" buttonAction={() => { navigateToView(`/selfcare-changeplan/${selectedSubscriptionId}`) }} buttonText={"Change Plan"} />
                                                                    <CommonButton color="secondary" className="px-4 mx-1" buttonAction={() => { navigateToView(`/selfcare-addon/${selectedSubscriptionId}`) }} buttonText={"Purchase Add-On"} />
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                    )}
                                                </Row>
                                            ) : null}
                                        </CardBody>
                                    );
                                })
                            }
                        </>
                    ) :
                        <div className="d-flex justify-content-center align-items-center text-muted p-5 fst-italic">
                            No active subscriptions yet
                        </div>
                    }
                </Card>
            </Col >
        </Row >

    )
};

export default CustomerProfile;