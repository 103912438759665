import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const SliderForEmail = ({ isOpen, setOpen, actionName, handleSave, children, handleReset }) => {
    // State to control the reset confirmation modal
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);

    // Function to open reset confirmation modal
    const openResetModal = () => {
        setIsResetModalOpen(true);
    };

    // Function to close reset confirmation modal
    const closeResetModal = () => {
        setIsResetModalOpen(false);
    };

    // Function to handle reset confirmation
    const confirmReset = () => {
        handleReset(); // Call the original reset handler
        closeResetModal(); // Close the modal
    };

    return (
        <>
            {/* Existing Slider Content */}
            {isOpen && (
                <div
                    className="slider-backdrop w-100 h-100"
                    onClick={() => setOpen({ open: false })}
                ></div>
            )}

            <div
                className={`slider-content bg-white border border-gray ${
                    isOpen ? "translate-x-0" : "translate-x-100"
                }`}
            >
                <div className="d-flex justify-content-between align-items-center p-3">
                    <p className="text-uppercase fw-bold fs-5 m-0">{actionName}</p>
                    <div className="d-flex justify-content-center">
                        <div
                            onClick={openResetModal} // Changed to open modal
                            className="cursor-pointer"
                        >
                            <i className="ri-arrow-go-back-line fs-4 p-2"></i>
                        </div>
                        <div
                            onClick={() => setOpen({ open: false })}
                            className="cursor-pointer"
                        >
                            <i className="ri-close-line fs-4 p-2"></i>
                        </div>
                    </div>
                </div>

                <div
                    className="px-3 pt-1 py-5 flex-grow-1 overflow-auto"
                >
                    {children}
                </div>

                <div
                    className="p-3 d-flex justify-content-between"
                >
                    <Button className='btn-outline-main-color' onClick={() => setOpen({ open: false })}>
                        Back
                    </Button>
                    <Button color={"main-color"} onClick={()=>handleSave()}>
                        Save
                    </Button>
                </div>
                <Modal 
                    isOpen={isResetModalOpen} 
                    toggle={closeResetModal}
                    centered
                    className="custom-reset-modal"
                    contentClassName="rounded-lg"
                >
                    <ModalBody className="text-center p-5">
                        <div className="position-absolute top-0 end-0 p-3 cursor-pointer" onClick={closeResetModal}>
                            <i className="ri-close-line fs-4"></i>
                        </div>
                        <div className="mb-5">
                            <p className="fw-semibold fs-5">
                                Are you sure you want to reset to the default template?
                            </p>
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                            <button 
                                className="btn btn-outline-secondary px-4 py-2 rounded-lg"
                                onClick={closeResetModal}
                            >
                                Cancel
                            </button>
                            <button 
                                className="btn btn-primary px-4 py-2 rounded-lg"
                                onClick={confirmReset}
                            >
                                Confirm
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

