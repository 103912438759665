export const attributeOptions = [
    {
        name: "valueType",
        value: "Attribute",
        options: [
            { value: "integer", label: 'Integer' },
            { value: "float64", label: 'Decimal' },
            { value: "string", label: 'Text' },
            { value: "bool", label: 'Boolean' },
            { value: "list", label: 'List' },
        ]
    },
    {
        name: "type",
        value: "Attribute",
        options: [
            { value: "provisioning", label: 'Provisioning' },
            { value: "general", label: "General" },
            { value: "usage", label: 'Usage' },
            { value: "customer", label: 'Customer' },
            { value: "partner", label: 'Partner' },
        ]
    },
    {
        name: "valueFormat",
        value: "Attribute",
        options: [
            { value: "sequence", label: 'Sequence' },
            { value: "default", label: "Default" },
        ]
    },
    {
        name:"attributeValue",
        value:"Attribute",
        options:[
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
        ]
    }
]