import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { fetchOperatorbyId } from "../../slices/OperatorManagement/GetOperatorbyID/thunk";
import { logoViewDoc } from "../../slices/Logo/thunk";
import { documentUpdateFile } from "../../slices/customer/documentupdatefile/thunk";
import { partnerUploadDocument } from "../../slices/PartnerMangement/PartnerDocumentUpload/thunk";
import CreateOperatorProvider from "./createOperatorProvider";
import logoLarge from '../../assets/images/users/user-dummy-img.jpg';
import { countryOptionss, OperatorOptionsFields, OperatorStatusOptions } from '../OperatorManagement/Constant/Options';

export default function OperatorProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [update, setUpdate] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [profileImage, setProfileImage] = useState(logoLarge);
    const [fileSelected, setFileSelected] = useState(false);

    const roleName = localStorage.getItem('roleName');
    const userName = localStorage.getItem('userName');
    const operatorId = localStorage.getItem('operatorId');
    const userId = localStorage.getItem('userId');

    const initialValues = {
        businessName: "",
        Domain: "",
        state: "",
        city: "",
        zipCode: "",
        endDate: null,
        Address: "",
        Address2: "",
        country: "",
        firstname: "",
        lastname: "",
        username: "",
        phone: "",
        email: "",
        Status: "",
    };

    const operatorOptions = [
        { name: "Domain", value: "Operator", options: OperatorOptionsFields },
        { name: "country", value: "Operator", options: countryOptionss },
        { name: "Status", value: "Operator", options: OperatorStatusOptions }
    ];

    // Fetch operator details when isEdit is true
    useEffect(() => {
        if (isEdit && operatorId) {
            dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions, OperatorStatusOptions));
        }
    }, [dispatch, isEdit, operatorId]);

    // Fetch logo document based on role
    useEffect(() => {
        const partnerOrCustomer = 'Business';
        if (roleName === 'systemUser' && userId) {
            dispatch(logoViewDoc(setLoading, userId, "logoSys", partnerOrCustomer, setProfileData));
        } else if (operatorId) {
            dispatch(logoViewDoc(setLoading, operatorId, "logo", partnerOrCustomer, setProfileData));
        }
    }, [dispatch, roleName, operatorId, userId]);

    const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId ?? null);
    const ViewDocument = useSelector((state) => state.LogoView.logoView) || [];
    const base64ImageData = ViewDocument?.filePath || null;

    // Set profile image when document data changes
    useEffect(() => {
        if (base64ImageData) {
            setProfileImage(base64ImageData);
        }
    }, [base64ImageData]);

    // Function to create form data for upload
    const createDocumentJson = useCallback((file, isUpdate) => {
        const formData = new FormData();
        const id = roleName === 'systemUser' ? userId : operatorId;
        if (!id) {
            console.error('User/Operator ID is missing');
            return;
        }
        formData.append("ID", id);
        formData.append("file", file);
        formData.append("documentName", roleName === 'systemUser' ? "logoSys" : "logo");
        formData.append("documentType", roleName === 'systemUser' ? "logoSys" : "logo");
        formData.append("userType","operator")
        formData.append("uploadedBy", roleName === 'systemUser' ? userName : "Operator");

        if (isUpdate) {
            formData.append("newDocumentName", formData.get("documentName"));
        }
        return formData;
    }, [roleName, userId, operatorId]);

    // Upload or update document
    const uploadDocApiCall = useCallback((file) => {
        const partnerOrCustomer = "Business";
        const id = roleName === 'systemUser' ? userId : operatorId;

        if (!id) {
            console.error("User/Operator details missing");
            return;
        }

        if (base64ImageData) {
            const updateObj = createDocumentJson(file, true);
            dispatch(documentUpdateFile(setLoading, updateObj, id, null, partnerOrCustomer, setUpdate, setFileSelected, navigate));
        } else {
            const documentObj = createDocumentJson(file, false);
            dispatch(partnerUploadDocument(documentObj, setLoading, id, null, partnerOrCustomer, setFileSelected, navigate));
        }
    }, [dispatch, roleName, userId, operatorId, base64ImageData, createDocumentJson]);

    // Handle Image Upload
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileSelected(true);
            const reader = new FileReader();
            reader.onload = (e) => setProfileImage(e.target.result);
            reader.readAsDataURL(file);
            uploadDocApiCall(file);
        }
    };
    const toggleEdit = () => {
        setIsEdit(prevState => !prevState);
    };

    return (
        <React.Fragment>

            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={"Business Profile"} />
                    <Row>
                        <Col  lg={roleName !== 'systemUser' ? 3 : 12}>
                            <Card className="">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                                            <img
                                                src={profileImage}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                    onChange={handleImageUpload}
                                                    accept="image/*"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs"
                                                >
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <h5 className="fs-16 mb-1">{operatorData?.adminDetails?.profile?.firstName} {operatorData?.adminDetails?.profile?.lastName}</h5>
                                    </div>
                                </CardBody>
                            </Card>
                            {roleName !== 'systemUser' ?
                                <Card >
                                    <CardBody className="mb-2">
                                        <div className="d-flex align-items-center mb-5">
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-0">Complete Your Profile</h5>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <Link to="#" className="badge bg-light text-secondary fs-12" onClick={toggleEdit}>
                                                    <i className="ri-edit-box-line align-bottom me-1"></i>
                                                    {isEdit ? "Cancel" : "Edit"}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="progress animated-progress custom-progress progress-label">
                                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: base64ImageData ? '100%' : '80%' }}
                                                aria-valuenow={base64ImageData ? '100' : '80'} aria-valuemin="0" aria-valuemax="100">
                                                <div className="label">{base64ImageData ? '100%' : '80%'}</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card> : null}
                        </Col>
                        {roleName !== 'systemUser' ?
                            <Col lg={9}>
                                <div >
                                    <CreateOperatorProvider isEdit={isEdit} initialValues={initialValues} />
                                </div>
                            </Col> : null}
                    </Row>
                </Container>
                {loading && <Loader />}
            </div>

        </React.Fragment>
    );
}