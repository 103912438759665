import { useMemo } from "react";
import { Button, Input } from "reactstrap";
import { Box } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import InputField from "../../Utils/InputField";
import SelectField from "../../Utils/SelectField";
import { DocumentUploadOptions } from "./constantValues";
import { approveDocument, editDocument, formatNumber, rejectDocument } from "./HandleChangeFunctions";
import moment from "moment";
import { approveButtonFunction, callEditDocument, dontCallEditDocument, handleEditedDocumentName, handleEditedDocumentType, rejectButtonFunction } from "./DocumentTableFunctions";
import { getCellStyle } from "../../Utils/DecideColor";
import { getStatusLabel } from "../../Utils/DecideStatus";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";

export const customerColumns = (selectAllCustomers, setSelectAllCustomers, customers, setSelectedCustomer, selectedCustomer, checkboxRef, isMobile,operatorConfiguration) => {
        const isEIPEnabled = operatorConfiguration?.configurations
            ?.find(config => config.name === "EIP")
            ?.value?.find(item => item.key === "Enabled")
            ?.value?.[0] === "True";
            
    const headerText = isEIPEnabled ? "Device ID" : "A/C Number";
    const headerTextName = isEIPEnabled ? "Device Name" : "Full Name";
    const headerTextAddress = isEIPEnabled ? "Device Location" : "Address";

    const columns = [
        {
            accessorKey: 'checkbox',
            header: 'checkbox',
            Header: ({ row }) => (
                <Input type="checkbox" ref={checkboxRef} className=' border-black checkbox-size rounded-1' checked={selectAllCustomers} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
                    setSelectAllCustomers(!selectAllCustomers)
                    if (!selectAllCustomers) {
                        setSelectedCustomer(customers)
                    }
                    else {
                        setSelectedCustomer([])
                    }
                }} />
            ),
            enableColumnActions: false,
            enableColumnDragging: false,
            showColumnFilters: false,
            enableColumnFilter: false,
            Cell: ({ row }) => (
                <Input type="checkbox" className=' border-black checkbox-size  rounded-1' checked={selectedCustomer.some(p => p.CustomerId === row.original.CustomerId)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
                    handleCheckboxChange(row, selectedCustomer, setSelectedCustomer)
                }
                } />
            ),
            size: isMobile ? 50 : 34
        },
        {
            accessorKey: "CustomerId",
            header: headerText,
            muiFilterTextFieldProps : ({ column }) => ({
                placeholder : ' ', 
                InputProps  : {
                  startAdornment: (
                    <SearchIcon />
                  ),
                  endAdornment: (
                    <CloseIcon onClick={() => (column.setFilterValue(''))}/>
                  ),
                },
              }),
            size: 150,
        },
        {
            accessorFn: (row) => `${row.Name}`,
            id: "FullName",
            header: headerTextName,
            muiFilterTextFieldProps : ({ column }) => ({
                placeholder : ' ', 
                InputProps  : {
                  startAdornment: (
                    <SearchIcon />
                  ),
                  endAdornment: (
                    <CloseIcon onClick={() => (column.setFilterValue(''))}/>
                  ),
                },
              }),
            size: 150,
        },
        {
            accessorKey: "Email",
            header: "Email",
            muiFilterTextFieldProps : ({ column }) => ({
                placeholder : ' ', 
                InputProps  : {
                  startAdornment: (
                    <SearchIcon />
                  ),
                  endAdornment: (
                    <CloseIcon onClick={() => (column.setFilterValue(''))}/>
                  ),
                },
              }),
            size: 200,
        },
        {
            accessorKey: "Mobile",
            header: "Phone",
            muiFilterTextFieldProps : ({ column }) => ({
                placeholder : ' ', 
                InputProps  : {
                  startAdornment: (
                    <SearchIcon />
                  ),
                  endAdornment: (
                    <CloseIcon onClick={() => (column.setFilterValue(''))}/>
                  ),
                },
              }),
            size: 150,
        },
        {
            accessorKey: "Address",
            header: headerTextAddress,
            muiFilterTextFieldProps : ({ column }) => ({
                placeholder : ' ', 
                InputProps  : {
                  startAdornment: (
                    <SearchIcon />
                  ),
                  endAdornment: (
                    <CloseIcon onClick={() => (column.setFilterValue(''))}/>
                  ),
                },
              }),
            size: 150,
        },
        {
            accessorKey:"Tags",
            header:"Tags",
            muiFilterTextFieldProps : ({ column }) => ({
                placeholder : ' ', 
                InputProps  : {
                  startAdornment: (
                    <SearchIcon />
                  ),
                  endAdornment: (
                    <CloseIcon onClick={() => (column.setFilterValue(''))}/>
                  ),
                },
              }),
            Cell: ({ cell }) => {
                const value = cell?.getValue();
                return <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "Tags"),
                } : {})} >{value?.[0] ?? "-"}</Box>
            },
            size:150
        }
    ];
    return columns;
};

export const reservationColumns = (date, startDuration, endDuration, hours, stationName, stationAddress) => {
    const columns = [
        {
            accessorFn: (reservation, index) => `${date[index]}`,
            header: 'Date',
            size: 150,
        },
        {
            accessorFn: (reservation, index) => `${startDuration[index]} - ${endDuration[index]}`,
            header: 'Duration',
            size: 150,
        },
        {
            accessorFn: (reservation, index) => `${hours[index]}`,
            header: 'Hours',
            size: 150,
        },
        {
            accessorFn: (stations, index) => `${stationName[index]}`,
            header: "Station Name",
            size: 150
        },
        {
            accessorFn: (stations, index) => `${stationAddress[index]}`,
            header: "Station Address",
            size: 200
        }
    ]
    return columns
}
export const subscriptionColumns = (termValidity, validFrom, validTo, status, addOnOffer) => {
    const columns = [
        {
            accessorKey: `offerName`,
            header: 'Offer Name',
            size: 150,
        },
        {
            accessorFn: (offer, i) => `${termValidity[i]}`,
            accessorKey:'Term Validity',
            header: 'Term Validity',
            size: 150,
        },
        {
            accessorFn: (offer, i) => `${validFrom[i]}`,
            accessorKey:'Offer Starts',
            header: 'Offer Starts',
            size: 150,
        },
        {
            accessorFn: (offer, i) => `${validTo[i]}`,
            accessorKey: 'Offer Ends',
            header: 'Offer Ends',
            size: 150,
        },
        {
            accessorFn: (offer, i) => `${status[i]}`,
            accessorKey: 'Status',
            header: 'Status',
            size: 150,
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "Station"),
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        {
            accessorFn: (offer, i) => `${addOnOffer[i]}`,
            accessorKey: 'Offer Type',
            header: 'Offer Type',
            size: 150,
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(), "Subscriptions"),
                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
    ]
    return columns
}

export const documentTableColumns = (documents, open, setIsOpen, setButtonText, setMessageForPopUp, fields, setFields, handleChange, editingRow, editedDocumentName, setEditedDocumentName, editedDocumentType, setEditedDocumentType, customerId, approvePayload, rejectPayload, dispatch, setLoading) => {
    const allApproved = documents.every(doc => doc.status === 'Approved');
    const conditionCheck = !allApproved;
    const roleName = localStorage.getItem('roleName')
    const columns = [
        {
            accessorKey: 'documentName',
            header: 'Document Name',
            Cell: ({ cell, row }) => {
                return (
                    (fields && editingRow === row.index) ?
                        <InputField type={'text'} className={'form-control'} name={`documents_${row.index}`} handleChange={(e) => setEditedDocumentName(e.target.value)} value={editedDocumentName} />
                        : cell.getValue()
                )
            }
        },
        {
            accessorKey: 'fileName',
            header: 'File Name'
        },
        {
            accessorKey: 'documentType',
            header: 'Document Type',
            Cell: ({ cell, row }) => {
                const docType = { value: row.original.documentType, label: row.original.documentType }
                return (
                    (fields && editingRow === row.index) ?
                        <div className="d-flex justify-content-center align-items-center  ">
                            <SelectField menuPortalTarget={document.querySelector('body')} className={"me-2"} value={editedDocumentType} handleChange={(selectedOption) => setEditedDocumentType(selectedOption)} options={DocumentUploadOptions} />
                            <i className="ri-check-line me-2 fs-4" onClick={() => { editDocument(customerId, row.original.documentName, editedDocumentName, editedDocumentType, dispatch, setLoading), setFields(!fields) }} /><i className="ri-close-fill fs-4" onClick={() => setFields(!fields)} />
                        </div>
                        : cell.getValue()
                )
            },
            size: fields ? 225 : 150
        },
        {
            accessorKey: 'uploadedtime',
            header: 'Uploaded Date & Time',
            size: 200,
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format('MM/DD/YYYY hh:mm A')}
                </Box>
            )
        },
        // {
        //     accessorKey: 'uploadedBy',
        //     header: 'Uploaded By'
        // },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(), "Document"),
                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        ...(conditionCheck && !roleName === "Customer" ? [
            {
                header: '',
                id: 'statusButton',
                Cell: ({ cell, row }) => (
                    row.original.status === 'Pending' && (
                        <div>
                            <Button onClick={() => approveButtonFunction(setIsOpen, setButtonText, setMessageForPopUp, customerId, row.original.documentName, approvePayload)} type="button" className="p-1 me-2 approve-or-reject" color='main-color'>
                                Approve
                            </Button>
                            <Button onClick={() => rejectButtonFunction(setIsOpen, setButtonText, setMessageForPopUp, customerId, row.original.documentName, rejectPayload)} type="button" className="p-1 approve-or-reject cancel-button-background" outline={true} color="main-color">
                                Reject
                            </Button>
                        </div>
                    )
                )
            }
        ] : []),
    ]
    const filteredColumns = columns.filter(column => documents.every(doc => doc.status !== 'pending'));
    return filteredColumns;
}


export const invoiceListColumns = () => {
    const columns = [
        {
            accessorKey: 'id',
            header: 'Invoice ID',
            Cell: ({ cell }) => (
                <Box sx={{ color: "#3478F1" }}>{cell.getValue()}</Box>
            ),
        },
        {
            accessorKey: 'customer',
            header: 'Customer',
            Cell: ({ cell }) => (
                <Box>
                    {cell.getValue() === "" ? "-" : cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'email',
            header: 'Email',
            Cell: ({ cell }) => (
                <Box>
                    {cell.getValue() === "" ? "-" : cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'date',
            header: 'Date',
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            Cell: ({ cell }) => (
                <Box>
                    {formatNumber(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: 'balance',
            header: 'Balance Due',
            Cell: ({ cell }) => (
                <Box>
                    {formatNumber(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(), "InvoiceForAll"),
                    } : {})}
                >
                    {getStatusLabel(cell.getValue())}
                </Box>
            ),
        },
    ]
    return columns
}

export const invoiceColumns = () => {
    const columns = [
        {
            accessorKey: 'id',
            header: 'Invoice ID',
            size: 100,
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        color: '#3478F1',

                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'date',
            header: 'Created Date',
            size: 100,
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            Cell: ({ cell }) => (
                <Box>
                    {formatNumber(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: 'balance',
            header: 'BalanceDue',
            Cell: ({ cell }) => (
                <Box>
                    {formatNumber(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: 'notifiedDate',
            header: 'Notified Date'
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "InvoiceForCustomer")
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        {
            accessorKey: ' ',
            header: '',
            Cell: ({ row }) => (
                <Checkbox className="p-0"
                    checked={row.isSelected}
                />
            ),
        },
    ]
    return columns
}

export const BillsColumns = (docTableMockData) => {
    const columns = [
        {
            accessorKey: 'billId',
            header: 'Bill No',
            size: 100,
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        color: '#3478F1',

                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: 'startTime',
            header: 'Start Date',
            size: 100,
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format("DD-MMM-YYYY")}
                </Box>
            )
        },
        {
            accessorKey: 'endTime',
            header: 'End Date',
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format("DD-MMM-YYYY")}
                </Box>
            )
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "BillsForCustomer"),
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        {
            accessorKey: 'dueAmount',
            header: 'Balance Due',
            Cell: ({ cell }) => (
                <Box>
                    {`${docTableMockData[cell.row.index].currencyCode} ${cell.getValue()}`}
                </Box>
            )
        },
        {
            accessorKey: 'totalAmount',
            header: 'Amount',
            Cell: ({ cell }) => (
                <Box>
                    {`${docTableMockData[cell.row.index].currencyCode} ${cell.getValue()}`}
                </Box>
            )
        },
    ]
    return columns
}

export const customerSearchColumns = () => {
    const operatorConfiguration = useSelector((state)=>state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)
    
    const isEIPEnabled = operatorConfiguration?.configurations
      ?.find(config => config.name === "EIP")
      ?.value?.find(item => item.key === "Enabled")
      ?.value?.[0] === "True";

    const headerText = isEIPEnabled ? "Device ID" : "A/C Number";
    const headerTextName = isEIPEnabled ? "Device Name" : "Full Name";
    const headerTextAddress = isEIPEnabled ? "Device Location" : "Address";

    const columns = [
        {
            accessorKey: "CustomerId",
            header: headerText,
        },
        {
            accessorKey: "Name",
            header: headerTextName,
        },
        {
            accessorKey: "Email",
            header: "Email",
        },
        ...(isEIPEnabled === true ? [] : [
            {
                accessorKey: "msisdn",
                header: "MSISDN",
            },
            {
                accessorKey: "imsi",
                header: "IMSI",
            },
        ]),
        ...(isEIPEnabled === true ? [
            {
                accessorKey: "Tags",
                header: "Tags",
                muiFilterTextFieldProps: ({ column }) => ({
                    placeholder: ' ',
                    InputProps: {
                        startAdornment: (
                            <SearchIcon />
                        ),
                        endAdornment: (
                            <CloseIcon onClick={() => (column.setFilterValue(''))} />
                        ),
                    },
                }),
                Cell: ({ cell }) => {
                    const value = cell?.getValue();
                    return value?.length > 0 ? (
                        <Box sx={(theme) => ({
                            ...getCellStyle(cell.getValue(), "Tags"),
                            whiteSpace: 'nowrap',         
                            overflow: 'hidden',          
                            textOverflow: 'ellipsis',    
                            maxWidth: '100%',  
                            fontSize: value[0]?.length > 15 ? '0.68rem' : '0.80rem',
                        })}>                    
                            {value[0]}
                        </Box>
                    ) : null;
                },
                size: 150
            },
                {
                    accessorKey: "SiteType",
                    header: "Site Type",
                },
                {
                    accessorKey: "PowerSavingMode",
                    header: "Power Saving Mode",
                    Cell: ({ cell }) => (
                        <Box sx={(theme) => (cell.getValue() ? {
                            ...getCellStyle(cell.getValue(), "PowerSavingMode"),
                        } : {})}>
                            {getStatusLabel(cell.getValue())}
                        </Box>
                    ),
                }
        ] : []),
        {
            accessorKey: "Address",
            header: headerTextAddress,
            size: 250
        },
    ];
    //     {
    //         accessorKey: "CustomerId",
    //         header: headerText,
    //     },
    //     {
    //         accessorKey: "Name",
    //         header: headerTextName,
    //     },
    //     {
    //         accessorKey: "Email",
    //         header: "Email",
    //     },
    //     ...(operatorId === "2uGRHVTJkFEvCyuOtyNXUuhsXWs" ? [
    //         {
    //             accessorKey: "msisdn",
    //             header: "MSISDN",
    //         },
    //         {
    //             accessorKey: "imsi",
    //             header: "IMSI",
    //         },
    //     ] : []),
    //     {
    //         accessorKey: "uid",
    //         header: "UID",
    //     },
    //     {
    //         accessorKey: "mid",
    //         header: "MID",
    //     },
    //     {
    //         accessorKey: "Address",
    //         header: headerTextAddress,
    //         size: 250
    //     },
    // ];
    return columns;
};

export const handleCheckboxChange = (row, selectedCustomer, setSelectedCustomer) => {
    let isChecked = selectedCustomer.some(p => p.CustomerId === row.original.CustomerId);
    let updatedSelectedCustomer = isChecked
        ? selectedCustomer.filter(p => p.CustomerId !== row.original.CustomerId)
        : [...selectedCustomer, row.original];
    setSelectedCustomer(updatedSelectedCustomer);
};