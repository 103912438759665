import { QUOTA_MANAGEMENT_LIST } from "../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { fetchQuotaFailure, fetchQuotaSuccess } from "./reducer";

export const getAllQuota = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${QUOTA_MANAGEMENT_LIST}`,'GET')
        setLoading(false)
        if (response.error && response.error.code === 'NotFound') {
            dispatch(fetchQuotaSuccess([]));
        }
        else {
            dispatch(fetchQuotaSuccess(response.reverse()))
        }
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchQuotaFailure(error.message))
    }
}