import * as Yup from "yup"

export const validationSchemaForSimulate = Yup.object({
    eventType: Yup.string().required('Kindly enter Event Type'),
    uom: Yup.object().required('Kindly select UOM'),
    usageValue: Yup.number().typeError('It doesn\'t look like a number').positive('A number can\'t start with a minus').integer('It can\'t include a decimal point').required('Kindly enter Usage Value'),
    eventStartTime: Yup.date().required('Kindly Select start date'),
    eventEndTime: Yup.date().required('Kindly Select end date'),
})

export const validationSchemaForCustomer = (customerPermission, state,id) => {
    let validationSchema = {}
    return validationSchema = Yup.object({
      firstName: Yup.string().required("Kindly enter First Name").matches(/^[a-zA-Z\s_ ]*$/, 'Firstname can contain only characters,space and underscore'),
      lastName: Yup.string().required('Kindly enter Last Name').matches(/^[a-zA-Z\s_ ]*$/, 'Lastname can contain only characters,space and underscore'),
        userName: Yup.string().required('Kindly enter User Name'),
        email: Yup.string().email('Kindly enter valid email').required('Kindly enter Email'),
        // mobile: Yup.number().typeError('It doesn\'t look like a mobile number').positive('A phone number can\'t start with a minus').integer('It can\'t include a decimal point').test('len', 'Must be exactly 10 digits', val => val?.toString().length === 10).required('Kindly enter Phone Number'),
        // imsi: customerPermission.autoload ? Yup.number().required('Kindly enter IMSI').test('len', 'Must be exactly 15 digits', val => val?.toString().length === 15) : Yup.number(),
        ...( !id && {accountNumber: customerPermission?.autoload? Yup.string().required('Kindly enter Account Number').matches(/^[0-9a-zA-Z]+$/, 'Can contain only characters and digits').min(4, "Must be more than or equal to 4 characters").max(32, "Must be less than or equal to 32 characters") : Yup.string()}),
        bdom: customerPermission?.autoload ? Yup.object().required('Kindly select Billing date of month') : Yup.object(),
        addressLine1: Yup.string().max(30, 'Must be 30 characters or less').required('Kindly enter Address'),
        country: Yup.object().required("Kindly select Country"),
        city: Yup.object().required('Kindly select City'),
        state: Yup.object().required('Kindly select State'),
        zipCode: Yup.number().typeError('It doesn\'t look like a zip code').positive('A zip code can\'t start with a minus').integer('It can\'t include a decimal point').required('Kindly enter Zip code'),
        documentType: state ? Yup.object().required('Kindly select document type') : Yup.object(),
        documentName: state ? Yup.string().required('Kindly enter the document name') : Yup.string(),
        selectedFile: state ? Yup.mixed().required('Kindly select a file') : Yup.mixed(),
    }, []);
}

export const validationSchemaForTelcoSimulate = Yup.object({
    subscriptionId: Yup.object().required('Kindly select the Subscription'),
    usageEventType: Yup.object().required('Kindly enter Event Type'),
    sessionId: Yup.string().required('Kindly enter Session Id'),
    sessionTime: Yup.date().required('Kindly select Session Time'),
    requestedUnits: Yup.number().typeError('It doesn\'t look like a number').positive('A number can\'t start with a minus').integer('It can\'t include a decimal point').required('Kindly enter Requested Units'),
    uom: Yup.object().required('Kindly select UOM'),
})
export const validationSchemaForUpdateSimulate = (terminateSessions) =>
    Yup.object({
        sessionId: Yup.string().required('Kindly select the Subscription'),
        sequence:!terminateSessions? Yup.string().required('Kindly enter Event Type'):Yup.string(),
        sessionTime: Yup.date().required('Kindly select Session Time'),
        // requestedUnits:!terminateSessions? Yup.number().typeError('It doesn\'t look like a number').positive('A number can\'t start with a minus').integer('It can\'t include a decimal point').required('Kindly enter Requested Units'):Yup.string(),
        //  usedUnits:!terminateSessions?Yup.string(): Yup.number().typeError('It doesn\'t look like a number').min(0, 'The value cannot be negative').integer('It can\'t include a decimal point').required('Kindly enter Used Units'),
    })
export const validationSchemaForPassword = Yup.object({
    newPassword: Yup.string()
      .required('Please enter your new password')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[@$!%*?&#]/, 'Password must contain at least one special character')
      .matches(/^\S*$/, 'Password cannot contain spaces'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Please confirm your password'),
  })

let initialValues = {
    sessionId: '',
    sequence: '',
    sessionTime: null,
    requestedUnits: '',
    usedUnits: '',

};

export const validationSchemaForAdjustment = Yup.object().shape({
  adjustmentAmount: Yup.number()
    .required("Adjustment amount is required"),
  resourceName: Yup.string()
    .required("Resource name is required"),
  description: Yup.string(),
  balanceItemSpecId: Yup.string()
    .test(
      "balance-or-offer-sub-check",
      "BalanceItemSpecId is required",
      function (value) {
        const { offerCode, subscriptionId } = this.parent;
        const hasBalance = !!value?.trim();
        const hasOfferAndSub = !!offerCode?.trim() && !!subscriptionId?.trim();
        return hasBalance || hasOfferAndSub;
      }
    ),

  offerCode: Yup.string()
    .test(
      "offer-sub-or-balance-check",
      "OfferCode is required",
      function (value) {
        const { balanceItemSpecId, subscriptionId } = this.parent;
        const hasBalance = !!balanceItemSpecId?.trim();
        const hasOfferAndSub = !!value?.trim() && !!subscriptionId?.trim();
        return hasBalance || hasOfferAndSub;
      }
    ),

  subscriptionId: Yup.string()
    .test(
      "sub-offer-or-balance-check",
      "SubscriptionId is required",
      function (value) {
        const { balanceItemSpecId, offerCode } = this.parent;
        const hasBalance = !!balanceItemSpecId?.trim();
        const hasOfferAndSub = !!offerCode?.trim() && !!value?.trim();
        return hasBalance || hasOfferAndSub;
      }
    ),
});
