
export const AttributeNameOptions = [
    { value: 'Central', label: 'Central' },
    { value: 'Team', label: 'Team' },
    { value: 'eMAID', label: 'eMAID' },
    { value: 'ISO14443', label: 'ISO14443' },
    { value: 'ISO15693', label: 'ISO15693' },
    { value: 'KeyCode', label: 'KeyCode' },
    { value: 'Local', label: 'Local' },
    { value: 'idTag', label: 'idTag' },
    { value: 'MacAddress', label: 'MacAddress' },
];

export const DocumentUploadOptions = [
    { value : 'Aadhaar', label:'Aadhaar'},
    { value : "Pan Card", label : 'Pan Card'}
]

export const initialValues = {
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    dialCode: "",
    subscriptions: [],
    attributeList: [],
};

export const initialSimulateValues = {
    eventType: '',
    uom: '',
    usageValue: '',
    eventStartTime: null,
    eventEndTime: null
}

export const initialFormValues = {
    customerInfo: {
        customerId: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobile: "",
        addressLine1: "",
        addressLine2: "",
        country: "",
        state: "",
        city: "",
        zipCode: "",
        dialCode: "",
        subscriptions: [],
        attributeList: [],
    }
};

 export const lists = [{
    name: 'Customer ID',
    id: 'CustomerId'
},
{
    name: ' Full Name',
    id: 'Name'
},
{
    name: 'Email ID',
    id: 'Email'
},
{
    name: 'Address',
    id: 'Address'
},
{
    name: 'Phone',
    id: 'Mobile'
}]

export const initialPasswordValues ={
    newPassword: "",
    confirmNewPassword: ""
}