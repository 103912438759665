import { Container, Card, CardHeader, Nav, NavItem, NavLink, CardBody, TabContent, TabPane, Row, Button, Col } from "reactstrap";
import classnames from "classnames";
import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ViewSubscription from "./ViewSubscription";
import ViewReservation from "./ViewReservation";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomer } from "../../slices/customer/fetchcustomer/thunk";
import { fetchCustomerSummary } from "../../slices/customer/simulate/thunk";
import { CustomerProfile } from "./CustomerProfile";
import { customerIdData, subscriptionListData, subscriptionsaArrayData } from "./constants/constantFunctionsView";
import { formatDate } from "./constants/DateFormat";
import { tabChange } from "../Utils/SetDefaultRowData";
import { initialFormValues } from "./constants/constantValues";
import { noCurrencyArrayDataFormat, currencyDataFormat } from "./constants/constantFunctions";
import { Loader } from "../Utils/Loader";
import Invoice from "./Invoice"
import Bills from "./Bills"
import { fetchBillsForCustomer } from "../../slices/customer/getbills/thunk";
import { fetchAllInvoicesForCustomer } from "../../slices/invoices/invoiceforcustomer/thunk";
import { PAGE_TITLES } from "../Common/constants";
import { AttributeTableInView } from "./AttributeTableInView";
import { fetchAllOffers } from "../../slices/offer/thunk";
import { ViewTasks } from "./ViewTasks";
import { DetailedTaskView } from "./DetailedTaskView";
import CommonButton from "../Utils/CommonButton";
import { getAllResources } from "../../slices/resources/thunk";
import { LiveSession } from "./LiveSession";
import ReactApexChart from "react-apexcharts";

export default function CustomerView() {
    const [renderSimulate, setRenderSimulate] = useState(false)
    const isSimulateView = location.pathname.includes('simulate')
    const [activeTab, setActiveTab] = useState(isSimulateView ? "8" : "1");
    const [loading, setLoading] = useState(false)
    const [offer, setOffer] = useState([]);
    const [view, setView] = useState(false)
    const [taskDetails, setTaskDetails] = useState()
    const [showForm, setShowForm] = useState(isSimulateView ? true : false);
    const [buttonInitiateSession, setButtonInitiateSession] = useState(true)
    let initialValues = {
        subscriptionId: '',
        usageEventType: '',
        sessionId: '',
        sessionTime: null,
        requestedUnits: '',
        uom: ''
    }
    const [submitSimulate, setSubmitSimulate] = useState();
    const permissions = useSelector((state) => state.permissions.permissions);
    const billPermission = permissions.find((resource) => resource.resourceName === 'bill');
    const invoicePermission = permissions.find((resource) => resource.resourceName === 'invoice');
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    const subscriptionPermission = permissions.find((resource) => resource.resourceName === 'subscription');
    const taskPermission = permissions.find((resource) => resource.resourceName === 'task');
    const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)
    const billingenabled = operatorConfiguration?.configurations?.find(item => item.name === "BillingManagement")?.value[0]?.value[0] === "True" ? true : false

    let simulateOrPreview = ""
    let noCurrencyArray = []
    let currencyArray = []

    const dispatch = useDispatch()

    const customerId = customerIdData()

    useEffect(() => {
        simulateOrPreview = "View"
        customerPermission && dispatch(fetchCustomer(setLoading, customerId, initialFormValues, customerPermission))
        customerPermission && dispatch(fetchCustomerSummary(customerId, setLoading, simulateOrPreview))
        billingenabled && dispatch(fetchBillsForCustomer(customerId, setLoading))
        billingenabled && dispatch(fetchAllInvoicesForCustomer(setLoading, customerId))
    }, [])

    const customerData = useSelector((state) => state.FetchCustomer.fetchCustomer)
    const fetchCustomerSummaryData = useSelector(state => state.Simulate.simulate)
    const billData = useSelector((state) => state.GetBillsForCustomer.getBills)
    const invoiceData = useSelector((state) => state.InvoiceListForCustomer.invoiceListForCustomer)
    let attributeData = customerData?.customerInfo?.attributeList || [];
    const reservationPermission = permissions.find((resource) => resource.resourceName === 'reservation');

    noCurrencyArray = noCurrencyArrayDataFormat(fetchCustomerSummaryData, noCurrencyArray)
    currencyArray = currencyDataFormat(fetchCustomerSummaryData, currencyArray)

    let subscriptionList = []
    subscriptionList = subscriptionListData(customerData, subscriptionList)
    let subscriptionsArray = []
    subscriptionsArray = subscriptionsaArrayData(subscriptionsArray, subscriptionList)

    useEffect(() => {
        setOffer(subscriptionsArray)
    }, [subscriptionList])

    const operatorId = localStorage.getItem('operatorId');
    
    const isEIPEnabled = operatorConfiguration?.configurations
      ?.find(config => config.name === "EIP")
      ?.value?.find(item => item.key === "Enabled")
      ?.value?.[0] === "True";

    const customerListTitle = isEIPEnabled
        ? PAGE_TITLES.CUSTOMER_LIST_EIP
        : PAGE_TITLES.CUSTOMER_LIST;

    const viewCustomerTitle = isEIPEnabled
        ? PAGE_TITLES.VIEW_CUSTOMER_EIP
        : PAGE_TITLES.VIEW_CUSTOMER;

        const series = [75];
        const deviceStatus = true; // replace with actual status check
    
        const options = {
            chart: {
                height: 350,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '70%',
                        position: 'front',
                    },
                    track: {
                        strokeWidth: '67%',
                        margin: 0,
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#888',
                            fontSize: '17px',
                            formatter: function () {
                                return deviceStatus ? 'Online' : 'Offline';
                            }
                        },
                        value: {
                            formatter: function (val) {
                                return val + '%';
                            },
                            color: '#111',
                            fontSize: '36px',
                            show: true,
                        }
                    }
                }
            },
            fill: {
                type: 'solid',
                colors: [deviceStatus ? '#51d28c' : '#FF0000']
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Device Uptime %'],
        };
    
        const lineseries = [
            {
                name: 'This Device',
                data: [20, 49, 30, 32]
            },
            {
                name: 'Average Power Consumption ( All Devices )',
                data: [30, 42, 37, 29]
            }
        ];
    
        const lineoptions = {
            chart: {
                type: 'line',
                height: 350,
                toolbar: { show: false },
                zoom: {
                    enabled: true
                }
            },
            colors: ['#008FFB', '#FEB019'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
            },
            yaxis: {
                title: {
                    text: 'Power Consumption (kWh)'
                }
            },
            tooltip: {
                shared: true,
                y: {
                    formatter: function (val) {
                        return val + ' kWh';
                    }
                }
            }
        };

    return (
        <div id="csms" className="page-content">
            <Container fluid>
                <BreadCrumb title={!isSimulateView ? viewCustomerTitle : 'Simulate'} pageTitle={customerListTitle} />
                {(isEIPEnabled) && (
                <Row>
                    <Col lg={12}>
                        <Row className="gy-4 mb-4">
                            <Col xxl={4} md={6}>
                                <Card className="h-100">
                                    <CardHeader>Device Health Status</CardHeader>
                                    <CardBody>
                                        <ReactApexChart
                                            dir="ltr"
                                            className="apex-charts"
                                            options={options}
                                            series={series}
                                            type="radialBar"
                                            height={350}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xxl={4} md={6}>
                                <Card className="h-100">
                                    <CardHeader>Monthly Power Consumption</CardHeader>
                                    <CardBody>
                                        <ReactApexChart
                                            dir="ltr"
                                            className="apex-charts"
                                            options={lineoptions}
                                            series={lineseries}
                                            type="line"
                                            height={350}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xxl={4} md={6}>
                                <Card className="h-100">
                                    <CardHeader>Device Energy Cost</CardHeader>
                                    <CardBody>
                                        <h2 className="text-muted text-uppercase fs-13 mt-4">
                                        MONTH TILL DATE  (IN $)
                                            <i className="ms-1 fs-18 align-middle ri-arrow-up-circle-line text-success"></i>
                                        </h2>
                                        <h2 className="mt-4 ff-secondary fw-semibold fs-1 align-items-center justify-content-center d-flex mb-4">
                                            <span className="mt-4" style={{ fontSize: "70px" }}>
                                                $97.66k
                                            </span>
                                        </h2>
                                        <div className="row mt-3 mt-md-0 py-4 px-3">
                                            <div className="col-md-6 mt-4">
                                                <h5 className="text-muted text-uppercase fs-13">
                                                AVERAGE COST/Hr
                                                    <i className="ms-1 fs-18 align-middle ri-arrow-up-circle-line text-success"></i>
                                                </h5>
                                                <div className="d-flex align-items-center mt-4">
                                                    <div className="flex-shrink-0">
                                                        <i className="display-6 text-muted ri-exchange-dollar-line"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h2 className="mb-0"><span className="counter-value">$ 2.44K</span></h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-4">
                                                <h5 className="text-muted text-uppercase fs-13">
                                                PROJECTED COST <i className="fs-18 align-middle ri-arrow-up-circle-line text-success"></i> <br></br>(NEXT MONTH)
                                                   
                                                </h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="display-6 text-muted ri-exchange-dollar-line"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h2 className="mb-0"><span className="counter-value">$489.4k</span></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            )}
                <div>
                    <CardHeader className="mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                {!isSimulateView ? <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "1" })}
                                        onClick={() => {
                                            tabChange("1", activeTab, setActiveTab);
                                        }}
                                    >
                                        <i className="fas fa-home"></i>
                                        Profile
                                    </NavLink>
                                </NavItem> : null}
                                {reservationPermission && reservationPermission.permissionList.includes("view") && (
                                    <NavItem>
                                        <NavLink
                                            to="#"
                                            className={classnames({ active: activeTab === "2" })}
                                            onClick={() => {
                                                tabChange("2", activeTab, setActiveTab);
                                            }}
                                            type="button"
                                        >
                                            <i className="far fa-user"></i>
                                            Reservations
                                        </NavLink>
                                    </NavItem>
                                )}
                                {subscriptionPermission && subscriptionPermission.permissionList.includes("view") && !isSimulateView && (
                                    <NavItem>
                                        <NavLink
                                            to="#"
                                            className={classnames({ active: activeTab === (reservationPermission ? "3" : "2") })}
                                            onClick={() => {
                                                tabChange(reservationPermission ? "3" : "2", activeTab, setActiveTab);
                                            }}
                                            type="button"
                                        >
                                            <i className="far fa-envelope"></i>
                                            Subscriptions
                                        </NavLink>
                                    </NavItem>
                                )}
                                {!isSimulateView ?
                                    <NavItem>
                                        <NavLink
                                            to="#"
                                            type="button"
                                            className={classnames({
                                                active: activeTab === (reservationPermission ? "4" : subscriptionPermission ? "3" : "2"),
                                            })}
                                            onClick={() => {
                                                tabChange(reservationPermission ? "4" : subscriptionPermission ? "3" : "2", activeTab, setActiveTab);
                                            }}
                                        >
                                            Attributes
                                        </NavLink>
                                    </NavItem> : null}
                                {taskPermission && taskPermission.permissionList.includes("view") && !isSimulateView && (
                                    <NavItem>
                                        <NavLink
                                            to="#"
                                            type="button"
                                            className={classnames({
                                                active: activeTab === (reservationPermission ? "5" : subscriptionPermission ? "4" : taskPermission ? "3" : "2"),
                                            })}
                                            onClick={() => {
                                                tabChange(reservationPermission ? "5" : subscriptionPermission ? "4" : taskPermission ? "3" : "2", activeTab, setActiveTab);
                                            }}
                                        >
                                            Activity Logs
                                        </NavLink>
                                    </NavItem>
                                )}
                                {billingenabled && !isSimulateView && (
                                    <>
                                        <NavItem>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: activeTab === ("6") })}
                                                onClick={() => {
                                                    tabChange("6", activeTab, setActiveTab);
                                                }}
                                                type="button"
                                            >
                                                <i className="far fa-envelope"></i>
                                                Invoices
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                to="#"
                                                className={classnames({ active: activeTab === ("7") })}
                                                onClick={() => {
                                                    tabChange("7", activeTab, setActiveTab);
                                                }}
                                                type="button"
                                            >
                                                <i className="far fa-envelope"></i>
                                                Bills
                                            </NavLink>
                                        </NavItem>
                                    </>
                                )}
                                {!isSimulateView ?
                                    <NavItem className="d-flex align-items-center justify-content-end">
                                        <NavLink
                                            className={classnames("d-flex align-items-center", {
                                                active: activeTab === "8",
                                            })}
                                            onClick={() => {
                                                tabChange("8", activeTab, setActiveTab);
                                            }}
                                        >
                                            <i className="fas fa-video me-2"></i>
                                            <span>{!isSimulateView ? 'Active Sessions' : 'Simulate'}</span>
                                        </NavLink>
                                    </NavItem> : null}
                            </Nav>
                            {activeTab === "8" && !showForm && !isSimulateView && (
                                <div> <Button className="ms-3 py-2 " onClick={() => setShowForm(true)} disabled={!buttonInitiateSession}>
                                    Initiate Session
                                </Button></div>
                            )}
                        </div>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            {!isSimulateView ?

                                <TabPane tabId="1" >
                                    <CustomerProfile customerPermission={customerPermission} customerData={customerData} noCurrencyArray={noCurrencyArray} currencyArray={currencyArray} fetchCustomerSummaryData={fetchCustomerSummaryData} formatDate={formatDate} />
                                </TabPane> : null}
                            {reservationPermission && !isSimulateView && <TabPane tabId="2">
                                <ViewReservation />
                            </TabPane>}
                            {subscriptionPermission && <TabPane tabId={reservationPermission ? "3" : "2"}>
                                <ViewSubscription offer={offer} customerData={customerData} subscriptionPermission={subscriptionPermission} />
                            </TabPane>}
                            <TabPane tabId={reservationPermission ? "4" : subscriptionPermission ? "3" : "2"}>
                                <AttributeTableInView attributeData={attributeData} />
                            </TabPane>
                            {taskPermission && <TabPane tabId={reservationPermission ? "5" : subscriptionPermission ? "4" : taskPermission ? "3" : "2"}>
                                {!view ? <ViewTasks view={view} setTaskDetails={setTaskDetails} setView={setView} /> : <DetailedTaskView taskDetails={taskDetails} />}
                                {(view) && <Row lg={6} className=" justify-content-end mb-4">
                                    <div className="d-flex justify-content-end gap-2 ">
                                        <CommonButton color={"main-color"} type={'submit'} buttonAction={() => {
                                            if (view) {
                                                setView(false)
                                            }
                                        }} buttonText={"Back"} />
                                    </div>
                                </Row>}
                            </TabPane>}
                            {billingenabled && <><TabPane tabId={"6"}>
                                <Invoice invoiceData={invoiceData} />
                            </TabPane><TabPane tabId={"7"}>
                                    <Bills billData={billData} />
                                </TabPane></>}
                            <TabPane tabId="8">
                                <LiveSession customerId={customerId} showForm={showForm}
                                    setShowForm={setShowForm} setButtonInitiateSession={setButtonInitiateSession} isSimulateView={isSimulateView} />

                            </TabPane>
                        </TabContent>
                    </CardBody>
                </div>
            </Container>
            {loading && (<Loader />)}
        </div>
    )
}
