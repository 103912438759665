import { Formik } from "formik";
import { Button, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import SelectField from "../Utils/SelectField";
import { addQuota } from "../../slices/QuotaManagement/AddQuota/thunk";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { editQuota } from "../../slices/QuotaManagement/UpdateQuota/thunk";

export const QuotaForm = ({ extractedData,toggleModals,setModalOpen,initialData ,isEditMode}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const options1 = extractedData.map(({ eventType }) => ({
        label: eventType,
        value: eventType
    }));

    const uomMap = extractedData.reduce((acc, { eventType, uom }) => {
        acc[eventType] = { label: uom, value: uom };
        return acc;
    }, {});

    let initialValues = {
        usageeventtype: isEditMode && initialData.usageEventType ? 
        { label: initialData.usageEventType, value: initialData.usageEventType } : null,
    uom: isEditMode && initialData.UOM ? 
        { label: initialData.UOM, value: initialData.UOM } : null,
        initialQuota: isEditMode?initialData.initialQuota: "",
        incrementalQuota:isEditMode?initialData.incrementalQuota: "",
        validity:isEditMode?initialData.validityTime: "",
    };

    const validationSchema = Yup.object().shape({
        usageeventtype: Yup.object().required("Kindly Enter the Usage Event Type"),
        uom: Yup.object().required("Kindly Enter the UoM"),
        initialQuota: Yup.string().required("Kindly Enter Initial Quota"),
        incrementalQuota: Yup.string().required("Kindly Enter Incremental Quota"),
        validity: Yup.string().required("Kindly Enter the Validity"),
    });

    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={async (values) => {
                    const payload = {
                        usageEventType: values.usageeventtype?.value || "",
                        uom: values.uom?.value || "",
                        initialQuota: parseInt(values.initialQuota, 10) || 0,
                        incrementalQuota: parseInt(values.incrementalQuota, 10) || 0,
                        validityTime: parseInt(values.validity, 10) || 0,
                    };
                    if(!isEditMode){
                    dispatch(addQuota(payload,setLoading,setModalOpen))
                    }else{
                        dispatch(editQuota(payload,initialData.usageEventType,setLoading,setModalOpen))
                    }
                }}
            >
                {({
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <Form className="needs-validation" onSubmit={handleSubmit}>
                        <Col lg={12}>
                            <Row className="gy-4 px-3">
                                <CardBody>
                                    <Row>
                                        <Col className="mb-4" sm={6}>
                                            <Label htmlFor="usageeventtype">
                                                Usage Event Type<span className="ms-1 text-danger">*</span>
                                            </Label>
                                            <SelectField
                                                placeholder="Select an Usage Event"
                                                options={options1}
                                                value={values.usageeventtype || null} 
                                                className={` ${touched.usageeventtype &&
                                                    !values.usageeventtype ? (
                                                    "error-input"
                                                ) : ""}`}
                                                handleChange={(e) => {
                                                    setFieldValue("usageeventtype", e);
                                                    setFieldValue("uom", uomMap[e?.value] || null);
                                                }}
                                                isDisabled={isEditMode?true:false}
                                            />
                                            {touched.usageeventtype && errors.usageeventtype && (
                                                <p className="text-danger validation-font-size">
                                                {errors.usageeventtype}
                                            </p>
                                            )}
                                        </Col>
                                        <Col className="mb-4" sm={6}>
                                            <Label htmlFor="uom">
                                                UoM<span className="ms-1 text-danger">*</span>
                                            </Label>
                                            <SelectField
                                                placeholder="Select an UoM"
                                                options={[]} 
                                                className={` ${touched.uom &&
                                                    !values.uom ? (
                                                    "error-input"
                                                ) : ""}`}
                                                value={values.uom || null} 
                                                isDisabled={true} 
                                            />
                                            {touched.uom && errors.uom && (
                                               <p className="text-danger validation-font-size">
                                               {errors.uom}
                                           </p>
                                            )}
                                        </Col>
                                        <Col className="mb-4" sm={6}>
                                            <Label htmlFor="initialQuota">
                                                Initial Quota<span className="ms-1 text-danger">*</span>
                                            </Label>
                                            <Input
                                                placeholder="Enter Initial Quota"
                                                type="text"
                                                name="initialQuota"
                                                onChange={handleChange}
                                                value={values.initialQuota || ''}
                                                invalid={touched.initialQuota && !!errors.initialQuota}
                                            />
                                            {touched.initialQuota && errors.initialQuota && (
                                                <FormFeedback type="invalid">{errors.initialQuota}</FormFeedback>
                                            )}
                                        </Col>

                                        {/* Incremental Quota */}
                                        <Col className="mb-4" sm={6}>
                                            <Label htmlFor="incrementalQuota">
                                                Incremental Quota<span className="ms-1 text-danger">*</span>
                                            </Label>
                                            <Input
                                                placeholder="Enter Incremental Quota"
                                                type="text"
                                                name="incrementalQuota"
                                                onChange={handleChange}
                                                value={values.incrementalQuota || ''}
                                                invalid={touched.incrementalQuota && !!errors.incrementalQuota}
                                            />
                                            {touched.incrementalQuota && errors.incrementalQuota && (
                                                <FormFeedback type="invalid">{errors.incrementalQuota}</FormFeedback>
                                            )}
                                        </Col>

                                        {/* Validity */}
                                        <Col sm={6}>
                                            <Label htmlFor="validity">
                                                Validity<span className="ms-1 text-danger">*</span>
                                            </Label>
                                            <Input
                                                placeholder="Enter the Validity"
                                                type="text"
                                                name="validity"
                                                onChange={handleChange}
                                                value={values.validity || ''}
                                                invalid={touched.validity && !!errors.validity}
                                            />
                                            {touched.validity && errors.validity && (
                                                <FormFeedback type="invalid">{errors.validity}</FormFeedback>
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Row>
                        </Col>

                        {/* Submit and Cancel Buttons */}
                        <Row className="justify-content-end mb-4 mt-4">
                            <div className="live-preview">
                                <div className="d-flex justify-content-center gap-2">
                                    <Button outline type="reset" onClick={toggleModals}>Close</Button>
                                    <Button type="submit" >Submit</Button>
                                </div>
                            </div>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
