import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editQuota: [],
    loading: false,
    error: null,
}

const editQuotaSlice = createSlice({
    name: "editQuota",
    initialState,
    reducers: {
        fetcheditQuotaSuccess: (state, action) => {
            state.loading = false,
            state.editQuota = action.payload;
        },
        fetcheditQuotaFailure: (state, action) => {
            state.loading = false,
            state.error = action.payload;
        },
    },
})

export const {
    fetcheditQuotaSuccess,
    fetcheditQuotaFailure,
} = editQuotaSlice.actions;

export default editQuotaSlice.reducer;