import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Carousel,
    CarouselControl,
    CarouselIndicators,
    CarouselItem,
    Col,
    Label,
    Row,
} from "reactstrap";
import { customerIdData } from "./constants/constantFunctionsView";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../Utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";
import { fetchAllSessions } from "../../slices/sessions/getAllSessions/thunk";
import { grantData, sessionJsonAndApiCall } from "./constants/SessionFunctions";
import { goToIndex, next, previous } from "./constants/corouselNavigation";
import { SessionCards } from "./SessionCards";
import { fetchCustomerSummary } from "../../slices/customer/simulate/thunk";
// import { goToIndex } from "./constants/SessionFunctions"

export const ActiveSessionBalance = ({customerId}) => {
    // const customerId = customerIdData();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    let simulateOrPreview = "";

    useEffect(() => {
        if (customerId ) {
            simulateOrPreview = "View";
            dispatch(fetchCustomerSummary(customerId, setLoading, simulateOrPreview));
            dispatch(fetchAllSessions(customerId));
        }
    }, [ customerId]);

    const fetchCustomerSummaryData = useSelector((state) => state.Simulate.simulate);
    const formatCarouselData = (data) => {
        return data?.map((item) => ({
            resourceName: item.resourceName,
            consumedBalance: item?.consumedBalance || '0',
            remainingBalance: item?.remainingBalance || '0',
            reservedBalance: item?.reservedBalance|| '0',
            currency: item.currencySymbol || "",
            uom:item.uom || ''
        }));
    };

    const carouselData = formatCarouselData(fetchCustomerSummaryData?.resources || []);

    return (
        <Card >
            {loading && <Loader />}
            {carouselData?.length > 0 && (
                <div>
                    <CardBody>
                        {carouselData.length > 1 ? (
                            <Carousel
                                activeIndex={activeIndex}
                                next={() =>
                                    next(carouselData, activeIndex, setActiveIndex, animating)
                                }
                                previous={() =>
                                    previous(carouselData, activeIndex, setActiveIndex, animating)
                                }
                                interval={false}
                            >
                                <CarouselIndicators
                                    items={carouselData}
                                    activeIndex={activeIndex}
                                    onClickHandler={(newIndex) =>
                                        goToIndex(newIndex, animating, setActiveIndex)
                                    }
                                />
                                {carouselData.map((item, idx) => (
                                    <CarouselItem
                                        onExiting={() => setAnimating(true)}
                                        onExited={() => setAnimating(false)}
                                        key={idx}
                                    >
                                        <SessionCards value={item} />
                                    </CarouselItem>
                                ))}
                                <CarouselControl
                                    className="carousel-width"
                                    direction="prev"
                                    directionText="Previous"
                                    onClickHandler={() =>
                                        previous(carouselData, activeIndex, setActiveIndex, animating)
                                    }
                                />
                                <CarouselControl
                                    className="carousel-width"
                                    direction="next"
                                    directionText="Next"
                                    onClickHandler={() =>
                                        next(carouselData, activeIndex, setActiveIndex, animating)
                                    }
                                />
                            </Carousel>
                        ) : (
                            <SessionCards value={carouselData[0]} />
                        )}
                    </CardBody>
                </div>
            )}
        </Card>
    );
};

