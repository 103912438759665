import React from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

const CustomerPieChart = ({ PieChartActiveCustomerData, PieChartInactiveCustomerData }) => {
  const closedCustomers = PieChartInactiveCustomerData ? PieChartInactiveCustomerData : 0;
  const newCustomers = PieChartActiveCustomerData ? PieChartActiveCustomerData : 0;
  const series = [newCustomers, closedCustomers];
  const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)

  const isEIPEnabled = operatorConfiguration?.configurations
    ?.find(config => config.name === "EIP")
    ?.value?.find(item => item.key === "Enabled")
    ?.value?.[0] === "True";
  const labels = isEIPEnabled
    ? ['Active Devices', 'Closed Devices']
    : ['Active Customers', 'Closed Customers'];
  const options = {
    chart: {
      type: 'pie',
    },
    labels,
    colors: ['#0078A3', '#C5C5C5'],
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        }
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false
    }
  };

  return (
    <div className="App">
      <Chart options={options} series={series} type="donut" height={220} />
    </div>
  );
}

export default CustomerPieChart;