import { Row, Col, Input, Button, Table } from "reactstrap";
import { LabelText } from "../Utils/Input";
import { Slider } from "./Slider";
import { configDisplayNameMapping, notificationDisplayNameMapping } from "./Constant/Options";
import { useEffect, useState } from "react";
import SelectField from '../Utils/SelectField';
// Import normal Quill instead of react-quilljs
import Quill from 'quill';
import "quill/dist/quill.snow.css";
import { SliderForConfig } from "./Constant/SliderForConfig";
import { SliderForEmail } from "./Constant/SliderForEmail";

export const ConfigSlider = ({ isOpen, closeOffcanvas, offcanvasConfig, handleSave, switchState, handleSwitchToggle, handleInputChange, handleRadioChange, handleSelectAllChange, handleCheckboxChange, selectState, handleSelectChange, setSelectState, setUseEffectEnabled, setSwitchState, tempSelectState, tempSwitchState, CustomerChange, filteredValues, setFilteredValues, setTemplates, templates, intialFilteredValue, currencyCodes }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isEmailTemplatesSliderOpen, setIsEmailTemplatesSliderOpen] = useState(false);
    const [isEditTemplateSliderOpen, setIsEditTemplateSliderOpen] = useState(false);
    const [htmlContent, setHtmlContent] = useState("");
    const [inputData, setInputData] = useState({});
    // Create a direct reference to Quill instance instead of using the hook
    const [quillInstance, setQuillInstance] = useState(null);
    // Store all templates locally to manage updates
    // const [templates, setTemplates] = useState([]);

    // Initialize templates when filteredValues changes
    useEffect(() => {
        if (filteredValues && filteredValues.length > 0) {
            setTemplates([...filteredValues]);
        }
        else
            setTemplates([])
    }, [filteredValues]);

    // Create and destroy the Quill editor when the edit slider opens/closes
    useEffect(() => {
        let editor = null;

        // Only create the editor when the edit slider is open
        if (isEditTemplateSliderOpen && selectedTemplate) {
            // Destroy any existing instance first
            if (document.querySelector('#quill-editor .ql-editor')) {
                if (quillInstance) {
                    quillInstance.off('text-change');
                }
                // Clear the container
                const container = document.querySelector('#quill-editor');
                if (container) container.innerHTML = '';
            }

            // Create a fresh editor with a small delay to ensure DOM is ready
            setTimeout(() => {
                try {
                    editor = new Quill('#quill-editor', {
                        theme: 'snow',
                        modules: {
                            toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ font: [] }],
                                [{ size: [] }],
                                ["bold", "italic", "underline", "strike"],
                                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                                [{ indent: "-1" }, { indent: "+1" }],
                                [{ align: [] }],
                                ["direction"],
                                [{ color: [] }, { background: [] }],
                                ["clean"],
                            ]
                        }
                    });

                    setQuillInstance(editor);

                    // Load content into the editor
                    if (selectedTemplate) {
                        try {
                            const templateData = JSON.parse(selectedTemplate.value[0]);
                            setInputData(templateData.inputData || {});

                            let fullHtmlContent = templateData.message || "";
                            let editorContent = fullHtmlContent.replace(/<img[^>]+src=['"]cid:[^'"]+['"][^>]*>/g, "");

                            setHtmlContent(fullHtmlContent);
                            editor.clipboard.dangerouslyPasteHTML(editorContent);

                            // Set up the text-change handler
                            editor.on('text-change', () => {
                                setHtmlContent(editor.root.innerHTML);
                            });
                        } catch (error) {
                            console.error("Error parsing template JSON:", error);
                        }
                    }
                } catch (error) {
                    console.error("Error initializing Quill:", error);
                }
            }, 50);
        }

        // Cleanup function
        return () => {
            if (editor) {
                editor.off('text-change');
            }
        };
    }, [isEditTemplateSliderOpen, selectedTemplate]);

    const handleReset = () => {
        if (selectedTemplate && quillInstance && intialFilteredValue) {
            try {
                // Find the matching template in intialFilteredValue
                const matchingTemplate = intialFilteredValue.find(
                    template => template.name === selectedTemplate.name
                );

                if (matchingTemplate) {
                    // Parse the JSON string from the first (and only) value in the array using intialFilteredValue
                    const templateData = JSON.parse(matchingTemplate.value[0]);

                    // Use the 'message' field from the parsed template data
                    const defaultMessage = templateData.message || "";
                    let displayContent = defaultMessage.replace(/<img[^>]+src=['"]cid:[^'"]+['"][^>]*>/g, "");

                    quillInstance.clipboard.dangerouslyPasteHTML(displayContent);
                    setHtmlContent(defaultMessage);
                } else {
                    console.error("No matching template found in intialFilteredValue");
                }
            } catch (error) {
                console.error("Error resetting template JSON:", error);
            }
        }
    };

    const insertVariable = (variable) => {
        if (quillInstance) {
            const range = quillInstance.getSelection();
            if (range) {
                quillInstance.insertText(range.index, `{{.${variable}}}`);
            }
        }
    };

    const handleSaveTemplate = () => {
        if (quillInstance && selectedTemplate) {
            // Extract content from Quill editor
            let content = quillInstance.root.innerHTML;

            // Ensure image placeholder stays
            if (!content.includes("<img")) {
                content = `<img src="cid:image1" width="200px">` + content;
            }

            // Comprehensive inline styling approach
            const processedContent = processEmailContent(content);

            try {
                const templateData = JSON.parse(selectedTemplate.value[0]);

                const updatedTemplateData = { ...templateData };
                updatedTemplateData.message = processedContent;

                const updatedTemplateValue = JSON.stringify(updatedTemplateData);

                const updatedTemplate = {
                    name: selectedTemplate.name,
                    value: [updatedTemplateValue],
                };

                const updatedTemplates = templates.map(template =>
                    template.name === selectedTemplate.name ? updatedTemplate : template
                );

                setTemplates(updatedTemplates);

                if (typeof setFilteredValues === 'function') {
                    setFilteredValues(updatedTemplates);
                }

                setIsEditTemplateSliderOpen(false);

                return updatedTemplate;
            } catch (error) {
                console.error("Error updating template JSON:", error);
                return null;
            }
        }
        return null;
    };

    // Comprehensive content processing function
    const processEmailContent = (content) => {
        // Create a temporary div to manipulate the content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;

        // More comprehensive font and styling mapping
        const fontClasses = {
            'ql-font-serif': { fontFamily: 'Georgia, "Times New Roman", serif' },
            'ql-font-monospace': { fontFamily: 'Courier, "Courier New", monospace' },
            'ql-font-sans-serif': { fontFamily: 'Arial, Helvetica, sans-serif' }
        };

        // Expanded size classes with more granular mapping
        const sizeClasses = {
            'ql-size-small': { fontSize: '0.75em' },
            'ql-size-large': { fontSize: '1.5em' },
            'ql-size-huge': { fontSize: '2.5em' }
        };

        // Comprehensive style mapping
        const styleMapping = {
            ...fontClasses,
            ...sizeClasses,
            'ql-align-center': { textAlign: 'center' },
            'ql-align-right': { textAlign: 'right' },
            'ql-align-left': { textAlign: 'left' },
            'ql-align-justify': { textAlign: 'justify' }
        };

        // Recursive processing function
        const processElement = (element) => {
            // Preserve original classes for potential future reference
            const originalClasses = Array.from(element.classList);

            // Apply styles based on classes
            originalClasses.forEach(className => {
                if (styleMapping[className]) {
                    Object.entries(styleMapping[className]).forEach(([prop, value]) => {
                        element.style[prop] = value;
                    });
                    // Optionally, keep the original class for reference
                    element.dataset.originalClass = className;
                }
            });

            // Process child elements
            Array.from(element.children).forEach(processElement);
        };

        // Process the entire content
        processElement(tempDiv);

        // Wrap in a styled container
        const emailWrapper = `
        <div style="
            max-width: 400px; 
            background-color: white; 
            margin: 0 auto; 
            border-radius: 8px; 
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
            padding: 20px; 
            border: solid; 
            border-color: #0078A3; 
            border-radius: 12px;
            font-family: Arial, Helvetica, sans-serif;
        ">
            ${tempDiv.innerHTML}
        </div>
        `;

        return emailWrapper;
    };
    const formatTemplateName = (name) => {
        return name.replace(/([A-Z])/g, " $1").trim();
    };

    return (
        <>
            <Slider
                isOpen={isOpen}
                setOpen={closeOffcanvas}
                actionName={
                    configDisplayNameMapping[offcanvasConfig?.name] ||
                    configDisplayNameMapping[offcanvasConfig?.value[0]?.key] ||
                    offcanvasConfig?.name
                }
                handleSave={handleSave}
            >
                <Row>
                    {/* Rest of your component code remains the same */}
                    {offcanvasConfig?.name === "paymentManagement" || offcanvasConfig?.name === "identityManagement" ? (
                        offcanvasConfig?.value?.map((item, idx) => (
                            (item.key === "PaymentProvider" || item.key === "IdentityProvider") && (
                                <Col lg={6} md={8} key={idx}>
                                    <div className="mb-2">
                                        <LabelText displayText={item.key === "PaymentProvider" ? "Payment Provider" : "Identity Provider"} />
                                        {item?.value?.map((provider, providerIdx) => (
                                            <div key={providerIdx} className="border p-2 rounded-4 mb-2">
                                                <Input
                                                    type="radio"
                                                    checked={tempSelectState[item.key]?.value === provider}
                                                    className="me-2"
                                                    onChange={() => handleSelectChange(item.key, provider, setSelectState)}
                                                />
                                                <LabelText displayText={provider} />
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            )
                        ))
                    ) : offcanvasConfig?.name === "UsageEventsPersistanceMode" ? (
                        offcanvasConfig?.value?.map((item, idx) => (
                            item.key === "Mode" && (
                                <Col lg={6} md={8} key={idx}>
                                    <div className="mb-2">
                                        <LabelText displayText="Mode" />
                                        {item.value.map((mode, modeIdx) => (
                                            <div key={modeIdx} className="border p-2 rounded-4 mb-2">
                                                <Input
                                                    type="radio"
                                                    name="mode"
                                                    className="me-2"
                                                    checked={tempSwitchState["Mode"] === mode}
                                                    onChange={() => handleRadioChange("Mode", mode)}
                                                />
                                                {mode}
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            )
                        ))
                    ) : offcanvasConfig?.name === "customerRetrieval" ? (
                        <Col lg={6} md={8}>
                            <div>
                                <LabelText displayText="Customer Retrieval" />
                                {offcanvasConfig?.value?.map((item, ind) => (
                                    <div key={ind} className="border p-2 rounded-4 mb-2">
                                        <Input
                                            type="radio"
                                            name="customerRetrieval"
                                            className="me-2"
                                            checked={tempSwitchState[item.key]}
                                            onChange={() => CustomerChange(item.key)}
                                        />
                                        <LabelText displayText={item.key} />
                                    </div>
                                ))}
                            </div>
                        </Col>
                    ) : (offcanvasConfig?.name === "DefaultCurrency") ? 
                        (<div>
                                <SelectField
                                name={`defaultCurrency`}
                                value={
                                    currencyCodes?.find(
                                        (currency) => currency.label === tempSwitchState[offcanvasConfig?.value[0]?.key]
                                    ) || tempSwitchState[offcanvasConfig?.value[0]?.key]
                                }
                                handleChange={(selectedOption) => {
                                    handleRadioChange("CurrencyCode", selectedOption?.value || "");
                                }}
                                placeholder={"Select Default Currency"}
                                options={currencyCodes}
                            />
                        </div>
                    )
                    : (offcanvasConfig?.value.length === 1 || offcanvasConfig?.name === "Prefixes" || offcanvasConfig?.name === "SequenceHandling") ? (
                        <Col lg={12} md={12}>
                            {offcanvasConfig?.value.map((val, idx) =>
                                <div key={idx}>
                                    <LabelText
                                        displayText={
                                            configDisplayNameMapping[val?.key] ||
                                            configDisplayNameMapping[offcanvasConfig.name]
                                        }
                                        className="me-5"
                                    />
                                    {offcanvasConfig?.value.length !== 1 &&
                                        <p className="text-muted mt-0">Enter A {offcanvasConfig.name === "Prefixes" ? `Prefix (Eg., ${val?.key.substr(0, 3).toUpperCase()})` : "Sequence Number(Eg., 101, 1002, 1113)"} Up To {offcanvasConfig.name === "Prefixes" ? 16 : 8} Characters</p>
                                    }
                                    <Input
                                        type={offcanvasConfig.name === "Prefixes" ? "text" : "number"}
                                        className="mb-3 mt-0"
                                        value={tempSwitchState[val?.key]}
                                        onChange={(e) => handleInputChange(val?.key, e.target.value)}
                                    />
                                </div>
                            )}
                        </Col>
                    ) :
                        <div>
                            {(offcanvasConfig?.name === "notificationManagement" || offcanvasConfig?.name === "NotificationManagement") &&
                                (<>
                                    {filteredValues && filteredValues.length > 0 && (
                                        <>
                                            <div className="d-flex form-switch align-items-center border p-2 rounded-4 w-50 mb-2">
                                                <i className="fs-4 me-3 ri-eye-line" onClick={() => setIsEmailTemplatesSliderOpen(true)} />
                                                <LabelText
                                                    displayText="Email Template"
                                                    className="flex-grow-1"
                                                />
                                            </div>
                                            <div className="border-bottom mb-2 mt-3"></div>
                                        </>
                                    )}
                                    <div>
                                        <Input
                                            type="checkbox"
                                            className="me-2"
                                            checked={tempSwitchState["Select All"] || false}
                                            onChange={() => handleSelectAllChange()}
                                        />
                                        <LabelText displayText="Select All" /> </div>
                                </>
                                )}
                            <div className={(offcanvasConfig?.name === "notificationManagement" || offcanvasConfig?.name === "NotificationManagement") ? "row" : ""}>
                                {offcanvasConfig?.value?.filter(item => item.key !== "NotificationEnable").map((item, idx) => (
                                    <Col md={8} lg={6} key={idx}>
                                        <div className="border p-2 rounded-4 mb-2">
                                            <Input
                                                type="checkbox"
                                                className="me-2"
                                                checked={tempSwitchState[item.key] || false}
                                                onChange={() => handleCheckboxChange(item.key)}
                                            />
                                            <LabelText displayText={notificationDisplayNameMapping[item.key] || item.key} />
                                        </div>
                                    </Col>
                                ))}
                            </div>
                        </div>
                    }
                </Row>
            </Slider>

            {/* Email Templates List Slider */}
            {isEmailTemplatesSliderOpen && (
                <SliderForConfig
                    isOpen={isEmailTemplatesSliderOpen}
                    setOpen={() => setIsEmailTemplatesSliderOpen(false)}
                    actionName="Notifications ( Email Settings ) :"
                >
                    <Row>
                        <Col lg={12}>
                            <LabelText classLabelName={"fs-5 fw-semibold"} displayText="Edit Template :" />
                            <div className="row">
                                {templates.map((template, index) => {
                                    const rawName = template.name.split('.').pop();
                                    const displayName = formatTemplateName(rawName);
                                    return (
                                        <div key={index} className="col-6 mb-2 mt-1">
                                            <div
                                                className="d-flex form-switch align-items-center border p-2 rounded-4"
                                                onClick={() => {
                                                    setSelectedTemplate(template);
                                                    setIsEditTemplateSliderOpen(true);
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <i className="fs-4 me-3 ri-edit-2-line" />
                                                <LabelText displayText={displayName} className="flex-grow-1 mb-1" />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                        </Col>
                    </Row>
                </SliderForConfig>
            )}

            {/* Edit Email Template Slider */}
            {isEditTemplateSliderOpen && selectedTemplate && (
                <SliderForEmail
                    isOpen={isEditTemplateSliderOpen}
                    setOpen={() => setIsEditTemplateSliderOpen(false)}
                    actionName="Notifications ( Email Settings ) :"
                    handleSave={handleSaveTemplate}
                    handleReset={handleReset}
                >
                    <Row>
                        <Col lg={12}>
                            <div className="p-2">
                                {/* Available Variables */}
                                <div className="d-flex flex-wrap ">
                                    {Object.keys(inputData)
                                        .filter(key => {
                                            // Exclude keys related to attachments and images
                                            const excludedPatterns = [
                                                /attachment/i,
                                                /inline_?image/i,
                                                /^image/i,
                                                /file/i,
                                                /^images/i,
                                                /^img/i,
                                                /^doc/i,
                                                /^media/i
                                            ];

                                            return !excludedPatterns.some(pattern => pattern.test(key));
                                        })
                                        .map((key) => {
                                            // Convert camelCase or snake_case to Title Case
                                            const displayName = key
                                                .replace(/([A-Z])/g, ' $1')  // Add space before capital letters
                                                .replace(/_/g, ' ')  // Replace underscores with spaces
                                                .replace(/\b\w/g, char => char.toUpperCase());  // Capitalize first letter of each word
                                            return (
                                                <Button
                                                    key={key}
                                                    size="md"
                                                    className="m-1 email-button"
                                                    onClick={() => insertVariable(key)}
                                                >
                                                    {displayName}
                                                </Button>
                                            );
                                        })
                                    }
                                </div>
                                {/* Quill Editor - Important: We're using a div with a fixed ID for direct manipulation */}
                                <div className="snow-editor mt-2">
                                    <div id="quill-editor" style={{ height: "300px" }}></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </SliderForEmail>
            )}
        </>
    );
}