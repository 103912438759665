import React from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody, Row, Col, Label } from "reactstrap";

export const CustomerViewDetails = ({ customerData, customerPermission, aggregateResources }) => {
        const operatorConfiguration = useSelector((state)=>state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)
        
        const isEIPEnabled = operatorConfiguration?.configurations
          ?.find(config => config.name === "EIP")
          ?.value?.find(item => item.key === "Enabled")
          ?.value?.[0] === "True";
    
    const buttonLabel = isEIPEnabled ? "Device Details" : "Customer Details";
    return (
        <>
            <Card className="border mb-0 rounded-top" style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
                <CardHeader className="d-flex align-items-center p-1 ps-4">
                    <Label className="balance-heading-color fs-5 mt-2">{buttonLabel}</Label>
                </CardHeader>
                <CardBody>
                    <Row lg={6}>
                        <Col lg={6}>
                            <div >
                                <div className="row">
                                    <div className="row mb-3">
                                        {/* <div className="col fs-14"><Label className="fw-bold">{customerPermission?.autoload ? "Device ID" : "Device ID"}</Label></div> */}
                                        <div className="col fs-14">
                                            <Label className="fw-bold">
                                                {isEIPEnabled
                                                    ? (customerPermission?.autoload ? "Device ID" : "Device ID")
                                                    : (customerPermission?.autoload ? "Account Number" : "Customer Id")
                                                }
                                            </Label>
                                        </div>
                                        <div>{customerData?.customerInfo?.customerId}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">Firstname</Label></div>
                                        <div>{customerData?.customerInfo?.firstName}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">Lastname</Label></div>
                                        <div>{customerData?.customerInfo?.lastName}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div >
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">Email</Label></div>
                                        <div>{customerData?.customerInfo?.email}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">{customerPermission?.autoload ? "Phone Number" : "Contact No"}</Label></div>
                                        <div>{customerData?.customerInfo?.dialCode + '-' + customerData?.customerInfo?.mobile}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};

export const CustomerAddress = ({ customerData, aggregateResources }) => {
    const operatorConfiguration = useSelector((state)=>state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)
        
    const isEIPEnabled = operatorConfiguration?.configurations
      ?.find(config => config.name === "EIP")
      ?.value?.find(item => item.key === "Enabled")
      ?.value?.[0] === "True";

    const buttonLabel1 = isEIPEnabled ? "Device Location" : "Address";
    return (
        <>
            <Card className="border mb-5 " style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                <CardHeader className="d-flex align-items-center p-1 ps-4">
                    <Label className=" fs-5 mt-2 balance-heading-color">{buttonLabel1}</Label>
                </CardHeader>
                <CardBody>
                    <Row lg={6}>
                        <Col lg={6}>
                            <div >
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">Address 1</Label></div>
                                        <div>{customerData?.customerInfo?.addressLine1}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">Address 2</Label></div>
                                        <div >{customerData?.customerInfo?.addressLine2 || '-'}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">Country</Label></div>
                                        <div>{customerData?.customerInfo?.country}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div >
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">State</Label></div>
                                        <div>{customerData?.customerInfo?.state}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col"><Label className="fs-14 label-color fw-bold">City</Label></div>
                                        <div>{customerData?.customerInfo?.city}</div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col "><Label className="fs-14 label-color fw-bold">Zip Code</Label></div>
                                        <div>{customerData?.customerInfo?.zipCode}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};