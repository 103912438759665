import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Card, CardBody, Col, Container, Row, Form, FormGroup, Input } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { LabelText } from "../../Utils/Input";
import { Loader } from '../../Utils/Loader';
import { getValidationSchema } from './constants/validationschema';
import CommonButton from '../../Utils/CommonButton';
import { PAGE_TITLES } from '../../Common/constants';
import { fetchAllUom } from '../../../slices/uom/thunk';
import formSchema from "../../Utils/schema.json";
import { RenderField } from "../../Utils/renderFields";
import { RenderErrorMessage } from "../../Utils/renderErrorMessages";
import { consumptionRules, resOptionsAddition, typevalue, uomOptionsData } from './constants/selectoptions';
import { getAllResourcesById } from '../../../slices/resources/getresourcebyID/thunk';
import { customerIdData } from '../../Customers/constants/constantFunctionsView';
import { getAllResources } from '../../../slices/resources/thunk';
import ThresholdNotification from './ThresoldNotification';
import { addThreshold, handleThresholdChange, removeThreshold, toggleButton } from './constants/thresholdFunction';
import { handleSubmit } from './constants/resourcePayload';
import { set } from 'lodash';
export default function AddResources(initialAIResourceValues) {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [loading, setLoading] = useState('')
    const [isChecked, setIsChecked] = useState(false)
    const [isCounter, setIsCounter] = useState(false)
    const [isCurrency, setIsCurrency] = useState('')
    const isEdit = location.pathname.includes('edit-resource')
    const isAiPath = location.pathname.includes('chatbot')
    const [aggregate, setAggregate] = useState(false)
    const [thresholds, setThresholds] = useState([]);
    const [aggregateDisable, setAggregateDisable] = useState(false)
    const initialValues = {
        isCurrency: '',
        name: "",
        resourceId: "",
        currencyCode: "",
        ceiling: "",
        floor: "",
        uom: '',
        consumptionRule:consumptionRules?.options[0],
        aggregateResource: '',
        thresholds: [{thresholdType:"Fixed",thresholdValue:''}]
    };
    const validationSchema = getValidationSchema(isCurrency, aggregate);
    const [activeButtonIndex, setActiveButtonIndex] = useState(Array(thresholds.length).fill(0));
    const resourceName = customerIdData()
    useEffect(() => {
        dispatch(fetchAllUom(setLoading))
        dispatch(getAllResources(setLoading));
        setThresholds(initialValues.thresholds);
        if (isEdit) {
            dispatch(getAllResourcesById(setLoading, resourceName, initialValues, setThresholds))
        }
        if (aggregateDisable) {
            setAggregate(false)
        }
    }, [])
    const uomData = useSelector((state) => state.Uom.uom)
    const resourcebyID = useSelector((state) => state.ResourceById.resourceById)
    const uomOptions = uomOptionsData(uomData)
    const resourceData = useSelector((state) => state.Resources.resources);
        useEffect(() => {
        if (isEdit) {
            if (resourcebyID.override) {
                setIsChecked(true)
            } else {
                setIsChecked(false)
            }
            if (resourcebyID.isCounter) {
                setIsCounter(true)
            } else if (!resourcebyID.isCounter) {
                setIsCounter(false)
            }
            if (resourcebyID.isAggregate) {
                setAggregate(true)
            } else if (!resourcebyID.isAggregate) {
                setAggregate(false)
            }
            else {
                setIsCounter(false);
            }
            if (isEdit && resourcebyID?.threshold) {
                setThresholds(resourcebyID?.threshold);
            }
            
        }
        if (aggregate) {
            setIsCounter(true);
        }
    }, [resourcebyID, isEdit, aggregate])

    const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)
    const currencyConfig = operatorConfiguration?.configurations?.find(config => config?.name === "DefaultCurrency")?.value[0]?.value[0]

    const resOption = resOptionsAddition(uomOptions, aggregate, resourceData, currencyConfig);
    const pageTitleHeading = isEdit ? PAGE_TITLES.EDIT_RESOURCE : PAGE_TITLES.ADD_RESOURCE;
    return (
        <React.Fragment>
            <div id='csms' className={!isAiPath ? "page-content" : "pb-5"}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={isAiPath ? initialAIResourceValues.initialAIResourceValues : initialValues}
                    onSubmit={async (values) => {
                        if (values.name && values.resourceId) {
                            handleSubmit({ values, aggregate, isChecked, isCounter, isEdit, resourceName, dispatch, setLoading, navigate, });
                        }
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched, setTouched, isValid, dirty, resetForm }) => (
                        <Form className="needs-validation" onSubmit={handleSubmit}>
                            <Container fluid>
                                {isAiPath ? null :
                                    <BreadCrumb title={pageTitleHeading} pageTitle={PAGE_TITLES.RESOURCE_LIST} />}
                                <ToastContainer position='top-center' />
                                <Col className="mt-1" xxl={4} md={6}>
                                    <FormGroup switch >
                                        <div className="flex-column">
                                            <Input className="mt-1" type={"switch"} checked={aggregate} onClick={() => setAggregate(!aggregate)} disabled={values.isCurrency.value} />
                                            <p className='mt-n1' classLabelName={values.isCurrency.value ? "label-disable-color-edit-offer" : ""}>Aggregate</p>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <div className="listingjs-table " id="customerList">
                                                    <CardBody className="card-body mb-4">
                                                        <div className="live-preview">
                                                            {formSchema.map((section, sectionIndex) => (
                                                                <Row className="gy-4 pt-1 pb-3" key={sectionIndex}>
                                                                    {section.title === "Resource" && section.sections.map((subSection, subSectionIndex) => (
                                                                        subSection.fields.filter(field => {
                                                                            if (field.name === "currencyCode" && values.isCurrency?.value === false) {
                                                                                setIsCurrency(values.isCurrency?.value)
                                                                                return false;
                                                                            }
                                                                            if (values.isCurrency?.value === false) {
                                                                                setAggregateDisable(true)
                                                                            }
                                                                            if (isEdit && (field.name === "isCurrency" || field.name === "name" || field.name === "resourceId")) {
                                                                                field.disable = true
                                                                            } else {
                                                                                field.disable = false
                                                                            }
                                                                            if (field.name === "uom" && values.isCurrency?.value === true) {
                                                                                setIsCurrency(values.isCurrency?.value)
                                                                                return false
                                                                            }
                                                                            if (field.name === "Counter" && aggregate) {
                                                                                return false
                                                                            }
                                                                            if (field.name === "Counter" && values.isCurrency?.value === true) {
                                                                                setIsCurrency(values.isCurrency?.value)
                                                                                return false
                                                                            }
                                                                            if (field.name === "aggregateResource") {
                                                                                if (!aggregate) {
                                                                                    return false;
                                                                                }
                                                                            }
                                                                            if (field.name === "ceiling") {
                                                                                if (aggregate) {
                                                                                    return false;
                                                                                }
                                                                            }
                                                                            if (field.name === "floor") {
                                                                                if (aggregate) {
                                                                                    return false;
                                                                                }
                                                                            }
                                                                            if (aggregate) {
                                                                                values.isCurrency = typevalue.options[1]
                                                                            }
                                                                            if (field.name === "resourceId") {
                                                                                if (values.isCurrency?.value === false) {
                                                                                    field.label = "Numeric Code";
                                                                                    field.placeholder = "Enter your Numeric Code";
                                                                                } else {
                                                                                    field.label = "Currency Number";
                                                                                    field.placeholder = "Enter your Currency Number";
                                                                                }
                                                                            }
                                                                            return true;
                                                                        }).map((field, fieldIndex) => (
                                                                            <>
                                                                                <Col xxl={6} md={6} key={`${subSectionIndex}-${fieldIndex}`}>
                                                                                    <LabelText
                                                                                        htmlFor={field.name}
                                                                                        className={field.required ? "text-danger" : ""}
                                                                                        displayText={field.label}
                                                                                        important={field.required ? "*" : ""}
                                                                                    />
                                                                                    {(field.secondLabel && values.isCurrency?.value === false) &&
                                                                                        <LabelText
                                                                                            displayText={field.secondLabel}
                                                                                            classLabelName={field.secondClassName}
                                                                                        />
                                                                                    }
                                                                                    <RenderField field={field}
                                                                                        handleChange={handleChange}
                                                                                        handleBlur={handleBlur}
                                                                                        values={values}
                                                                                        touched={touched}
                                                                                        errors={errors}
                                                                                        setFieldTouched={setFieldTouched}
                                                                                        setFieldValue={setFieldValue} options={resOption} setIsChecked={setIsChecked} isChecked={isChecked} isCounter={isCounter} setIsCounter={setIsCounter} />
                                                                                    <RenderErrorMessage field={field} touched={touched} errors={errors} />
                                                                                </Col>
                                                                                {(field?.name === "consumptionRule" && values?.isCurrency?.value === false) && <Col xxl={6} md={6}></Col>}
                                                                                {(field?.name === "aggregateResource" && aggregate) && <Col xxl={12} md={12}></Col>}
                                                                            </>
                                                                        ))
                                                                    ))}
                                                                </Row>
                                                            ))}
                                                        </div>
                                                    </CardBody>
                                                </div>
                                            </CardBody>
                                        </Card>
                                        {!isCurrency || resourceData?.isAggregate ? (
                                            <ThresholdNotification
                                                thresholds={thresholds}
                                                addThreshold={() => addThreshold(thresholds, setThresholds, activeButtonIndex, setActiveButtonIndex, setFieldValue)}
                                                toggleButton={(index, value) =>
                                                    toggleButton(index, value, activeButtonIndex, setActiveButtonIndex, thresholds, setThresholds,setFieldValue)
                                                }
                                                handleThresholdChange={(index, field, value) =>
                                                    handleThresholdChange(index, field, value, thresholds, setThresholds,setFieldValue)
                                                }
                                                removeThreshold={(index, setFieldValue, values) =>
                                                    removeThreshold(index, thresholds, setThresholds, activeButtonIndex, setActiveButtonIndex, setFieldValue, values)
                                                }
                                                handleChange={handleChange}
                                                touched={touched}
                                                errors={errors}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isEdit={isEdit}
                                                setThresholds={setThresholds}
                                            />
                                        ) : null}
                                        <Row lg={6} className=" justify-content-end mb-4">
                                            <div className="live-preview">
                                                <div className="d-flex justify-content-end gap-2 ">
                                                    <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/resource')} buttonText={"Cancel"} />
                                                    <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} buttonAction={handleSubmit} />
                                                </div>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
                {loading && (<Loader />)}
            </div>
        </React.Fragment>
    );
}