import { Card, Row, Col, Container } from "reactstrap";
import { LabelText } from "../Utils/Input";
import InvoiceListTable from "./InvoiceListTable";
import BreadCrumb from "../Common/BreadCrumb";
import { useEffect, useState } from "react";
import { Loader } from "../Utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceStatistics } from "../../slices/invoices/invoicestatistics/thunk";
import { fetchAllInvoices } from "../../slices/invoices/invoicelist/thunk";
import { ToastContainer } from "react-toastify";
import { formatNumberForStatistics } from "./constants/HandleChangeFunctions";
import { PAGE_TITLES } from "../Common/constants";
import { InvoiceAnalyticsCard } from "./InvoiceAnalyticsCard";
import { invoiceListColumns } from "./constants/CustomerTableColumns";
import { useColumnSettings } from "../Utils/useColumnSettings";
import { getView } from "../../slices/View/thunk";
export default function InvoiceListing() {
    const [loading, setLoading] = useState()
    const [profileData, setProfileData] = useState()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchInvoiceStatistics(setLoading))
        dispatch(fetchAllInvoices(setLoading))
        dispatch(getView(setLoading));
    }, [dispatch])
    const invoiceStatisticsData = useSelector((state) => state.InvoiceStatistics.invoiceStatistics)
    const invoiceListData = useSelector((state) => state.InvoiceList.invoiceList)
    const columns = invoiceListColumns()
    const {
        settingsModalOpen,
        viewColumns,
        toggleSettingsModal,
        SettingsSidebar
    } = useColumnSettings('invoice', columns);

    const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)

    const isEIPEnabled = operatorConfiguration?.configurations
        ?.find(config => config.name === "EIP")
        ?.value?.find(item => item.key === "Enabled")
        ?.value?.[0] === "True";

    const customerListTitle = isEIPEnabled
        ? PAGE_TITLES.CUSTOMER_LIST_EIP
        : PAGE_TITLES.CUSTOMER_LIST;

    return (
        <div className="page-content">
            <Container fluid>
                {loading && <Loader />}
                <BreadCrumb title={PAGE_TITLES.CUSTOMER_INVOICE} pageTitle={customerListTitle} />
                <ToastContainer position="top-center" />
                <Row className="gy-4">
                    <InvoiceAnalyticsCard heading={"Invoices Sent"} currencyCode={invoiceStatisticsData?.currencyCode} totalAmount={invoiceStatisticsData?.totalAmount} particularTotalCount={invoiceStatisticsData?.totalInvoicesSent} icon={"ri-file-text-line"} />
                    <InvoiceAnalyticsCard heading={"Paid Invoices"} currencyCode={invoiceStatisticsData?.currencyCode} totalAmount={invoiceStatisticsData?.paidInvoicesAmt} particularTotalCount={invoiceStatisticsData?.paidInvoices} icon={"ri-checkbox-line"} />
                    <InvoiceAnalyticsCard heading={"Unpaid Invoices"} currencyCode={invoiceStatisticsData?.currencyCode} totalAmount={invoiceStatisticsData?.unpaidInvoicesAmt} particularTotalCount={invoiceStatisticsData?.unpaidInvoices} icon={"ri-time-line"} />
                    <InvoiceAnalyticsCard heading={"Cancelled Invoices"} currencyCode={invoiceStatisticsData?.currencyCode} totalAmount={invoiceStatisticsData.cancelledInvoicesAmt} particularTotalCount={invoiceStatisticsData?.cancelledInvoices} icon={"ri-close-circle-line"} />
                </Row>
                <Row>
                    <Col lg={settingsModalOpen ? 10 : 12}>
                        <Card>
                            <InvoiceListTable viewColumns={viewColumns} columns={columns} toggleSettingsModal={toggleSettingsModal} setProfileData={setProfileData} invoiceListData={invoiceListData} dispatch={dispatch} setLoading={setLoading} />
                        </Card>
                    </Col>
                    {SettingsSidebar()}
                </Row>
            </Container>
        </div>
    )
}