import { toast } from "react-toastify";
import { fetcheditQuotaSuccess, fetcheditQuotaFailure } from "./reducer";
import { setEditSuccessMessage } from "../../toastMessage/action";
import {  QUOTA_MANAGEMENT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { getAllQuota } from "../thunk";

export const editQuota = (jsonObj,eventType, setLoading,setModalOpen) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${QUOTA_MANAGEMENT_API}/${eventType}`, 'PUT', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetcheditQuotaFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            dispatch(fetcheditQuotaSuccess(response))
            dispatch(setEditSuccessMessage(true))
            setModalOpen(false)
            dispatch(getAllQuota(setLoading))
            setLoading(false)
        }
    }).catch((errorResp) => { })
};