import { Card, CardBody, Col, Container, Progress, Row, Table } from "reactstrap"
import BreadCrumb from "../Common/BreadCrumb"
import { PAGE_TITLES } from "../Common/constants"
import { LabelText } from "../Utils/Input"
import CommonButton from "../Utils/CommonButton"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../Utils/Loader"
import moment from "moment"
import { customerIdData } from "./constants/constantFunctionsView"
import { fetchCustomer } from "../../slices/customer/fetchcustomer/thunk"
import { fetchSelectedResourceSuccess } from "../../slices/customer/balance/reducer"
import { ToastContainer, toast } from "react-toastify"
import { setSuccessMessage } from "../../slices/toastMessage/action"

export const ChangeSummary = () => {
    const location = useLocation()
    const subId = location?.state?.id
    const attributes = location?.state?.attributes
    const offerName = location?.state?.offerName
    const [loading, setLoading] = useState(false)
    const customerName = location?.state?.customerData?.firstName + " " + location?.state?.customerData?.lastName
    const time = moment().format('DD MMM YYYY')
    const dispatch = useDispatch()
    const customerId = customerIdData()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(fetchCustomer(setLoading, customerId))
    }, [dispatch])

    const SelectedResourceData = useSelector(state => state.Balance.selectedResource)
    const showSuccessMessage = useSelector(
        (state) => state.SuccessToast.showSuccessMessage
    );
    const fetchChangePlanData = useSelector(state => state.ChangePlan.changePlan)

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Offer Changed Successfully`);
            dispatch(setSuccessMessage(false));
        }
    }, [showSuccessMessage, dispatch]);

    return (
        <div className="page-content">
            <Container fluid>
                {loading && <Loader />}
                <ToastContainer position="top-center"/>
                <BreadCrumb title={PAGE_TITLES.CHANGE_PLAN} pageTitle={PAGE_TITLES.CUSTOMER_SUBSCRIBE} />
                <Card>
                    <CardBody>
                        <Row className="gy-4 mb-3">
                            <Col lg={12}>
                                <div className="p-4 border rounded ">
                                    <h4 className="card-title mb-0 flex-grow-1">CUSTOMER DETAILS</h4>
                                    <hr />
                                    <Row>
                                        <Col xxl={3} >
                                            <LabelText displayText={"Customer Name"} />
                                            <p>{customerName}</p>
                                        </Col>
                                        <Col xxl={3}>
                                            <LabelText displayText={"Customer ID"} />
                                            <p>{fetchChangePlanData?.customerId}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className="gy-4">
                            <Col lg={12}>
                                <div className="p-4 border rounded ">
                                    <h4 className="card-title mb-0 flex-grow-1">SUBSCRIPTION DETAILS</h4>
                                    <hr />
                                    <Row>
                                        <Col xxl={3} >
                                            <LabelText displayText={"Subscription ID"} />
                                            <p>{fetchChangePlanData?.subscriptionId}</p>
                                        </Col>
                                        <Col xxl={3} >
                                            <LabelText displayText={"Offer Name"} />
                                            <p>{offerName}</p>
                                        </Col>
                                        <Col xxl={3} >
                                            <LabelText displayText={"Change Offer Date"} />
                                            <p>{time}</p>
                                        </Col>
                                        <Col xxl={3} >
                                            <LabelText displayText={"Change Offer Effective Date"} />
                                            <p>{moment(fetchChangePlanData?.validFrom).format('DD MMM YYYY')}</p>
                                        </Col>
                                    </Row>
                                    <div className="p-4 border rounded mb-4">
                                        <h4 className="card-title mb-0 flex-grow-1">Grants</h4>
                                        <hr />
                                        {SelectedResourceData.length ?
                                            SelectedResourceData?.map((resource) => {
                                                return resource.validFrom && (
                                                    <div className="mb-4" key={resource.resourceName}>
                                                        <LabelText displayText={resource.resourceName} />
                                                        <div className="d-flex justify-content-between align-content-between">
                                                            <LabelText displayText={`${resource?.remainingBalance} left of ${resource.originalBalance}`} />
                                                            <LabelText displayText={`${moment(resource.validFrom).format("DD/MMM/YYYY")} - ${moment(resource.validTo).format("DD/MMM/YYYY")}`} />
                                                        </div>
                                                        <Progress max={resource?.originalBalance} value={resource?.remainingBalance}></Progress>
                                                    </div>
                                                );
                                            })
                                            : <div className="text-center fs-5">Balance Exhausted</div>
                                        }
                                    </div>
                                    {attributes?.length ? <div className="p-4 border rounded ">
                                        <h4 className="card-title mb-0 flex-grow-1">Attributes</h4>
                                        <hr />
                                        <CardBody>
                                            <Table className=' table-border-none'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {attributes.map((attribute, index) => (
                                                        <tr key={index}>
                                                            <td>{attribute.name}</td>
                                                            <td>{attribute.value}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </div> : null}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row lg={6} className=" justify-content-end mb-4">
                    <div className="live-preview">
                        <div className="d-flex justify-content-end gap-2 ">
                            <CommonButton color={"main-color px-4"} type={'submit'} buttonAction={() => {
                                dispatch(fetchSelectedResourceSuccess([{}]))
                                navigate(`/view-customer/${customerId}`)
                            }} buttonText={"OK"} />
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}