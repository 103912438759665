import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchPlaceOrderFailure, fetchPlaceOrderSuccess } from "./reducer";
import { ORDERS_LIST_API } from "../../constants/constants";
import { toast } from "react-toastify";
import { setDeleteSuccessMessage, setSuccessMessage } from "../../toastMessage/action";

export const updateOrderStatus = (state) => ({
    type: 'UPDATE_ORDER_STATUS',
    payload: { state },
});
export const placeOrder = (setLoading, jsonObj, orderID, navigate, createOrCancel, setDisplayButton, setStatus, placePayload,setTaxAmount,setTotalAmountWithTax,setOrderID, setCGST, setSGST) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${ORDERS_LIST_API}/${orderID}`, 'PATCH', jsonObj)
        if(response===''){
            toast.error("An error occurred. Please try again later.");
            dispatch(setSuccessMessage(false))
        }
        else if (!response.error) {
            if (createOrCancel === "Create") {
                setLoading(false)
                if (placePayload?.state !== 'Acknowledged') {
                    setLoading(false)
                    setDisplayButton(true)
                    setStatus("Pending")
                    navigate('/orders')
                    dispatch(setSuccessMessage(true))
                    dispatch(updateOrderStatus(response.state));
                    dispatch(fetchPlaceOrderSuccess(response));
                } else {
                    setOrderID(response.id)
                    setTaxAmount(response?.orderTotalPrice[0]?.price?.taxIncludedAmount?.value - response?.orderTotalPrice[0]?.price?.dutyFreeAmount?.value || 0)
                    setTotalAmountWithTax(response?.orderTotalPrice[0]?.price?.taxIncludedAmount?.value ||response?.orderTotalPrice[0]?.price?.dutyFreeAmount?.value)
                    setCGST(response?.orderTotalPrice[0]?.price?.countryGST || 0)
                    setSGST(response?.orderTotalPrice[0]?.price?.stateGST || 0)
                    setStatus("Acknowledged")
                    dispatch(setSuccessMessage(true))
                    dispatch(updateOrderStatus(response.state));
                    setDisplayButton(true)
                }
            }
            if(createOrCancel === "Cancel") {
                setLoading(false)
                navigate('/orders')
                dispatch(setDeleteSuccessMessage(true))
                dispatch(fetchPlaceOrderSuccess(response));
            }
        }
        else {
            setLoading(false)
            dispatch(fetchPlaceOrderFailure(response.error.message));
            toast.error(`Failed to create order: ${response.error.message}`);
        }
    } catch (error) {
        setLoading(false);
         toast.error("An error occurred. Please try again later.");

    }
}