import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import {  QUOTA_MANAGEMENT_API } from '../../constants/constants'
import { fetchQuotaByEventFailure, fetchQuotaByEventSuccess } from "./reducer";

export const getQuotaByEvent = (setLoading,usageEventtype,initialValues,ValueTypeOptions) => (dispatch) => {
    setLoading(true);
    determineBaseUrlAndPerformApiCall(`${QUOTA_MANAGEMENT_API}/${usageEventtype}`, 'GET')
        .then((response) => {
            setLoading(false);
            dispatch(fetchQuotaByEventSuccess(response));
            initialValues.valuetype = ValueTypeOptions.find(attr => attr.name === 'valuetype')?.options?.find(option => option.value === response.valueType);
            initialValues.name = response.name
        })
    .catch ((errorResp) => { dispatch(fetchQuotaByEventFailure(errorResp.message)); })
}