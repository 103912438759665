import { MaterialReactTable } from "material-react-table"
import { taskColumns } from "./constants/taskColumns"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { MenuItem } from '@mui/material';
import { customerIdData } from "./constants/constantFunctionsView";
import { useEffect, useState } from "react";
import { DetailedTaskView } from "./DetailedTaskView";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatisticsOfTasks } from "../../slices/tasks/getstatistics/thunk";
import { Loader } from "../Utils/Loader";
import { fetchAllTasks } from "../../slices/tasks/getalltasks/thunk";
import { useColumnSettings } from "../Utils/useColumnSettings";
import { getView } from "../../slices/View/thunk";
import { fetchOperatorSpecificConfigurations } from "../../slices/OperatorManagement/GetConfigurationByOperatorId/thunk";

export const ViewTasks = ({ setView, setTaskDetails, view }) => {
    const permissions = useSelector((state) => state.permissions.permissions);
    const taskPermission = permissions.find((resource) => resource.resourceName === 'task');
    const [viewTask, setViewTask] = useState(false)
    const [viewGlobalTaskDetails, setViewGlobalTaskDetails] = useState(false)
    const [loading, setLoading] = useState(false)

    const taskPath = customerIdData()
    const dispatch = useDispatch()
    const operatorId = localStorage.getItem("operatorId")
    const configData = useSelector((state) => state.OperatorSpecificConfig.OperatorSpecificConfiguration)

    useEffect(() => {
        if (taskPath === "tasks") {
            taskPermission && dispatch(fetchStatisticsOfTasks(setLoading))
            taskPermission && dispatch(fetchAllTasks(setLoading))
            dispatch(getView(setLoading));
        }
        else {
            taskPermission && dispatch(fetchAllTasks(setLoading, taskPath))
        }
        dispatch(fetchOperatorSpecificConfigurations(setLoading, operatorId))
    }, [dispatch])

    const statData = useSelector((state) => state.GetStatisticsOfTasks.getTaskStatistics)
    const taskData = useSelector((state) => state.GetAllTasks.getAllTasks)
    const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)

    const isEIPEnabled = operatorConfiguration?.configurations
        ?.find(config => config.name === "EIP")
        ?.value?.find(item => item.key === "Enabled")
        ?.value?.[0] === "True";
        
    function transformTaskTypes(tasks, isEIPEnabled) {
        if (!isEIPEnabled) return tasks;

        return tasks.map(task => {
            return {
                ...task,
                taskType: task.taskType === "PURCHASE_PLAN" ? "DEVICE_ACTIVATION" :
                    task.taskType === "UPDATE_CUSTOMER" ? "UPDATE_DEVICE" :
                        task.taskType === "CREATE_CUSTOMER" ? "CREATE_DEVICE" : task.taskType
            };
        });
    }
    const updatedTasks = transformTaskTypes(taskData, isEIPEnabled);

    const columns = taskColumns(taskPath)

    const {
        settingsModalOpen,
        viewColumns,
        toggleSettingsModal,
        SettingsSidebar
    } = useColumnSettings('task', columns);


    return (

        <div className={taskPath === "tasks" && "page-content"}>
            {taskPath === "tasks" && <Row className="gy-4">
                <Col lg={12}>
                    {loading && <Loader />}
                    <Row>
                        <Col xxl={4}>
                            <Card className="border-for-task-card">
                                <CardBody>
                                    <div className="d-flex justify-content-center align-items-center p-3">
                                        <div className="flex-column text-center">
                                            <h4>Failed Tasks MTD</h4>
                                            <h1 className="mb-0">{statData[0]?.failedTasksMTD}</h1>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xxl={4}>
                            <Card className="border-for-task-card">
                                <CardBody>
                                    <div className="d-flex justify-content-center align-items-center p-3">
                                        <div className="flex-column text-center">
                                            <h4>Failed Internal Tasks MTD</h4>
                                            <h1 className="mb-0">{statData[0]?.failedInternalTasksMTD}</h1>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xxl={4}>
                            <Card className="border-for-task-card">
                                <CardBody>
                                    <div className="d-flex justify-content-center align-items-center p-3">
                                        <div className="flex-column text-center">
                                            <h4>Failed Provisional Tasks MTD</h4>
                                            <h1 className="mb-0">{statData[0]?.failedProvisionalTasksMTD}</h1>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>}
            <Row>
                <Col lg={settingsModalOpen ? 10 : 12}>
                    <Card>
                        <CardBody>
                            <Row className="table-responsive">
                                {((taskPath !== "tasks") || (taskPath === "tasks" && !viewTask)) && <MaterialReactTable data={updatedTasks} columns={taskPath === "tasks" ? columns.filter((column) => viewColumns?.includes(column.accessorKey || column.header)) : columns} enableDensityToggle={false}
                                    enableColumnOrdering
                                    manualFiltering
                                    enableColumnDragging
                                    enableRowActions
                                    icons={{
                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: {
                                            fontFamily: "Inter,sans-serif"
                                        }
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontFamily: "Inter,sans-serif"
                                        }
                                    }}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            header: '',
                                            enableHiding: true
                                        },
                                    }}
                                    initialState={{
                                        columnVisibility: {
                                            'taskId': false,
                                        },
                                    }}
                                    renderTopToolbarCustomActions={({ }) => (
                                        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                                            {taskPath === "tasks" && <><div className="d-flex flex-column flex-md-row">
                                            </div>
                                                <div className="d-flex align-items-center">
                                                    <div color="secondary" className="btn-icon me-2 cursor-img" onClick={toggleSettingsModal}>
                                                        <i className="ri-settings-2-line fs-4"></i>
                                                    </div>
                                                </div></>}
                                        </div>
                                    )}
                                    renderRowActionMenuItems={({ closeMenu, row }) =>
                                        [
                                            <MenuItem key="view" onClick={() => {
                                                if (taskPath !== "tasks") {
                                                    setTaskDetails(row.original)
                                                    setView(true)
                                                }
                                                else {
                                                    setViewGlobalTaskDetails(row.original)
                                                    setViewTask(true)
                                                }
                                                closeMenu()

                                            }}>
                                                <i className="ri-eye-line me-2"></i>
                                                View
                                            </MenuItem>
                                        ]
                                    }
                                />}
                            </Row>
                            {viewTask && <DetailedTaskView configurations={configData} taskDetails={viewGlobalTaskDetails} />}
                        </CardBody>
                    </Card>
                </Col>
                {SettingsSidebar()}
            </Row>
            {(viewTask) && <Row lg={6} className=" justify-content-end mb-4">
                <div className="d-flex justify-content-end gap-2 ">
                    <CommonButton color={"main-color"} type={'submit'} buttonAction={() => {
                        if (viewTask) {
                            setViewTask(false)
                        }
                    }} buttonText={"Back"} />
                </div>
            </Row>}
        </div>
    )
}