import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../Utils/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { setEditSuccessMessage, setSuccessMessage } from '../../slices/toastMessage/action';
import { MenuItem } from "@mui/material";
import CommonModal from "../Utils/CommonModal";
import { QuotaForm } from './QuotaForm';
import { getAllQuota } from '../../slices/QuotaManagement/thunk';
import { deleteQuota } from '../../slices/QuotaManagement/DeleteQuota/thunk';
import { fetchAllOffers } from '../../slices/offer/thunk';
import { handleExportData } from '../Utils/ExportToCsv';
import { getQuotaByEvent } from '../../slices/QuotaManagement/GetQuota/thunk';

function QuotaManagementListing() {
    const [loading, setLoading] = useState(false);
    const [messageForPopUp, setMessageForPopUp] = useState('');
    const [eventType, setEventType] = useState("")
    const [selectedQuota, setSelectedQuota] = useState(null);   
    const [isEditMode, setIsEditMode] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
    const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage);
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const uom = useSelector((state) => state.Uom.uom);
    const permissions = useSelector((state) => state.permissions.permissions);
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModals = () => {
        setModalOpen(prev => !prev);
    };

    const uomPermission = permissions.find((resource) => resource.resourceName === 'uom');
    const columns = [
        {
            accessorKey: 'usageEventType',
            header: 'Usage Event Type',
        },
        {
            accessorKey: 'UOM',
            header: 'UOM',
        },
        {
            accessorKey: 'initialQuota',
            header: 'Initial Quota',
        },
        {
            accessorKey: 'incrementalQuota',
            header: 'Incremental Quota',
        },
        {
            accessorKey: 'validityTime',
            header: 'Validity',
        },
    ];
    useEffect(() => {
        dispatch(getAllQuota(setLoading))
        dispatch(fetchAllOffers(setLoading));
    }, [dispatch]);

    const quota = useSelector((state) => state.GetAllQuota.quota)
    const offers = useSelector((state) => state.Offer?.offer || []);
    const quotaByEventType = useSelector((state) => state.GetQuotaByEvent.QuotaByEvent);
    useEffect(() => {
        if (isEditMode && quotaByEventType) {
            setSelectedQuota(prev => ({ ...prev, ...quotaByEventType })); 
        }
    }, [quotaByEventType]);
    const extractedData = Array.from(
        new Map(
            offers.flatMap(offer =>
                offer.usageRatePlan?.map(({ eventType, uomName }) => ({
                    eventType,
                    uom: uomName
                })) || []
            ).map(obj => [`${obj.eventType}-${obj.uom}`, obj])
        ).values()
    );
    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Quota Created Successfully`);
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success(`Quota Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch]);

    const toggleModal = (id, singleOrMulti) => {
        if (singleOrMulti === "single") {
            setEventType(id);
            setModal(!modal);
        }
        document.body.style.overflow = 'auto';
    };
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title="Quota Management" pageTitle="Charging" />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col >
                            <div className="listingjs-table" id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {uom ?
                                                <>
                                                    <MaterialReactTable
                                                        icons={{
                                                            DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                        }}

                                                        positionToolbarAlertBanner='none'
                                                        renderTopToolbarCustomActions={({ table }) => (
                                                            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                                                                <div className="d-flex flex-column flex-md-row">
                                                                    <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(quota) }}>
                                                                        <i className="ri-upload-line label-icon align-middle fs-16 me-2"></i> Export Data
                                                                    </Button>
                                                                    {uomPermission && uomPermission.permissionList.includes('create') &&
                                                                        <Button
                                                                            color="secondary"
                                                                            className="btn-label me-2 mb-2 mb-md-0"
                                                                            onClick={() => {
                                                                                setModalOpen(true);
                                                                                setIsEditMode(false);
                                                                            }}
                                                                        >
                                                                            <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Quota
                                                                        </Button>}
                                                                </div>
                                                            </div>
                                                        )}
                                                        initialState={{
                                                            columnOrder: [
                                                                'mrt-row-actions',
                                                                'Name',
                                                                'Value Type'
                                                            ]
                                                        }}
                                                        columns={columns}
                                                        data={quota}
                                                        enableColumnOrdering
                                                        enableColumnDragging
                                                        enableRowActions={true}
                                                        displayColumnDefOptions={{
                                                            'mrt-row-actions': {
                                                                header: '',
                                                                enableHiding: false,
                                                            },
                                                        }}
                                                        defaultColumn={{
                                                            minSize: 1,
                                                            maxSize: 9001,
                                                            muiTableBodyCellProps: {
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif"
                                                                }
                                                            },
                                                            muiTableHeadCellProps: {
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif"
                                                                }
                                                            }
                                                        }}
                                                        layoutMode='grid'
                                                        renderRowActionMenuItems={({ closeMenu, row }) => [
                                                            <MenuItem className='row-options' key={`${row.index}.2`} onClick={() => {
                                                                setIsEditMode(true);  
                                                                setSelectedQuota(row.original); 
                                                                setModalOpen(true);  
                                                                dispatch(getQuotaByEvent(setLoading, row.original.usageEventType));
                                                                closeMenu();
                                                            }}><i className="bx bxs-edit me-2" /> Edit</MenuItem>,
                                                            <MenuItem className='row-options'
                                                                key="delete"
                                                                onClick={() => {
                                                                    setMessageForPopUp(`Are you sure you want to Delete this Usage Event Type "${row.original.usageEventType}"?`)
                                                                    toggleModal(row, "single");
                                                                    closeMenu();
                                                                }}
                                                            >
                                                                <i className="ri ri-delete-bin-5-line me-2" /> Delete
                                                            </MenuItem>,
                                                        ]}
                                                        enableDensityToggle={false}
                                                    />
                                                </>
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <CommonModal messageForPopUp={messageForPopUp} toggle={() => { if (modal) { toggleModal(eventType, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }
                } open={modalForBulkDelete ? modalForBulkDelete : modal} buttonText={"Yes, Delete It"} modalAction={() => {
                    if (modal) {
                        dispatch(deleteQuota(eventType.original.usageEventType, setLoading, navigate));
                        toggleModal(eventType, "single");
                    }
                }} />
                <Modal isOpen={modalOpen} toggle={toggleModals} centered size="md" className="custom-modal">
                    <button type="button" className="close-button-currency mt-n1" onClick={toggleModals}>
                        &times;
                    </button>
                    <h5 className="text-center w-100 mt-0 fw-semibold py-3 bg-pop-header">
                        {isEditMode ? "Edit Quota" : "Add Quota"}
                    </h5>
                    <ModalBody>
                        <QuotaForm extractedData={extractedData} toggleModals={toggleModals} setModalOpen={setModalOpen} initialData={isEditMode ? selectedQuota : null} isEditMode={isEditMode} />
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default QuotaManagementListing;
