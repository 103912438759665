import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    addQuota : [],
    loading : false,
    error : null,
};

const addQuotaSlice = createSlice({
    name:"addQuota",
    initialState,
    reducers: {
        fetchAddQuotaSuccess: (state,action) => {
            state.loading = false;
            state.addQuota = action.payload;
        },
        fetchAddQuotaFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchAddQuotaSuccess,
    fetchAddQuotaFailure
} = addQuotaSlice.actions

export default addQuotaSlice.reducer