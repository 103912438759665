import { FieldArray } from "formik";
import { CardHeader, CardBody, Button, Row, Col, FormGroup, Input, UncontrolledTooltip, Label } from "reactstrap";
import SelectField from "../../Utils/SelectField";
import InputField from "../../Utils/InputField";
import { LabelText } from "../../Utils/Input";
import { Link } from "react-router-dom";
import { removeRowsFromGrantsAndCharges } from "./constants/checkEmptyFields";
import { grantsAndChargesHandle, handleGrantsAndChargesRecurringFrequencyAndUsageOptions, handleGrantsAndChargesResourceValueAndUsageFields, handleInfiniteForGrantsAndCharges } from "./constants/GrantsAndRecurringFunctions";
import { CycleAlignmentOption, GrantsValidityEnd, SubscriptionEndsOnOptions, SubscriptionStartsOnOptions, TimePeriodGrantOptions, ValidityStartsOnOptions, recurringGrantFrequencyOptions } from "./constants/initialValues";
import { handleGrantsRecurringOverride, handleRolloverPriorityState, handleRolloverState } from "./constants/displayInfinite";

export default function GrantsRecurring({
    values,
    errors, isEdit, isSubscribed,
    recurringFrequencyOptions,
    handleProrateState,
    touched,
    setTouched,
    handleChange,
    prorateState,
    overrideStateRecurring,
    resourceData,
    grantResourceOptions,
    setFieldValue,
    setFieldTouched,
    infiniteOptions, disableValidityInGrantRecurring, setDisableValidityInGrantRecurring,
    isEmptyorInvalidFieldForGrantsAndCharges, isCreate
}) {
    return (
        <FieldArray name="recurringGrants">
            {({ push, remove }) => (
                <>
                    <CardHeader className="d-flex justify-content-center align-items-center">
                        <h4 className="card-title mb-0 flex-grow-1 me-2">New Grant</h4>
                        {!isSubscribed && (
                            <Button color="secondary" className="btn-label" onClick={() => {
                                const lastIndex = values.recurringGrants.length - 1;
                                if (lastIndex === -1 || !isEmptyorInvalidFieldForGrantsAndCharges(lastIndex, values.recurringGrants)) {
                                    push({ recurringFrequency: recurringFrequencyOptions[0], resourceType: '', resourceValue: "0", infinite: '', ceiling: '', floor: '', grantValidityEndsOnTimeperiod: '', type: "2", override:overrideStateRecurring, prorate: prorateState, validityStartsOn: '', validityEndsOn: '', cycleAlignment: '', rollover:false , rolloverModel: {rolloverAmount:"0", rolloverCycle:"0", rolloverPriority:false}});
                                    setTouched({
                                        ...touched,
                                        recurringGrants: {
                                            ...touched.recurringGrants,
                                            [lastIndex]: {
                                                resourceType: false,
                                            },
                                        },
                                    });
                                }
                            }}>
                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Grants
                            </Button>
                        )}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            {values.recurringGrants.length === 0 ? (
                                <div className="text-center">No Grants</div>
                            ) : (
                                values.recurringGrants.map((val, index) => (

                                    <div key={index} className="border border-black p-4 rounded mb-3">
                                        <div className=" d-flex justify-content-between align-items-center ">
                                            <h4>Grant {index + 1}</h4>
                                            {!isEdit && (
                                                <Link to="#" className="link-danger ms-5 " onClick={() => {
                                                    remove(index);
                                                }}>
                                                    <i data-testid="delete" className="ri-delete-bin-5-line fs-5"></i>
                                                </Link>
                                            )}
                                        </div>
                                        <hr />
                                        <Row className="mb-3">
                                            <Col lg={4}>
                                                <FormGroup>
                                                    <Label htmlFor={`recurringGrants[${index}].recurringFrequency`}>Recurring Frequency</Label>
                                                    <SelectField
                                                        name={`recurringGrants[${index}].recurringFrequency`}
                                                        value={values.recurringGrants[index].recurringFrequency}
                                                        isDisabled={isSubscribed}
                                                        handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.recurringGrants[index].recurringFrequency, `recurringGrants[${index}].recurringFrequency`, setFieldValue, setFieldTouched)}
                                                        options={recurringGrantFrequencyOptions}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12} lg={4} className="mt-0">
                                                <Label for={`recurringGrants[${index}].cycleAlignment`}>Cycle Alignment<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    className={(touched.recurringGrants && touched.recurringGrants[index] && !values.recurringGrants[index].cycleAlignment) ? 'error-input' : ''}
                                                    name={`recurringGrants[${index}].cycleAlignment`}
                                                    isDisabled={isSubscribed}
                                                    value={values.recurringGrants[index].cycleAlignment}
                                                    handleChange={(value) => {
                                                        setFieldValue(`recurringGrants[${index}].cycleAlignment`, value);
                                                    }}
                                                    options={CycleAlignmentOption}
                                                />
                                                {errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index].cycleAlignment ? (
                                                    <p className="text-danger">{errors.recurringGrants[index].cycleAlignment}</p>
                                                ) : null}
                                            </Col>
                                            <Col lg={4}>
                                                <FormGroup>
                                                    <Label htmlFor={`recurringGrants[${index}].resourceType`}>Resource Type<span className="text-danger"> *</span></Label>
                                                    <SelectField
                                                        className={(touched.recurringGrants && touched.recurringGrants[index] && !values.recurringGrants[index].resourceType) ? 'error-input' : ''}
                                                        name={`recurringGrants[${index}].resourceType`}
                                                        isDisabled={isSubscribed}
                                                        value={values.recurringGrants[index].resourceType}
                                                        handleChange={grantsAndChargesHandle(`recurringGrants[${index}].resourceType`, values.recurringGrants[index], setFieldValue, setFieldTouched, resourceData, setDisableValidityInGrantRecurring, index)}
                                                        options={grantResourceOptions}
                                                    />
                                                    {errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index].resourceType ? (
                                                        <p className="text-danger">{errors.recurringGrants[index].resourceType}</p>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4}>
                                                <FormGroup>
                                                    <Label htmlFor={`recurringGrants[${index}].resourceValue`}>Resource Value<span className="text-danger"> *</span></Label>
                                                    <InputField
                                                        name={`recurringGrants[${index}].resourceValue`}
                                                        className="form-control"
                                                        placeholder="Enter Amount"
                                                        type="number"
                                                        handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                        value={values.recurringGrants[index].resourceValue}
                                                        invalid={errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index].resourceValue && errors.recurringGrants[index].resourceValue ? true : false}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={4}>
                                                <FormGroup>
                                                    <Label>Type of Resource</Label>
                                                    <InputField
                                                        className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                        value="Non-Currency"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                                {values.recurringGrants[index].infinite && (
                                                    <Col lg={4}>
                                                        <FormGroup>
                                                            <Label htmlFor={`recurringGrants[${index}].infinite`}>Infinite</Label>
                                                            <SelectField
                                                                name={`recurringGrants[${index}].infinite`}
                                                                value={values.recurringGrants[index].infinite}
                                                                isDisabled={isSubscribed}
                                                                handleChange={handleInfiniteForGrantsAndCharges(`recurringGrants[${index}].infinite`, values.recurringGrants[index], setFieldValue)}
                                                                options={infiniteOptions}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                                {values.recurringGrants[index].ceiling ? (
                                                    <Col lg={4}>
                                                    <FormGroup>
                                                        <Label htmlFor={`recurringGrants[${index}].ceiling`}>Ceiling</Label>
                                                        <InputField
                                                            className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                            value={values.recurringGrants[index].ceiling}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                    </Col>
                                                ) : null}
                                                {values.recurringGrants[index].floor ? (
                                                    <Col lg={4}>
                                                    <FormGroup>
                                                        <Label htmlFor={`recurringGrants[${index}].floor`}>Floor</Label>
                                                        <InputField
                                                            className={` ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                                                            value={values.recurringGrants[index].floor}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                    </Col>
                                                ) : null}
                                            <Col lg={2}>
                                                <Label>Prorate</Label>
                                                <FormGroup switch className="d-flex align-items-start switch-state">
                                                    <div className="me-2">
                                                        <Input
                                                            type="switch"
                                                            disabled={isEdit}
                                                            checked={values.recurringGrants[index].prorate}
                                                            onChange={() => handleProrateState(index, values, setFieldValue)}
                                                        />
                                                    </div>
                                                    <div className="flex-column">
                                                        <Label id="UncontrolledTooltipExample" htmlFor="globalOffer" className="text-danger" displayText="Prorate" check />
                                                        <UncontrolledTooltip target="UncontrolledTooltipExample">Enable rating user based on their service consumption</UncontrolledTooltip>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2}>
                                                <Label>Override</Label>
                                                <FormGroup switch className="d-flex align-items-start switch-state">
                                                    <div className="me-2">
                                                        <Input
                                                            type="switch"
                                                            disabled={isEdit}
                                                            checked={values.recurringGrants[index].override}
                                                            onChange={() => handleGrantsRecurringOverride(index, values, setFieldValue)}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12} lg={4} className="mt-0">
                                                <Label for={`recurringGrants[${index}].validityStartsOn`}>Validity Starts<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    className={(touched.recurringGrants && touched.recurringGrants[index] && !values.recurringGrants[index].validityStartsOn) && !disableValidityInGrantRecurring[index] ? 'error-input' : ''}
                                                    name={`recurringGrants[${index}].validityStartsOn`}
                                                    isDisabled={isSubscribed}
                                                    value={values.recurringGrants[index].validityStartsOn}
                                                    handleChange={(value) => {
                                                        setFieldValue(`recurringGrants[${index}].validityStartsOn`, value);
                                                    }}
                                                    options={ValidityStartsOnOptions}
                                                />
                                                {errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index].validityStartsOn && !disableValidityInGrantRecurring[index] ? (
                                                    <p className="text-danger">{errors.recurringGrants[index].validityStartsOn}</p>
                                                ) : null}
                                            </Col>
                                            <Col md={12} lg={4} className="mt-0">
                                                <Label for={`recurringGrants[${index}].validityEndsOn`}>Validity Ends<span className="text-danger"> *</span></Label>
                                                <SelectField
                                                    className={(touched.recurringGrants && touched.recurringGrants[index] && !values.recurringGrants[index].validityEndsOn) && !disableValidityInGrantRecurring[index] ? 'error-input' : ''}
                                                    name={`recurringGrants[${index}].validityEndsOn`}
                                                    isDisabled={isSubscribed}
                                                    value={values.recurringGrants[index].validityEndsOn}
                                                    handleChange={(value) => {
                                                        setFieldValue(`recurringGrants[${index}].validityEndsOn`, value);
                                                    }}
                                                    options={GrantsValidityEnd.filter((_, idx) => idx !== 1)}
                                                />
                                                {errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index].validityEndsOn && !disableValidityInGrantRecurring[index] ? (
                                                    <p className="text-danger">{errors.recurringGrants[index].validityEndsOn}</p>
                                                ) : null}
                                            </Col>
                                            {values.recurringGrants[index].validityEndsOn?.label === "Relative" && (
                                                <>
                                                    <Col xxl={2} md={6} >
                                                        <Label>Time Period</Label>
                                                        <InputField name={`recurringGrants[${index}].grantValidityEndsOnTimeperiod`} disabled={isSubscribed} type="number" className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`} id="grantValidityEndsOnTimeperiod" handleChange={handleChange} value={values.grantValidityEndsOnTimeperiod} />
                                                    </Col>
                                                    <Col xxl={2} md={6} className="period-option">
                                                        <div data-testid="select-component">
                                                            <SelectField
                                                                name={`recurringGrants[${index}].grantValidityEndsOn`} isDisabled={isSubscribed}
                                                                value={values.recurringGrants[index].grantValidityEndsOn || ""}
                                                                handleChange={(val) => {
                                                                    values.recurringGrants[index].grantValidityEndsOn = val;
                                                                    setFieldValue(`recurringGrants[${index}].grantValidityEndsOn`, val);
                                                                }}
                                                                options={TimePeriodGrantOptions}
                                                            />
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                            <>
                                            <Col lg={2}>
                                                <Label>Rollover</Label>
                                                <FormGroup switch className="d-flex align-items-start switch-state">
                                                    <div className="me-2">
                                                        <Input
                                                            type="switch"
                                                            checked={values.recurringGrants[index].rollover}
                                                            onChange={() => handleRolloverState(index, values, setFieldValue)}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            {values.recurringGrants[index].rollover && (
                                                <>
                                                <Col lg={2}>
                                                        <Label>Rollover Priority</Label>
                                                        <FormGroup switch className="d-flex align-items-start switch-state">
                                                            <div>
                                                                <Input
                                                                    type="switch"
                                                                    checked={values.recurringGrants[index].rolloverModel?.rolloverPriority}
                                                                    onChange={() => handleRolloverPriorityState(index, values, setFieldValue)}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={4} className="mt-3">
                                                        <FormGroup>
                                                            <Label htmlFor={`recurringGrants[${index}].rolloverModel.rolloverAmount`}>Maximum Rollover Amount</Label>
                                                            {values.recurringGrants[index]?.rollover &&
                                                                <InputField
                                                                    name={`recurringGrants[${index}].rolloverModel.rolloverAmount`}
                                                                    className="form-control"
                                                                    placeholder="Enter Rollover Amount"
                                                                    type="number"
                                                                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                                    value={values.recurringGrants[index].rolloverModel.rolloverAmount}
                                                                    invalid={errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index]?.rolloverModel?.rolloverAmount && errors.recurringGrants[index]?.rolloverModel?.rolloverAmount ? true : false}
                                                                />
                                                            }
                                                            {errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index]?.rolloverModel?.rolloverAmount  ? (
                                                                <p className="text-danger">{errors.recurringGrants[index]?.rolloverModel?.rolloverAmount}</p>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={4} className="me-2 mt-3">
                                                        <FormGroup>
                                                            <Label htmlFor={`recurringGrants[${index}].rolloverModel.rolloverCycle`}>Maximum Rollover Cycle</Label>
                                                            {values.recurringGrants[index]?.rolloverModel &&
                                                                <InputField
                                                                    name={`recurringGrants[${index}].rolloverModel.rolloverCycle`}
                                                                    className={'form-control'}
                                                                    placeholder="Enter Rollover Cycle"
                                                                    type="number"
                                                                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                                    value={values.recurringGrants[index]?.rolloverModel?.rolloverCycle}
                                                                    invalid={errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index]?.rolloverModel?.rolloverCycle && errors.recurringGrants[index]?.rolloverModel?.rolloverCycle ? true : false}
                                                                />
                                                            }
                                                            {errors.recurringGrants && errors.recurringGrants[index] && touched.recurringGrants && touched.recurringGrants[index] && touched.recurringGrants[index]?.rolloverModel?.rolloverCycle  ? (
                                                                <p className="text-danger">{errors.recurringGrants[index]?.rolloverModel?.rolloverCycle}</p>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </>
                                            )}
                                            </>
                                        </Row>
                                    </div>
                                ))
                            )}
                        </div>
                    </CardBody>
                </>
            )}
        </FieldArray>
    );
}