import { toast } from "react-toastify"
import { INITIATE_SESSION } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchInitiateSessionFailure, fetchInitiateSessionSuccess } from "./reducer"
import { fetchAllSessions } from "../getAllSessions/thunk"
import { fetchDetailedBalance } from "../../customer/detailedBalance/thunk"
import { fetchCustomerSummary } from "../../customer/simulate/thunk"

export const initiateSession = (jsonObj, setLoading,navigate,customerId,fullName,setShowForm,isView) => async (dispatch) => {
    await determineBaseUrlAndPerformApiCall(`${INITIATE_SESSION}`, 'POST',jsonObj).then((response) => {
        setLoading(true)
        if (response?.error) {
            setLoading(false)
           
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchInitiateSessionFailure(response.error.message))
        }
        if (response?.resultCode === "Failure") {
            setLoading(false);
            toast.error(response?.reasonCode);
            return;
        }
        else if (!response?.error) {
            setLoading(false)
            if(isView){
            toast.success("Session Initiated Successfully")
            dispatch(fetchAllSessions(customerId));
            dispatch(fetchDetailedBalance(response?.subscriptionId, setLoading, false))
            setShowForm(false)
            navigate(`/view-customer/${customerId}`)
            dispatch(fetchCustomerSummary(customerId, setLoading, "View"));

            }else{
                navigate(`/sessions/${response?.subscriptionId}`,{state:{customerId:customerId, fullName:fullName}})  

            }
            dispatch(fetchInitiateSessionSuccess(response))
           
        }
    })
}