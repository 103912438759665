import { DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API } from "../constants/constants";
import { resetDocumentViewState } from "../customer/documentview/reducer";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { fetchlogoViewFailure, fetchlogoViewSuccess } from "./reducer";

export const logoViewDoc = (setLoading, customerId, documentName, partnerOrCustomer,setProfileData) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API}/${customerId}/${documentName}`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            setProfileData(true)
            dispatch(fetchlogoViewSuccess(response))
            if ( partnerOrCustomer !== "Operator" && partnerOrCustomer !== "Business") {
                const base64Data = response.filePath;
                fetch(base64Data)
                    .then(res => res.blob())
                    .then((blob_res) => {
                        let fileUrl = URL.createObjectURL(blob_res);
                        window.open(fileUrl)
                    })
            }

        }
        if (response.error) {
            setLoading(false)
            setProfileData(false)
            dispatch(fetchlogoViewFailure(response.error.message))
            dispatch(fetchlogoViewSuccess([]))
            dispatch(resetDocumentViewState());
        }
        setLoading(false)
    }).catch((error) => {
        // setProfileData(false)
        dispatch(resetDocumentViewState())
     })
}