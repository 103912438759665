import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteQuota: [],
    loading: false,
    error: null,
}

const deleteQuotaSlice = createSlice({
    name: "deleteQuota",
    initialState,
    reducers: {
        fetchdeleteQuotaSuccess: (state, action) => {
            state.loading = false,
                state.deleteQuota = action.payload;
        },
        fetchdeleteQuotaFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchdeleteQuotaSuccess,
    fetchdeleteQuotaFailure,
} = deleteQuotaSlice.actions;

export default deleteQuotaSlice.reducer;