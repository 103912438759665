import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap"
import BreadCrumb from "../Common/BreadCrumb"
import CustomerDetails from "./CustomerDetails"
import UploadDocument from "./UploadDocument"
import Attributes from "./Attributes"
import CommonButton from "../Utils/CommonButton"
import { PAGE_TITLES } from "../Common/constants"
import { AttributeNameOptions } from "./constants/constantValues"
import { customerDialCodeErrorThrow } from "./constants/DocumentTableFunctions"
import { NonSearchableAttributes } from "./NonSearchableAttributes"
import { Identifiers } from "./Identifiers"
import { useState } from "react"
import { validateForm } from "./constants/TelcoSubmitFunctions"
import { CustomerCategory } from "./Category"
import { useSelector } from "react-redux"

export const CommonCustomerDetails = ({ setFieldError, isAIPath, searchableFormErrorsValues, setSearchableFormErrorValues, nonSearchableFormErrorsValues, setNonSearchableFormErrorValues, searchableAttributes, nonSearchableAttributes, setNonSearchableValues, searchableValues, nonSearchableValues, setSearchableValues, identifiers, Attributes, title, customerPermission, values, touched, bdomOptions, errors, dialCode, setDialCode, setFieldValue, onfocus, setOnFocus, setFieldTouched, initialValues, handleBlur, handleChange, options, optionCity, optionState, setSelectedCountry, setSelectedState, setSelectedCity, cityData, setCities, isEdit, documentPermission, fetchDocumentData, open, setIsOpen, file, setFile, fileName, setFileName, setState, setTouched, roleName, navigate, isProfile, setIsProfile, profilePicture, setProfilePicture, inputFileRef, dispatch, setLoading, customerId, setUpdate, setFileSelected, documentName }) => {
    const [setAllTouched, setSetAllTouched] = useState(() => () => { });
    const operatorConfiguration = useSelector((state)=>state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)
    
    const isEIPEnabled = operatorConfiguration?.configurations
      ?.find(config => config.name === "EIP")
      ?.value?.find(item => item.key === "Enabled")
      ?.value?.[0] === "True";

    const customerListTitle = isEIPEnabled
        ? PAGE_TITLES.CUSTOMER_LIST_EIP
        : PAGE_TITLES.CUSTOMER_LIST;

        return (
        <Container fluid>
            {((roleName !== "telcoAdmin") || (roleName === "telcoAdmin" && isEdit)) && <BreadCrumb title={title} pageTitle={customerListTitle} />}
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0 flex-grow-1">Primary Contact</h4>
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <CustomerDetails
                                    customerPermission={customerPermission}
                                    values={values}
                                    touched={touched} bdomOptions={bdomOptions}
                                    errors={errors} dialCode={dialCode} setDialCode={setDialCode}
                                    setFieldValue={setFieldValue} onfocus={onfocus} setOnFocus={setOnFocus}
                                    setFieldTouched={setFieldTouched}
                                    initialValues={initialValues}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    options={options}
                                    optionState={optionState}
                                    optionCity={optionCity}
                                    setSelectedCountry={setSelectedCountry}
                                    setSelectedState={setSelectedState}
                                    setSelectedCity={setSelectedCity}
                                    cityData={cityData}
                                    setCities={setCities} isEdit={isEdit}
                                    isProfile={isProfile} setIsProfile={setIsProfile}
                                    profilePicture={profilePicture} setProfilePicture={setProfilePicture}
                                    inputFileRef={inputFileRef}
                                    dispatch={dispatch}
                                    setLoading={setLoading}
                                    customerId={customerId} setUpdate={setUpdate}
                                    setFileSelected={setFileSelected}
                                    documentName={documentName}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <CustomerCategory setFieldError={setFieldError} values={values} errors={errors} touched={touched} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} handleBlur={handleBlur} />
            {documentPermission && <Row>
                <Col lg={12}>
                    <UploadDocument fetchDocumentData={fetchDocumentData} isEdit={isEdit} open={open} setIsOpen={setIsOpen} file={file} setFile={setFile} fileName={fileName} setFileName={setFileName} setState={setState} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} values={values} errors={errors} handleChange={handleChange} handleBlur={handleBlur} touched={touched} />
                </Col>
            </Row>}
            <Identifiers searchableFormErrorsValues={searchableFormErrorsValues} searchableValues={searchableValues} setSearchableValues={setSearchableValues} setSetAllTouched={setSetAllTouched} identifiers={identifiers} roleName={roleName} setFieldValue={setFieldValue} isEdit={isEdit} handleBlur={handleBlur} values={values} touched={touched} errors={errors} handleChange={handleChange} />
            <NonSearchableAttributes isEdit={isEdit} nonSearchableFormErrorsValues={nonSearchableFormErrorsValues} nonSearchableValues={nonSearchableValues} setNonSearchableValues={setNonSearchableValues} Attributes={Attributes} />
            <Row lg={6} className=" justify-content-end mb-4">
                <div className="live-preview">
                    <div className="d-flex justify-content-end gap-2 ">
                        <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/customer')} buttonText={"Cancel"} />
                        <CommonButton color={"main-color"} type={'submit'} buttonAction={() => {
                            customerDialCodeErrorThrow(onfocus, dialCode, setOnFocus)
                            const searchableFormErrors = validateForm(searchableAttributes, searchableValues);
                            setSearchableFormErrorValues(searchableFormErrors);
                            const nonSearchableFormErrors = validateForm(nonSearchableAttributes, nonSearchableValues);
                            setNonSearchableFormErrorValues(nonSearchableFormErrors);
                        }} buttonText={((roleName !== "telcoAdmin" && roleName !== "telcoOperationUser" && roleName !== "telcoBusinessUser") || ((roleName === "telcoAdmin" || roleName === "telcoOperationUser" || roleName === "telcoBusinessUser") && isEdit) || isAIPath) ? "Confirm" : "Next"} />
                    </div>
                </div>
            </Row>
        </Container>
    )
}