import { toast } from "react-toastify"
import { createCustomer } from "../../../slices/customer/create/thunk"
import { deleteDoc } from "../../../slices/customer/documentdelete/thunk"
import { updateCustomer } from "../../../slices/customer/update/thunk"
import { customerJsonObj, subscriptionsJsonObj } from "./CustomerJsonObj"
import { approveDocument, editDocument, rejectDocument, submitDocument, toggleModal, verifyDocumentApiCall } from "./HandleChangeFunctions"
import { cancelSubscription } from "../../../slices/customer/cancelsubscription/thunk"
import { isValidPhoneNumber } from "react-phone-number-input"
import { resetPreview } from "../../../slices/orderManagement/previewOrder/reducer"

export const handleEditedDocumentName = (setEditedDocumentName) => (e) => {
    setEditedDocumentName(e.target.value)
}

export const handleEditedDocumentType = (setEditedDocumentType) => (selectedOption) => {
    setEditedDocumentType(selectedOption)
}

export const callEditDocument = (customerId, documentName, editedDocumentName, editedDocumentType, dispatch, setLoading, setFields, fields) => {
    editDocument(customerId, documentName, editedDocumentName, editedDocumentType, dispatch, setLoading)
    setFields(!fields)
}

export const dontCallEditDocument = (setFields, fields) => {
    setFields(!fields)
}

export const approveButtonFunction = (setIsOpen, setButtonText, setMessageForPopUp, customerId, documentName, approvePayload) => {
    setIsOpen(true)
    setButtonText('Yes, Approve It')
    setMessageForPopUp(`Are you sure you want to Approve this document '${documentName}'?`)
    approveDocument(customerId, documentName, approvePayload)
}

export const rejectButtonFunction = (setIsOpen, setButtonText, setMessageForPopUp, customerId, documentName, rejectPayload) => {
    setIsOpen(true)
    setButtonText('Yes, Reject It')
    setMessageForPopUp(`Are you sure you want to Reject this document '${documentName}'?`)
    rejectDocument(customerId, documentName, rejectPayload)
}

export const uploadSubmitFunction = (values, file, customerId, dispatch, setLoading, setFieldEmpty, setFieldValue, setState, setFieldTouched) => {
    if (values.selectedFile !== "" && values.documentName !== "" && values.documentType !== "") {
        submitDocument(file, values, customerId, dispatch, setLoading)
        setFieldEmpty(setFieldValue, setState, setFieldTouched)
    }
    else {
        toast.error("Please fill the fields under Documents Section")
    }
}

export const clearDocFunction = (emptyFieldsOfDocuments, setFieldValue) => {
    emptyFieldsOfDocuments(setFieldValue)
}

export const displayVerifyModal = (buttonText, dispatch, payload, setLoading, customerId, setIsOpen, open) => {
    if (buttonText.includes("Approve") || buttonText.includes("Reject")) {
        verifyDocumentApiCall(dispatch, payload, setLoading, customerId);
        toggleModal(setIsOpen, open)
    } else {
        dispatch(deleteDoc(setLoading, payload, customerId));
        toggleModal(setIsOpen, open)
    }
}

export const registerOrEditCustomer = async (jsonObj, setLoading, isEdit, dispatch, customerId, navigate,profilePicture,fileSelected,setFileSelected,update,setUpdate,profile,isProfile,editData) => {
    setLoading(true)
    if (isEdit) {
        dispatch(updateCustomer(jsonObj, customerId, setLoading, navigate,fileSelected,setFileSelected,update,setUpdate,profile,profilePicture,isProfile,editData))
    }
    else {
        dispatch(createCustomer(jsonObj, setLoading, navigate,profilePicture))
    }
}

export const onSubmit = (values, isEdit, subscriptionsLength, setLoading, dispatch, customerId, navigate, dialCode,roleName,searchableValues,nonSearchableValues,quantity,profilePicture,fileSelected,setFileSelected,update,setUpdate,profile,isProfile,editData, startsOn,endsOn,selectedOfferCode,overrideResource,setOverrideResource) => {
    let jsonObj = customerJsonObj(values, isEdit, subscriptionsLength, dialCode,roleName,searchableValues,nonSearchableValues,startsOn,endsOn,selectedOfferCode,quantity,overrideResource,setOverrideResource)
   registerOrEditCustomer(jsonObj, setLoading, isEdit, dispatch, customerId, navigate,profilePicture,fileSelected,setFileSelected,update,setUpdate,profile,isProfile,editData)
};

export const onSubmitCustomerFunction = (values,isEdit, subscriptionsLength, setLoading, dispatch, customerId, navigate, dialCode,roleName,searchableValues,nonSearchableValues,quantity,profilePicture,fileSelected,setFileSelected,update,setUpdate,profile,isProfile,overrideResource,setOverrideResource,editData,startsOn,endsOn,selectedOfferCode) => {
    const { firstName, lastName, mobile, email, addressLine1, country, city, state, zipCode, attributeList, subscriptions } = values;
    const isRequiredFieldsFilled = firstName && lastName && dialCode && email && addressLine1 && country && city && state && zipCode;
    const isAttributeListAndSubscriptionsProvided = attributeList?.length && subscriptions?.length;
    const isAttributeListProvided = attributeList?.length;
    const isSubscriptionsProvided = subscriptions?.length;
    
    const isValid =
        isRequiredFieldsFilled ||
        (isRequiredFieldsFilled &&
            isAttributeListAndSubscriptionsProvided) ||
        (isRequiredFieldsFilled && isAttributeListProvided) ||
        (isRequiredFieldsFilled && isSubscriptionsProvided);
        
    if (isValid) {
        onSubmit(values, isEdit, subscriptionsLength, setLoading, dispatch, customerId, navigate, dialCode,roleName,searchableValues,nonSearchableValues,quantity,profilePicture,fileSelected,setFileSelected,update,setUpdate,profile,isProfile,editData,startsOn,endsOn,selectedOfferCode,overrideResource,setOverrideResource)
    }
    else {
        toast.error('Kindly fill all the required fields')
    }
}

export const navigateToSubscribe = async (row, dispatch, navigate) => {
    dispatch({ type: 'CLEAR_ORDERS', payload: [] })
    dispatch(resetPreview())
    const extractedId = row.original.CustomerId;
    let path = `/order-filtering/${extractedId}`;
    navigate(path);
};

export const cancelOffer = (jsonObj, setLoading, dispatch) => {
    dispatch(cancelSubscription(jsonObj, setLoading))
}

export const unsubscribeOffer = async (customerData, customerId, id, setLoading, dispatch) => {
    let jsonObj = subscriptionsJsonObj(customerData, customerId, id)
    cancelOffer(jsonObj, setLoading, dispatch)
}

export const dialCodeFocusChange = (setOnFocus) => {
    setOnFocus(true)
}

export const validatePhoneNumber = (dialCode) => {
    if (!dialCode) {
        return 'Kindly enter phone number';
    } else if (!isValidPhoneNumber(dialCode)) {
        return 'Kindly enter a valid phone number';
    } else {
        return null;
    }
};

export const customerDialCodeErrorThrow = (onfocus, dialCode, setOnFocus) => {
    if (!onfocus && dialCode === "") {
        setOnFocus(true)
    }
}
