import { toast } from "react-toastify";
import { fetchSetUpdatePasswordSucess, fetchSetUpdatePasswordFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { UPDATE_PASSWORD_API } from "../../constants/constants";
import { fetchCustomer } from "../fetchcustomer/thunk";

export const UpdatePassword = (jsonObj, userId, setLoading, navigate,setPasswordCustomer) => async (dispatch, getState) => {
    try {
        await dispatch(fetchCustomer(setLoading, userId));
        setLoading(true);
        const customerData = getState().FetchCustomer.fetchCustomer;
        const response = await determineBaseUrlAndPerformApiCall(`${UPDATE_PASSWORD_API}/${customerData?.customerInfo?.userId}`, 'PUT', jsonObj);
        if (!response.error) {
            setLoading(false);
            dispatch(fetchSetUpdatePasswordSucess({response:response,customerInfo:customerData?.customerInfo}));
            navigate('/customer')
            setPasswordCustomer(null);
        } else {
            dispatch(fetchSetUpdatePasswordFailure(response.error.message));
            setLoading(false);
            toast.error(`${response.error.message}`);
        }
    } catch (errorResp) { }
};
