import { Button } from "reactstrap";

export const Slider = ({ isOpen, setOpen, actionName, handleSave, children }) => {
    return (
        <>
            {/* Backdrop */}
            {isOpen && (
                <div
                    className="slider-backdrop w-100 h-100"
                    onClick={() => setOpen({ open: false })}
                ></div>
            )}

            <div
                className={`slider-content bg-white border border-gray ${isOpen ? "translate-x-0" : "translate-x-100"
                    }`}
            >
                <div className="d-flex justify-content-between align-items-center p-3 border-bottom mb-2">
                    <p className="text-uppercase fw-bold fs-5 m-0">{actionName}</p>
                    <div
                        onClick={() => setOpen({ open: false })}
                        className="cursor-pointer"
                    >
                        <i className="ri-close-line fs-4 p-2"></i>
                    </div>
                </div>

                <div
                    className="px-3 pt-1 py-5 flex-grow-1 overflow-auto"
                >
                    {children}
                </div>

                <div
                    className="p-3 d-flex justify-content-end"
                >
                    <Button color={"main-color"} onClick={() => handleSave()}>
                        Save
                    </Button>
                </div>
            </div>
        </>
    );
};

