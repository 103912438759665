import moment from "moment";
import { getCellStyle } from "../../Utils/DecideColor";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

export const taskColumns = (taskPath) => {
    const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)

    const isEIPEnabled = operatorConfiguration?.configurations
        ?.find(config => config.name === "EIP")
        ?.value?.find(item => item.key === "Enabled")
        ?.value?.[0] === "True";
    const buttonName = isEIPEnabled ? "Device ID" : "Customer ID";

    const formatTaskType = (taskType) => {
        return taskType
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const columns = [
        {
            accessorKey: "taskId",
            header: "Task ID",
        },
        {
            accessorKey: "taskType",
            header: "Task Type",
            Cell: ({ cell }) => (
                <Box>
                    {formatTaskType(cell.getValue())}
                </Box>
            )
        },
        {
            accessorKey: "internalStatus",
            header: "Internal Status",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(), "Task"),
                    } : {})}
                >
                    {cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: "provisioningStatus",
            header: "Provisioning Status",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => (cell.getValue() ? {
                        ...getCellStyle(cell.getValue(), "Task"),
                    } : {})}
                >
                    {cell.getValue() === "NOT_ENABLED" ? "NOT ENABLED" : cell.getValue()}
                </Box>
            )
        },
        {
            accessorKey: "taskTime",
            header: "Task Time",
            Cell: ({ cell }) => (
                <Box>
                    {moment(cell.getValue()).format("DD MMM YYYY, HH:MM:SS")}
                </Box>
            )
        }
    ];
    if (taskPath === 'tasks') {
        columns.splice(1, 0, {
            accessorKey: "customerId",
            header: buttonName,
        });
    }
    return columns;
};