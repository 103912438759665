import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    SetUpdatePassword: "",
    loading: false,
    error: null,
    userDetail:{}
}

const SetUpdatePasswordSlice = createSlice({
    name: "SetUpdatePassword",
    initialState,
    reducers: {
        fetchSetUpdatePasswordSucess: (state, action) => {
            state.loading = false
            state.SetUpdatePassword = action.payload.response;
            state.userDetail = action.payload.customerInfo;
        },
        fetchSetUpdatePasswordFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
        resetUpdatePasswordState: (state) => { 
            state.SetUpdatePassword = "";
            state.userDetail = {};
            state.error = null;
        }
    },
})

export const {
    fetchSetUpdatePasswordSucess,
    fetchSetUpdatePasswordFailure,
    resetUpdatePasswordState
} = SetUpdatePasswordSlice.actions;

export default SetUpdatePasswordSlice.reducer;