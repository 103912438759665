import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import { MaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { getView } from '../../slices/View/thunk';
import { fetchAllSessions } from '../../slices/sessions/getAllSessions/thunk';
import { LabelText } from '../Utils/Input';
import moment from 'moment';

function LiveSessionListings({ customerId, setUpdateSession, sessionInfo, setSessionInfo, terminateSessions, setTerminateSessions }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState("1");
    const toggle = (id) => {
        setOpen(open === id ? "" : id);
    };
    const columns = [
        {
            accessorKey: 'sessionId',
            header: 'Session ID',
            Cell: ({ cell, row }) => {
                const sessionId = cell.getValue()?.replace(/^TEST-SESSION-/, "");
                return <span>{sessionId}</span>;
            },
        },
        {
            accessorKey: 'subscriptionId',
            header: 'Subscription ID',
        },
        {
            accessorKey: 'usageEventType',
            header: 'Event Type',
        },
        {
            accessorKey: 'startTime',
            header: 'Start Time',
            Cell: ({ cell }) => moment(cell.getValue()).utc().format("DD/MM/YYYY, hh:mm A"),

        },
        {
            accessorFn: (row) => `${row.grantedServiceUnit || 0} ${row.uom || ''}`,
            header: 'Reserved Units',
        },
        {
            accessorFn: (row) => `${row.UsedServiceUnit || 0} ${row.uom || ''}`,
            header: 'Consumed Units',
        },
    ];

    useEffect(() => {
        dispatch(getView(setLoading));
        dispatch(fetchAllSessions(customerId))
    }, [dispatch]);
    let sessionData = useSelector((state) => state.GetAllSessions.sessions)
    return (
        <React.Fragment>
            <div id='csms' className='mt-2'>
                {/* <Container fluid> */}
                <Row>
                    <Col lg={12}>
                        <div className="listingjs-table" id="customerList">
                            <Card>
                                <CardBody>
                                    <Row className="mb-3  table-responsive">
                                        {loading}
                                        {sessionData ?
                                            <>
                                                <MaterialReactTable

                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    positionToolbarAlertBanner="none"
                                                    columns={columns}
                                                    data={sessionData}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableTopToolbar={false}
                                                    enableExpanding={true}

                                                    muiTableHeadRowProps={() => ({
                                                        sx: {
                                                            backgroundColor: '#EEEEEE',
                                                        },
                                                    })}
                                                    defaultColumn={{
                                                        minSize: 1,
                                                        maxSize: 9001,
                                                        muiTableBodyCellProps: {
                                                            sx: {
                                                                fontFamily: 'Inter,sans-serif',
                                                            },
                                                        },
                                                        muiTableHeadCellProps: {
                                                            sx: {
                                                                fontFamily: 'Inter,sans-serif',
                                                            },
                                                        },
                                                    }}

                                                    enableDensityToggle={false}
                                                    renderDetailPanel={({ row }) => {
                                                        const auditData = row?.original?.audit || [];
                                                        const initiateEvents = auditData.filter(event => event.eventType === "Started");
                                                        const updateEvents = auditData.filter(event => event.eventType === "Updated");
                                                        const isTestSession = row?.original?.sessionId?.startsWith('TEST-SESSION-') || false;
                                                        return (
                                                            <>
                                                                <div className="row">

                                                                    <div className="col-md-6 mb-2">
                                                                        <div className="border rounded border-2">
                                                                            <div className="card-body">
                                                                                <LabelText displayText="Reserved Units" />
                                                                             
                                                                                <h1 className="card-text fw-bold my-4 text-center session-card-font-size">
                                                                                    {row?.original?.grantedServiceUnit ? (
                                                                                        <>
                                                                                            {row?.original?.grantedServiceUnit || '0'} <span className="fs-2">{row?.original?.uom}</span>
                                                                                        </>
                                                                                    ) : (
                                                                                        '0'
                                                                                    )}
                                                                                </h1>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-md-6 mb-2">
                                                                        <div className="border rounded border-2">
                                                                            <div className="card-body">
                                                                                <LabelText displayText="Consumed Units" />
                                                                                <h1 className="card-text fw-bold my-4 text-center session-card-font-size">
                                                                                    {row?.original?.UsedServiceUnit || '0 '} <span className="fs-2">{row?.original?.uom}</span>
                                                                                </h1>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-3 mb-3 ms-2 fs-5">History</div>

                                                                <Accordion open={open} toggle={toggle} className="mt-2">
                                                                    <AccordionItem className="custom-accordion">
                                                                        <AccordionHeader targetId="1" className='balance-heading-color'>Initiate</AccordionHeader>
                                                                        <AccordionBody accordionId="1">
                                                                            {initiateEvents.map((event, index) => (
                                                                                <Row key={index} className="gy-4">
                                                                                    <Col xxl={3} md={4}>
                                                                                        <LabelText displayText="Start Time" classLabelName={'text-black fw-semibold'} />
                                                                                        <p className='text-black'>{moment((event?.timeStamp)).utc().format("DD/MM/YYYY, hh:mm a")}</p>
                                                                                    </Col>
                                                                                    <Col xxl={3} md={4}>
                                                                                        <LabelText displayText="Requested Units" classLabelName={'text-black fw-semibold'} />
                                                                                        <p className='text-black'>{event?.requestedServiceUnits} {row?.original?.uom}</p>
                                                                                    </Col>
                                                                                    <Col xxl={3} md={4}>
                                                                                        <LabelText displayText="Granted Units" classLabelName={'text-black fw-semibold'} />
                                                                                        <p className='text-black'>{event?.grantedServiceUnits} {row?.original?.uom}</p>
                                                                                    </Col>
                                                                                </Row>
                                                                            ))}
                                                                        </AccordionBody>
                                                                    </AccordionItem>
                                                                    <AccordionItem className="custom-accordion">
                                                                        <AccordionHeader targetId="2" className='balance-heading-color d-flex justify-content-between'>Update
                                                                            <div className='d-flex justify-content-end'>
                                                                            {!isTestSession ? null :(
                                                                                <a
                                                                                    href="#"
                                                                                    className=" live-session-status-1 "
                                                                                    onClick={() => {
                                                                                        const sessionId = row?.original?.sessionId?.replace(/^TEST-SESSION-/, "");
                                                                                        const sequenceNumber = (row?.original?.seqno?.at(-1) || 0) + 1;
                                                                                        const subscriptionId = row?.original?.subscriptionId
                                                                                        const usageEventType = row?.original?.usageEventType
                                                                                        const uom = row?.original?.uom
                                                                                        setSessionInfo({ sessionId, sequenceNumber, subscriptionId, usageEventType, uom });
                                                                                        setUpdateSession(true);
                                                                                        setTerminateSessions(false);
                                                                                    }}
                                                                                >
                                                                                    Update Session
                                                                                </a>
                                                                                )}
                                                                            </div></AccordionHeader>

                                                                        <AccordionBody accordionId="2">
                                                                            {updateEvents && updateEvents.length > 0 ? (
                                                                                updateEvents.map((event, index) => (
                                                                                    <Row
                                                                                        key={index}
                                                                                        className={`gy-4 mb-2 ${index === updateEvents.length - 1 ? '' : 'border-bottom'}`}
                                                                                    >
                                                                                        <Col xxl={2} md={4} >
                                                                                            <LabelText displayText="Sequence" classLabelName={'text-black fw-semibold'} />
                                                                                            <p className='text-black'>{event.seqno}</p>
                                                                                        </Col>
                                                                                        <Col xxl={2} md={4} className='me-5'>
                                                                                            <LabelText displayText="Event Time" classLabelName={'text-black fw-semibold'} />
                                                                                            <p className='text-black'>{moment((event?.timeStamp)).utc().format("DD/MM/YYYY, hh:mm a")}</p>
                                                                                        </Col>
                                                                                        <Col xxl={2} md={4} className='me-5'>
                                                                                            <LabelText displayText="Requested Units" classLabelName={'text-black fw-semibold'} />
                                                                                            <p className='text-black'>{event?.requestedServiceUnits || '0'} {row?.original?.uom}</p>
                                                                                        </Col>
                                                                                        <Col xxl={2} md={4} className='me-5'>
                                                                                            <LabelText displayText="Granted Units" classLabelName={'text-black fw-semibold'} />
                                                                                            <p className='text-black'>{event?.grantedServiceUnits || '0'} {row?.original?.uom}</p>
                                                                                        </Col>
                                                                                        <Col xxl={2} md={4} className='me-5'>
                                                                                            <LabelText displayText="Consumed Units" classLabelName={'text-black fw-semibold'} />
                                                                                            <p className='text-black'>{event?.usedServiceUnits || '0'} {row?.original?.uom}</p>
                                                                                        </Col>

                                                                                    </Row>
                                                                                ))
                                                                            ) : (
                                                                                <p className="text-center text-muted">No data available</p>
                                                                            )}
                                                                        </AccordionBody>

                                                                    </AccordionItem>
                                                                    <AccordionItem className="custom-accordion">
                                                                        <AccordionHeader targetId="3" className='balance-heading-color d-flex justify-content-between'>Terminate
                                                                            <div className='d-flex justify-content-end'>
                                                                            {!isTestSession ? null :(
                                                                                <a
                                                                                    href="#"
                                                                                    className="live-session-status "
                                                                                    onClick={() => {
                                                                                        const sessionId = row?.original?.sessionId.replace(/^TEST-SESSION-/, "");
                                                                                        const sequenceNumber = (row?.original?.seqno?.at(-1) || 0) + 1;
                                                                                        const subscriptionId = row?.original?.subscriptionId
                                                                                        const usageEventType = row?.original?.usageEventType
                                                                                        const uom = row?.original?.uom
                                                                                        setSessionInfo({ sessionId, sequenceNumber, subscriptionId, usageEventType, uom });
                                                                                        setTerminateSessions(true);
                                                                                        setUpdateSession(true);
                                                                                    }}
                                                                                >
                                                                                    Terminate Session
                                                                                </a>
                                                                                   )}
                                                                            </div></AccordionHeader></AccordionItem>
                                                                </Accordion>
                                                            </>
                                                        );
                                                    }}


                                                />


                                            </>
                                            : null
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
                {/* </Container> */}

            </div>
        </React.Fragment>
    );
}

export default LiveSessionListings;
